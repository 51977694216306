.modalSelect {
    align-self: center;
    display: flex;
    background-color: #f0f0f0;
    padding: 5px;
    border-radius: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
    > div {
        width: 220px;
        height: 50px;
        border-radius: 12px;
        display: flex;
        text-align: center;
        flex-direction: column;
        justify-content: center;
        user-select: none;
        cursor: pointer;
    }
}

.disableInput {
    opacity: 1 !important;
    > input {
        &:disabled {
            background-color: #eee !important;
        }
        
    }
}