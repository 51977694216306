.productRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;

    &:last-child {
        border: none;
    }

    .button {
        min-width: fit-content;
        white-space: nowrap;
        word-wrap: none;
    }
}
