.modalContainer {
    padding: 2rem;
    border-radius: 15px !important;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .contentContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 300px;
        max-height: 60vh;
        overflow-y: auto;
        margin-bottom: 1rem;

        .empty {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 100px;
        }

        .companyContainer {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 10px 0 20px;

            & span {
                font-size: 1.1rem;
                margin-bottom: 10px;
            }

            .companyName {
                margin-top: 10px;
                border-radius: 5px;
                font-size: 1.4rem;
            }
        }
    }
}

.factoryContainer {
    display: flex;
    flex-direction: column;
    width: 100%;

    .detailContainer {
        display: flex;
        width: 100%;
        padding: 1rem 5px;

        &:not(:last-child) {
            border-bottom: 1px solid #eee;
        }

        .leftSelction {
            display: flex;
            flex-direction: column;
            width: 100%;

            .factoryNameTh {
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 5px;
            }
            .factoryNameEn {
                font-size: 16px;
                font-weight: 400;
                color: #4f4f4f;
            }
            .factoryAddress {
                font-size: 14px;
                font-weight: 300;
                color: #7d7d7d;
                line-height: 1.5;
            }
        }

        .rightSelction {
            display: flex;
            flex-direction: column;
            padding-left: 1rem;
            min-width: 250px;

            .standardText {
                background-color: #eee;
                border-radius: 5px;
                padding: 5px 10px;
                margin: 5px 0;
            }

            .standardExp {
                display: flex;

                .standardExpDate {
                    padding-left: 10px;

                    &.expired {
                        color: red;
                    }
                }
            }
        }
    }
}
