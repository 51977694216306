// .containerContentRow {
//     display: flex;
//     flex-direction: row;
//     width: 100%;
//     justify-content: space-between;
//     align-items: center;
// }
// .boxContainer {
//     max-width: 510;
//     max-height: 300;
//     display: flex;
//     z-index: 9999999;
//     color: #ffff;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     padding: 50px;
//     box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
//     background: linear-gradient(112.1deg, rgb(32, 38, 57) 11.4%, rgb(63, 76, 119) 70.2%);
//     border-radius: 10px;
//     font-family: rsu;
//     h1 {
//         font-size: 32px;
//         margin: 5px;
//         font-family: rsu;
//     }
//     h3 {
//         font-size: 20px;
//         font-weight: 400;
//         margin: 15px;
//         font-family: rsu;
//     }
//     p {
//         margin-top: 15px;
//     }
// }
// .boxContainerSelect {
//     max-width: 510;
//     min-width: 400;
//     display: flex;
//     z-index: 9999999;
//     flex-direction: column;
//     padding: 25px;
//     border-radius: 10px;
//     font-family: rsu;
//     h3 {
//         font-size: 20px;
//         font-weight: 400;
//         margin: 15px;
//         font-family: rsu;
//     }
// }
.containerListCompany{
    display: flex;
    text-align: center;
}
// .containerListCompany {
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     .contaienrBoxbutton {
//         margin: 0 15px;
//         .boxButtonList {
//             background: #fff;
//             margin-top: 20px;
//         }
//     }
// }

// .imageContainer {
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     align-items: center;
//     overflow: hidden;
// }
// .boxLogoImage {
//     padding: 15;
//     width: 100%;
//     max-width: 170px;
//     position: relative;
//     pointer-events: none;
//     img {
//         width: 100%;
//         height: 100%;
//         object-fit: contain;
//     }
// }

// .imageStatusIcon {
//     width: 55px;
//     margin: 0 20px;
//     animation: winggle 2s linear infinite;
//     @keyframes winggle {
//         0% {
//             transform: rotateZ(0);
//         }
//         15% {
//             transform: rotateZ(-15deg);
//         }
//         20% {
//             transform: rotateZ(10deg);
//         }
//         25% {
//             transform: rotateZ(-10deg);
//         }
//         30% {
//             transform: rotateZ(6deg);
//         }
//         35% {
//             transform: rotateZ(-4deg);
//         }
//         40% {
//             transform: rotateZ(0);
//         }
//     }
// }

// css motor
.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.boxContainer {
    max-width: 510;
    max-height: 300;
    display: flex;
    // z-index: 9999999;
    color: #ffff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    background: linear-gradient(112.1deg, rgb(32, 38, 57) 11.4%, rgb(63, 76, 119) 70.2%);
    border-radius: 10px;
    h1 {
        background: linear-gradient(to right, #9710cc 0%, #ffd80c 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 32px;
        margin: 5px;
    }
    h3 {
        font-size: 24px;
        font-weight: 400;
        margin: 15px;
    }
}

.imageContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.boxLogoImage {
    padding: 15;
    width: 100%;
    max-width: 170px;
    position: relative;
    pointer-events: none;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.imageStatusIcon {
    width: 55px;
    margin: 0 20px;
    animation: winggle 2s linear infinite;
    @keyframes winggle {
        0% {
            transform: rotateZ(0);
        }
        15% {
            transform: rotateZ(-15deg);
        }
        20% {
            transform: rotateZ(10deg);
        }
        25% {
            transform: rotateZ(-10deg);
        }
        30% {
            transform: rotateZ(6deg);
        }
        35% {
            transform: rotateZ(-4deg);
        }
        40% {
            transform: rotateZ(0);
        }
    }
    // width: 55,
    // animation: "wiggle 2s linear infinite",
    // "@keyframes wiggle": {
    // 	"0%,7%": {
    // 		transform: "rotateZ(0)",
    // 	},
    // 	"15%": {
    // 		transform: "rotateZ(-15deg)",
    // 	},
    // 	"20%": {
    // 		transform: "rotateZ(10deg)",
    // 	},
    // 	"25%": {
    // 		transform: "rotateZ(-10deg)",
    // 	},
    // 	"30%": {
    // 		transform: "rotateZ(6deg)",
    // 	},
    // 	"35%": {
    // 		transform: "rotateZ(-4deg)",
    // 	},
    // 	"40%": {
    // 		transform: "rotateZ(0)",
    // 	},
    // },
}
.iconStatusButton {
    width: 30px;
}

.iconArrowLoading {
    margin: 0 20px;
    animation: updown;
    animation-duration: 1.5s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
    @keyframes updown {
        0% {
            transform: translateX(-15%);
        }
        50% {
            transform: translateX(15%);
        }
        100% {
            transform: translateX(-15%);
        }
    }
}

.containerListCompany {
    max-width: 510;
    min-width: 400;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    .boxCompanyButton {
        width: 100%;
        margin: 15px;
        .buttonCompanyLink {
            width: 100%;
            display: flex;
            flex-direction: row;
            padding: 15px;
            justify-content: space-between;
            align-items: center;
        }
    }
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: rgb(73, 163, 73);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}

// industry
.justifyContentCenten {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .cardContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(8px);
    background: radial-gradient(circle at 18.7% 37.8%, rgb(250, 250, 250) 0%, rgb(225, 234, 238) 90%);
  }
  .positionBox {
    width: 100%;
    margin: auto;
    display: flex;
    min-height: 100vh;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .cardNotJuristicPerson {
    max-width: 510px;
    max-height: 300px;
    display: flex;
    z-index: 999999;
    color: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    background: linear-gradient(45deg, #2f1c53, #a46dff);
    border-radius: 10px;
    h1 {
      background: linear-gradient(to right, #c136f8 0%, #ffd80c 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 2rem;
      font-weight: 900;
      margin: 5px;
      padding: 10px;
    }
    h3 {
      font-size: 18px;
      font-weight: 400;
      margin: 10px 0;
      line-height: 1.5;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: rgb(216, 216, 216);
    }
  }
  .containerColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
