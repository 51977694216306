.container-news-detail {
    display:flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    margin-top: 2%;
}
.box-btn-close {
    margin-top: 70px;
    color: #ff7070;
    .btn-close-popup {
        border: none;
        padding: 15px 60px;
        border-radius: 50px;
        color: #ff7070;
        box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    }
    .btn-close-popup:hover {
        color: #fff;
        background: #ff7070;
        box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    }
}
.box-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // background: #fff;
    border-radius: 5px;
    padding:50px;

    .box-header-detail {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        h4 {
            margin: 0;
            font-size: 30px;
            font-weight: 500;
            color: #ffff;
        }
        small {
            color: #0096ff;
            font-size: 14px;
            margin-bottom: 20px;
        }
    }
    .box-image-news {
        // width: 130vh;
        width: 60%;
        height: 55vh;
        overflow: hidden;
        display: flex;
        justify-content: center;
        margin-bottom: -30px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        img {
            display: block;
            width: 100%;
            object-fit: cover;
        }
    }
    .box-news-detail {
        display: flex;
        padding: 20px;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        width: 50%;
        margin-left: -150px;
        p {
            color: #333333;
        }
    }
}
