.sidebar_pusher {
  position: fixed !important;
  top: 0;
  left:-100vw;
  width: 65%;
  z-index: 99;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px!important;
  // transition: width 2s;
}

.sidebar_visible {
  left:0vw;
  transition: all 0.65s ;
}
