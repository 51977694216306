.customPopup {
    background-color: #fff !important;
    padding: 10px !important;
    /* width: 50px !important; */
    max-height: 300px !important;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) !important;
}

.popUpSize {
    background-color: #fff !important;
    padding: 10px !important;
    /* width: 50px !important; */
    max-height: 300px !important;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.headerSizeProductName {
    color: #FFF;
    font-size: 0.9em;
    padding: 5px;
    border-radius: 5px;
}

.containerSize {
    display: flex !important;
    text-align: left !important;
    justify-content: space-between !important;
    padding: 5px 10px !important;
}

.cardAddress {
    background-color: #eeeeee;
    padding: 10px;
    border-radius: 5px;
    margin-top: 7px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.typeFactoryCard {
    font-size: 0.7em;
    padding: 5px 10px;
    background-color: #fff172;
    border-radius: 5px;
    white-space: nowrap;
}
