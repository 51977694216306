.underConstruction{
    height: 100vh;
    width: 100%;
    // background-color: aqua;
    background-image: url('../../assets/img/underconstruction.jpg');

    background-repeat: no-repeat;
    background-size: auto 100vh;
    background-position: center;
}

.btn-back {
    position: absolute;
    top: 50px;
    left: 40px;
}