@charset "UTF-8";
/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
.bootstrap-scope :root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
.bootstrap-scope *,
.bootstrap-scope *::before,
.bootstrap-scope *::after {
  box-sizing: border-box;
}
.bootstrap-scope html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.bootstrap-scope article, .bootstrap-scope aside, .bootstrap-scope figcaption, .bootstrap-scope figure, .bootstrap-scope footer, .bootstrap-scope header, .bootstrap-scope hgroup, .bootstrap-scope main, .bootstrap-scope nav, .bootstrap-scope section {
  display: block;
}
.bootstrap-scope body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
.bootstrap-scope [tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}
.bootstrap-scope hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
.bootstrap-scope h1, .bootstrap-scope h2, .bootstrap-scope h3, .bootstrap-scope h4, .bootstrap-scope h5, .bootstrap-scope h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.bootstrap-scope p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.bootstrap-scope abbr[title],
.bootstrap-scope abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}
.bootstrap-scope address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
.bootstrap-scope ol,
.bootstrap-scope ul,
.bootstrap-scope dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
.bootstrap-scope ol ol,
.bootstrap-scope ul ul,
.bootstrap-scope ol ul,
.bootstrap-scope ul ol {
  margin-bottom: 0;
}
.bootstrap-scope dt {
  font-weight: 700;
}
.bootstrap-scope dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
.bootstrap-scope blockquote {
  margin: 0 0 1rem;
}
.bootstrap-scope b,
.bootstrap-scope strong {
  font-weight: bolder;
}
.bootstrap-scope small {
  font-size: 80%;
}
.bootstrap-scope sub,
.bootstrap-scope sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
.bootstrap-scope sub {
  bottom: -0.25em;
}
.bootstrap-scope sup {
  top: -0.5em;
}
.bootstrap-scope a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
.bootstrap-scope a:hover {
  color: #0056b3;
  text-decoration: underline;
}
.bootstrap-scope a:not([href]) {
  color: inherit;
  text-decoration: none;
}
.bootstrap-scope a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}
.bootstrap-scope pre,
.bootstrap-scope code,
.bootstrap-scope kbd,
.bootstrap-scope samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}
.bootstrap-scope pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}
.bootstrap-scope figure {
  margin: 0 0 1rem;
}
.bootstrap-scope img {
  vertical-align: middle;
  border-style: none;
}
.bootstrap-scope svg {
  overflow: hidden;
  vertical-align: middle;
}
.bootstrap-scope table {
  border-collapse: collapse;
}
.bootstrap-scope caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
.bootstrap-scope th {
  text-align: inherit;
}
.bootstrap-scope label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.bootstrap-scope button {
  border-radius: 0;
}
.bootstrap-scope button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
.bootstrap-scope input,
.bootstrap-scope button,
.bootstrap-scope select,
.bootstrap-scope optgroup,
.bootstrap-scope textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.bootstrap-scope button,
.bootstrap-scope input {
  overflow: visible;
}
.bootstrap-scope button,
.bootstrap-scope select {
  text-transform: none;
}
.bootstrap-scope select {
  word-wrap: normal;
}
.bootstrap-scope button,
.bootstrap-scope [type=button],
.bootstrap-scope [type=reset],
.bootstrap-scope [type=submit] {
  -webkit-appearance: button;
}
.bootstrap-scope button:not(:disabled),
.bootstrap-scope [type=button]:not(:disabled),
.bootstrap-scope [type=reset]:not(:disabled),
.bootstrap-scope [type=submit]:not(:disabled) {
  cursor: pointer;
}
.bootstrap-scope button::-moz-focus-inner,
.bootstrap-scope [type=button]::-moz-focus-inner,
.bootstrap-scope [type=reset]::-moz-focus-inner,
.bootstrap-scope [type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
.bootstrap-scope input[type=radio],
.bootstrap-scope input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}
.bootstrap-scope input[type=date],
.bootstrap-scope input[type=time],
.bootstrap-scope input[type=datetime-local],
.bootstrap-scope input[type=month] {
  -webkit-appearance: listbox;
}
.bootstrap-scope textarea {
  overflow: auto;
  resize: vertical;
}
.bootstrap-scope fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
.bootstrap-scope legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
.bootstrap-scope progress {
  vertical-align: baseline;
}
.bootstrap-scope [type=number]::-webkit-inner-spin-button,
.bootstrap-scope [type=number]::-webkit-outer-spin-button {
  height: auto;
}
.bootstrap-scope [type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
.bootstrap-scope [type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
.bootstrap-scope ::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
.bootstrap-scope output {
  display: inline-block;
}
.bootstrap-scope summary {
  display: list-item;
  cursor: pointer;
}
.bootstrap-scope template {
  display: none;
}
.bootstrap-scope [hidden] {
  display: none !important;
}
.bootstrap-scope h1, .bootstrap-scope h2, .bootstrap-scope h3, .bootstrap-scope h4, .bootstrap-scope h5, .bootstrap-scope h6,
.bootstrap-scope .h1, .bootstrap-scope .h2, .bootstrap-scope .h3, .bootstrap-scope .h4, .bootstrap-scope .h5, .bootstrap-scope .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.bootstrap-scope h1, .bootstrap-scope .h1 {
  font-size: 2.5rem;
}
.bootstrap-scope h2, .bootstrap-scope .h2 {
  font-size: 2rem;
}
.bootstrap-scope h3, .bootstrap-scope .h3 {
  font-size: 1.75rem;
}
.bootstrap-scope h4, .bootstrap-scope .h4 {
  font-size: 1.5rem;
}
.bootstrap-scope h5, .bootstrap-scope .h5 {
  font-size: 1.25rem;
}
.bootstrap-scope h6, .bootstrap-scope .h6 {
  font-size: 1rem;
}
.bootstrap-scope .lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.bootstrap-scope .display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}
.bootstrap-scope .display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.bootstrap-scope .display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.bootstrap-scope .display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.bootstrap-scope hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.bootstrap-scope small,
.bootstrap-scope .small {
  font-size: 80%;
  font-weight: 400;
}
.bootstrap-scope mark,
.bootstrap-scope .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
.bootstrap-scope .list-unstyled {
  padding-left: 0;
  list-style: none;
}
.bootstrap-scope .list-inline {
  padding-left: 0;
  list-style: none;
}
.bootstrap-scope .list-inline-item {
  display: inline-block;
}
.bootstrap-scope .list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.bootstrap-scope .initialism {
  font-size: 90%;
  text-transform: uppercase;
}
.bootstrap-scope .blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.bootstrap-scope .blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.bootstrap-scope .blockquote-footer::before {
  content: "— ";
}
.bootstrap-scope .img-fluid {
  max-width: 100%;
  height: auto;
}
.bootstrap-scope .img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
.bootstrap-scope .figure {
  display: inline-block;
}
.bootstrap-scope .figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.bootstrap-scope .figure-caption {
  font-size: 90%;
  color: #6c757d;
}
.bootstrap-scope code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > .bootstrap-scope code {
  color: inherit;
}
.bootstrap-scope kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
.bootstrap-scope kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}
.bootstrap-scope pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
.bootstrap-scope pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
.bootstrap-scope .pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
.bootstrap-scope .container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .bootstrap-scope .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .bootstrap-scope .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .bootstrap-scope .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .bootstrap-scope .container {
    max-width: 1140px;
  }
}
.bootstrap-scope .container-fluid, .bootstrap-scope .container-xl, .bootstrap-scope .container-lg, .bootstrap-scope .container-md, .bootstrap-scope .container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .bootstrap-scope .container-sm, .bootstrap-scope .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .bootstrap-scope .container-md, .bootstrap-scope .container-sm, .bootstrap-scope .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .bootstrap-scope .container-lg, .bootstrap-scope .container-md, .bootstrap-scope .container-sm, .bootstrap-scope .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .bootstrap-scope .container-xl, .bootstrap-scope .container-lg, .bootstrap-scope .container-md, .bootstrap-scope .container-sm, .bootstrap-scope .container {
    max-width: 1140px;
  }


}

.nav_container.container{
  max-width: 1920px;
  padding: 0 1.7%;
}

.bootstrap-scope .row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.bootstrap-scope .no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.bootstrap-scope .no-gutters > .col,
.bootstrap-scope .no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}
.bootstrap-scope .col-xl,
.bootstrap-scope .col-xl-auto, .bootstrap-scope .col-xl-12, .bootstrap-scope .col-xl-11, .bootstrap-scope .col-xl-10, .bootstrap-scope .col-xl-9, .bootstrap-scope .col-xl-8, .bootstrap-scope .col-xl-7, .bootstrap-scope .col-xl-6, .bootstrap-scope .col-xl-5, .bootstrap-scope .col-xl-4, .bootstrap-scope .col-xl-3, .bootstrap-scope .col-xl-2, .bootstrap-scope .col-xl-1, .bootstrap-scope .col-lg,
.bootstrap-scope .col-lg-auto, .bootstrap-scope .col-lg-12, .bootstrap-scope .col-lg-11, .bootstrap-scope .col-lg-10, .bootstrap-scope .col-lg-9, .bootstrap-scope .col-lg-8, .bootstrap-scope .col-lg-7, .bootstrap-scope .col-lg-6, .bootstrap-scope .col-lg-5, .bootstrap-scope .col-lg-4, .bootstrap-scope .col-lg-3, .bootstrap-scope .col-lg-2, .bootstrap-scope .col-lg-1, .bootstrap-scope .col-md,
.bootstrap-scope .col-md-auto, .bootstrap-scope .col-md-12, .bootstrap-scope .col-md-11, .bootstrap-scope .col-md-10, .bootstrap-scope .col-md-9, .bootstrap-scope .col-md-8, .bootstrap-scope .col-md-7, .bootstrap-scope .col-md-6, .bootstrap-scope .col-md-5, .bootstrap-scope .col-md-4, .bootstrap-scope .col-md-3, .bootstrap-scope .col-md-2, .bootstrap-scope .col-md-1, .bootstrap-scope .col-sm,
.bootstrap-scope .col-sm-auto, .bootstrap-scope .col-sm-12, .bootstrap-scope .col-sm-11, .bootstrap-scope .col-sm-10, .bootstrap-scope .col-sm-9, .bootstrap-scope .col-sm-8, .bootstrap-scope .col-sm-7, .bootstrap-scope .col-sm-6, .bootstrap-scope .col-sm-5, .bootstrap-scope .col-sm-4, .bootstrap-scope .col-sm-3, .bootstrap-scope .col-sm-2, .bootstrap-scope .col-sm-1, .bootstrap-scope .col,
.bootstrap-scope .col-auto, .bootstrap-scope .col-12, .bootstrap-scope .col-11, .bootstrap-scope .col-10, .bootstrap-scope .col-9, .bootstrap-scope .col-8, .bootstrap-scope .col-7, .bootstrap-scope .col-6, .bootstrap-scope .col-5, .bootstrap-scope .col-4, .bootstrap-scope .col-3, .bootstrap-scope .col-2, .bootstrap-scope .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.bootstrap-scope .col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.bootstrap-scope .row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}
.bootstrap-scope .row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}
.bootstrap-scope .row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
.bootstrap-scope .row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}
.bootstrap-scope .row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}
.bootstrap-scope .row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}
.bootstrap-scope .col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.bootstrap-scope .col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}
.bootstrap-scope .col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}
.bootstrap-scope .col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.bootstrap-scope .col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
.bootstrap-scope .col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}
.bootstrap-scope .col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.bootstrap-scope .col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}
.bootstrap-scope .col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}
.bootstrap-scope .col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.bootstrap-scope .col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}
.bootstrap-scope .col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}
.bootstrap-scope .col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.bootstrap-scope .order-first {
  order: -1;
}
.bootstrap-scope .order-last {
  order: 13;
}
.bootstrap-scope .order-0 {
  order: 0;
}
.bootstrap-scope .order-1 {
  order: 1;
}
.bootstrap-scope .order-2 {
  order: 2;
}
.bootstrap-scope .order-3 {
  order: 3;
}
.bootstrap-scope .order-4 {
  order: 4;
}
.bootstrap-scope .order-5 {
  order: 5;
}
.bootstrap-scope .order-6 {
  order: 6;
}
.bootstrap-scope .order-7 {
  order: 7;
}
.bootstrap-scope .order-8 {
  order: 8;
}
.bootstrap-scope .order-9 {
  order: 9;
}
.bootstrap-scope .order-10 {
  order: 10;
}
.bootstrap-scope .order-11 {
  order: 11;
}
.bootstrap-scope .order-12 {
  order: 12;
}
.bootstrap-scope .offset-1 {
  margin-left: 8.3333333333%;
}
.bootstrap-scope .offset-2 {
  margin-left: 16.6666666667%;
}
.bootstrap-scope .offset-3 {
  margin-left: 25%;
}
.bootstrap-scope .offset-4 {
  margin-left: 33.3333333333%;
}
.bootstrap-scope .offset-5 {
  margin-left: 41.6666666667%;
}
.bootstrap-scope .offset-6 {
  margin-left: 50%;
}
.bootstrap-scope .offset-7 {
  margin-left: 58.3333333333%;
}
.bootstrap-scope .offset-8 {
  margin-left: 66.6666666667%;
}
.bootstrap-scope .offset-9 {
  margin-left: 75%;
}
.bootstrap-scope .offset-10 {
  margin-left: 83.3333333333%;
}
.bootstrap-scope .offset-11 {
  margin-left: 91.6666666667%;
}
@media (min-width: 576px) {
  .bootstrap-scope .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .bootstrap-scope .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .bootstrap-scope .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .bootstrap-scope .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .bootstrap-scope .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .bootstrap-scope .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .bootstrap-scope .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .bootstrap-scope .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .bootstrap-scope .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .bootstrap-scope .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .bootstrap-scope .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .bootstrap-scope .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .bootstrap-scope .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .bootstrap-scope .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .bootstrap-scope .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .bootstrap-scope .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .bootstrap-scope .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .bootstrap-scope .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .bootstrap-scope .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .bootstrap-scope .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .bootstrap-scope .order-sm-first {
    order: -1;
  }
  .bootstrap-scope .order-sm-last {
    order: 13;
  }
  .bootstrap-scope .order-sm-0 {
    order: 0;
  }
  .bootstrap-scope .order-sm-1 {
    order: 1;
  }
  .bootstrap-scope .order-sm-2 {
    order: 2;
  }
  .bootstrap-scope .order-sm-3 {
    order: 3;
  }
  .bootstrap-scope .order-sm-4 {
    order: 4;
  }
  .bootstrap-scope .order-sm-5 {
    order: 5;
  }
  .bootstrap-scope .order-sm-6 {
    order: 6;
  }
  .bootstrap-scope .order-sm-7 {
    order: 7;
  }
  .bootstrap-scope .order-sm-8 {
    order: 8;
  }
  .bootstrap-scope .order-sm-9 {
    order: 9;
  }
  .bootstrap-scope .order-sm-10 {
    order: 10;
  }
  .bootstrap-scope .order-sm-11 {
    order: 11;
  }
  .bootstrap-scope .order-sm-12 {
    order: 12;
  }
  .bootstrap-scope .offset-sm-0 {
    margin-left: 0;
  }
  .bootstrap-scope .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .bootstrap-scope .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .bootstrap-scope .offset-sm-3 {
    margin-left: 25%;
  }
  .bootstrap-scope .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .bootstrap-scope .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .bootstrap-scope .offset-sm-6 {
    margin-left: 50%;
  }
  .bootstrap-scope .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .bootstrap-scope .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .bootstrap-scope .offset-sm-9 {
    margin-left: 75%;
  }
  .bootstrap-scope .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .bootstrap-scope .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .bootstrap-scope .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .bootstrap-scope .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .bootstrap-scope .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .bootstrap-scope .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .bootstrap-scope .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .bootstrap-scope .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .bootstrap-scope .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .bootstrap-scope .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .bootstrap-scope .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .bootstrap-scope .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .bootstrap-scope .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .bootstrap-scope .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .bootstrap-scope .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .bootstrap-scope .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .bootstrap-scope .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .bootstrap-scope .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .bootstrap-scope .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .bootstrap-scope .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .bootstrap-scope .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .bootstrap-scope .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .bootstrap-scope .order-md-first {
    order: -1;
  }
  .bootstrap-scope .order-md-last {
    order: 13;
  }
  .bootstrap-scope .order-md-0 {
    order: 0;
  }
  .bootstrap-scope .order-md-1 {
    order: 1;
  }
  .bootstrap-scope .order-md-2 {
    order: 2;
  }
  .bootstrap-scope .order-md-3 {
    order: 3;
  }
  .bootstrap-scope .order-md-4 {
    order: 4;
  }
  .bootstrap-scope .order-md-5 {
    order: 5;
  }
  .bootstrap-scope .order-md-6 {
    order: 6;
  }
  .bootstrap-scope .order-md-7 {
    order: 7;
  }
  .bootstrap-scope .order-md-8 {
    order: 8;
  }
  .bootstrap-scope .order-md-9 {
    order: 9;
  }
  .bootstrap-scope .order-md-10 {
    order: 10;
  }
  .bootstrap-scope .order-md-11 {
    order: 11;
  }
  .bootstrap-scope .order-md-12 {
    order: 12;
  }
  .bootstrap-scope .offset-md-0 {
    margin-left: 0;
  }
  .bootstrap-scope .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .bootstrap-scope .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .bootstrap-scope .offset-md-3 {
    margin-left: 25%;
  }
  .bootstrap-scope .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .bootstrap-scope .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .bootstrap-scope .offset-md-6 {
    margin-left: 50%;
  }
  .bootstrap-scope .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .bootstrap-scope .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .bootstrap-scope .offset-md-9 {
    margin-left: 75%;
  }
  .bootstrap-scope .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .bootstrap-scope .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .bootstrap-scope .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .bootstrap-scope .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .bootstrap-scope .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .bootstrap-scope .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .bootstrap-scope .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .bootstrap-scope .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .bootstrap-scope .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .bootstrap-scope .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .bootstrap-scope .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .bootstrap-scope .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .bootstrap-scope .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .bootstrap-scope .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .bootstrap-scope .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .bootstrap-scope .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .bootstrap-scope .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .bootstrap-scope .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .bootstrap-scope .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .bootstrap-scope .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .bootstrap-scope .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .bootstrap-scope .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .bootstrap-scope .order-lg-first {
    order: -1;
  }
  .bootstrap-scope .order-lg-last {
    order: 13;
  }
  .bootstrap-scope .order-lg-0 {
    order: 0;
  }
  .bootstrap-scope .order-lg-1 {
    order: 1;
  }
  .bootstrap-scope .order-lg-2 {
    order: 2;
  }
  .bootstrap-scope .order-lg-3 {
    order: 3;
  }
  .bootstrap-scope .order-lg-4 {
    order: 4;
  }
  .bootstrap-scope .order-lg-5 {
    order: 5;
  }
  .bootstrap-scope .order-lg-6 {
    order: 6;
  }
  .bootstrap-scope .order-lg-7 {
    order: 7;
  }
  .bootstrap-scope .order-lg-8 {
    order: 8;
  }
  .bootstrap-scope .order-lg-9 {
    order: 9;
  }
  .bootstrap-scope .order-lg-10 {
    order: 10;
  }
  .bootstrap-scope .order-lg-11 {
    order: 11;
  }
  .bootstrap-scope .order-lg-12 {
    order: 12;
  }
  .bootstrap-scope .offset-lg-0 {
    margin-left: 0;
  }
  .bootstrap-scope .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .bootstrap-scope .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .bootstrap-scope .offset-lg-3 {
    margin-left: 25%;
  }
  .bootstrap-scope .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .bootstrap-scope .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .bootstrap-scope .offset-lg-6 {
    margin-left: 50%;
  }
  .bootstrap-scope .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .bootstrap-scope .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .bootstrap-scope .offset-lg-9 {
    margin-left: 75%;
  }
  .bootstrap-scope .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .bootstrap-scope .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .bootstrap-scope .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .bootstrap-scope .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .bootstrap-scope .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .bootstrap-scope .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .bootstrap-scope .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .bootstrap-scope .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .bootstrap-scope .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .bootstrap-scope .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .bootstrap-scope .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .bootstrap-scope .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .bootstrap-scope .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .bootstrap-scope .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .bootstrap-scope .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .bootstrap-scope .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .bootstrap-scope .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .bootstrap-scope .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .bootstrap-scope .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .bootstrap-scope .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .bootstrap-scope .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .bootstrap-scope .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .bootstrap-scope .order-xl-first {
    order: -1;
  }
  .bootstrap-scope .order-xl-last {
    order: 13;
  }
  .bootstrap-scope .order-xl-0 {
    order: 0;
  }
  .bootstrap-scope .order-xl-1 {
    order: 1;
  }
  .bootstrap-scope .order-xl-2 {
    order: 2;
  }
  .bootstrap-scope .order-xl-3 {
    order: 3;
  }
  .bootstrap-scope .order-xl-4 {
    order: 4;
  }
  .bootstrap-scope .order-xl-5 {
    order: 5;
  }
  .bootstrap-scope .order-xl-6 {
    order: 6;
  }
  .bootstrap-scope .order-xl-7 {
    order: 7;
  }
  .bootstrap-scope .order-xl-8 {
    order: 8;
  }
  .bootstrap-scope .order-xl-9 {
    order: 9;
  }
  .bootstrap-scope .order-xl-10 {
    order: 10;
  }
  .bootstrap-scope .order-xl-11 {
    order: 11;
  }
  .bootstrap-scope .order-xl-12 {
    order: 12;
  }
  .bootstrap-scope .offset-xl-0 {
    margin-left: 0;
  }
  .bootstrap-scope .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .bootstrap-scope .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .bootstrap-scope .offset-xl-3 {
    margin-left: 25%;
  }
  .bootstrap-scope .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .bootstrap-scope .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .bootstrap-scope .offset-xl-6 {
    margin-left: 50%;
  }
  .bootstrap-scope .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .bootstrap-scope .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .bootstrap-scope .offset-xl-9 {
    margin-left: 75%;
  }
  .bootstrap-scope .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .bootstrap-scope .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.bootstrap-scope .table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.bootstrap-scope .table th,
.bootstrap-scope .table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.bootstrap-scope .table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.bootstrap-scope .table tbody + tbody {
  border-top: 2px solid #dee2e6;
}
.bootstrap-scope .table-sm th,
.bootstrap-scope .table-sm td {
  padding: 0.3rem;
}
.bootstrap-scope .table-bordered {
  border: 1px solid #dee2e6;
}
.bootstrap-scope .table-bordered th,
.bootstrap-scope .table-bordered td {
  border: 1px solid #dee2e6;
}
.bootstrap-scope .table-bordered thead th,
.bootstrap-scope .table-bordered thead td {
  border-bottom-width: 2px;
}
.bootstrap-scope .table-borderless th,
.bootstrap-scope .table-borderless td,
.bootstrap-scope .table-borderless thead th,
.bootstrap-scope .table-borderless tbody + tbody {
  border: 0;
}
.bootstrap-scope .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.bootstrap-scope .table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}
.bootstrap-scope .table-primary,
.bootstrap-scope .table-primary > th,
.bootstrap-scope .table-primary > td {
  background-color: #b8daff;
}
.bootstrap-scope .table-primary th,
.bootstrap-scope .table-primary td,
.bootstrap-scope .table-primary thead th,
.bootstrap-scope .table-primary tbody + tbody {
  border-color: #7abaff;
}
.bootstrap-scope .table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.bootstrap-scope .table-hover .table-primary:hover > td,
.bootstrap-scope .table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}
.bootstrap-scope .table-secondary,
.bootstrap-scope .table-secondary > th,
.bootstrap-scope .table-secondary > td {
  background-color: #d6d8db;
}
.bootstrap-scope .table-secondary th,
.bootstrap-scope .table-secondary td,
.bootstrap-scope .table-secondary thead th,
.bootstrap-scope .table-secondary tbody + tbody {
  border-color: #b3b7bb;
}
.bootstrap-scope .table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.bootstrap-scope .table-hover .table-secondary:hover > td,
.bootstrap-scope .table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}
.bootstrap-scope .table-success,
.bootstrap-scope .table-success > th,
.bootstrap-scope .table-success > td {
  background-color: #c3e6cb;
}
.bootstrap-scope .table-success th,
.bootstrap-scope .table-success td,
.bootstrap-scope .table-success thead th,
.bootstrap-scope .table-success tbody + tbody {
  border-color: #8fd19e;
}
.bootstrap-scope .table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.bootstrap-scope .table-hover .table-success:hover > td,
.bootstrap-scope .table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}
.bootstrap-scope .table-info,
.bootstrap-scope .table-info > th,
.bootstrap-scope .table-info > td {
  background-color: #bee5eb;
}
.bootstrap-scope .table-info th,
.bootstrap-scope .table-info td,
.bootstrap-scope .table-info thead th,
.bootstrap-scope .table-info tbody + tbody {
  border-color: #86cfda;
}
.bootstrap-scope .table-hover .table-info:hover {
  background-color: #abdde5;
}
.bootstrap-scope .table-hover .table-info:hover > td,
.bootstrap-scope .table-hover .table-info:hover > th {
  background-color: #abdde5;
}
.bootstrap-scope .table-warning,
.bootstrap-scope .table-warning > th,
.bootstrap-scope .table-warning > td {
  background-color: #ffeeba;
}
.bootstrap-scope .table-warning th,
.bootstrap-scope .table-warning td,
.bootstrap-scope .table-warning thead th,
.bootstrap-scope .table-warning tbody + tbody {
  border-color: #ffdf7e;
}
.bootstrap-scope .table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.bootstrap-scope .table-hover .table-warning:hover > td,
.bootstrap-scope .table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}
.bootstrap-scope .table-danger,
.bootstrap-scope .table-danger > th,
.bootstrap-scope .table-danger > td {
  background-color: #f5c6cb;
}
.bootstrap-scope .table-danger th,
.bootstrap-scope .table-danger td,
.bootstrap-scope .table-danger thead th,
.bootstrap-scope .table-danger tbody + tbody {
  border-color: #ed969e;
}
.bootstrap-scope .table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.bootstrap-scope .table-hover .table-danger:hover > td,
.bootstrap-scope .table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}
.bootstrap-scope .table-light,
.bootstrap-scope .table-light > th,
.bootstrap-scope .table-light > td {
  background-color: #fdfdfe;
}
.bootstrap-scope .table-light th,
.bootstrap-scope .table-light td,
.bootstrap-scope .table-light thead th,
.bootstrap-scope .table-light tbody + tbody {
  border-color: #fbfcfc;
}
.bootstrap-scope .table-hover .table-light:hover {
  background-color: #ececf6;
}
.bootstrap-scope .table-hover .table-light:hover > td,
.bootstrap-scope .table-hover .table-light:hover > th {
  background-color: #ececf6;
}
.bootstrap-scope .table-dark,
.bootstrap-scope .table-dark > th,
.bootstrap-scope .table-dark > td {
  background-color: #c6c8ca;
}
.bootstrap-scope .table-dark th,
.bootstrap-scope .table-dark td,
.bootstrap-scope .table-dark thead th,
.bootstrap-scope .table-dark tbody + tbody {
  border-color: #95999c;
}
.bootstrap-scope .table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.bootstrap-scope .table-hover .table-dark:hover > td,
.bootstrap-scope .table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}
.bootstrap-scope .table-active,
.bootstrap-scope .table-active > th,
.bootstrap-scope .table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}
.bootstrap-scope .table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.bootstrap-scope .table-hover .table-active:hover > td,
.bootstrap-scope .table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}
.bootstrap-scope .table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.bootstrap-scope .table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.bootstrap-scope .table-dark {
  color: #fff;
  background-color: #343a40;
}
.bootstrap-scope .table-dark th,
.bootstrap-scope .table-dark td,
.bootstrap-scope .table-dark thead th {
  border-color: #454d55;
}
.bootstrap-scope .table-dark.table-bordered {
  border: 0;
}
.bootstrap-scope .table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.bootstrap-scope .table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}
@media (max-width: 575.98px) {
  .bootstrap-scope .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .bootstrap-scope .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .bootstrap-scope .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .bootstrap-scope .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .bootstrap-scope .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .bootstrap-scope .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .bootstrap-scope .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .bootstrap-scope .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.bootstrap-scope .table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.bootstrap-scope .table-responsive > .table-bordered {
  border: 0;
}
.bootstrap-scope .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .bootstrap-scope .form-control {
    transition: none;
  }
}
.bootstrap-scope .form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.bootstrap-scope .form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.bootstrap-scope .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.bootstrap-scope .form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.bootstrap-scope .form-control:disabled, .bootstrap-scope .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.bootstrap-scope select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.bootstrap-scope .form-control-file,
.bootstrap-scope .form-control-range {
  display: block;
  width: 100%;
}
.bootstrap-scope .col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.bootstrap-scope .col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}
.bootstrap-scope .col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}
.bootstrap-scope .form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.bootstrap-scope .form-control-plaintext.form-control-sm, .bootstrap-scope .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}
.bootstrap-scope .form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.bootstrap-scope .form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.bootstrap-scope select.form-control[size], .bootstrap-scope select.form-control[multiple] {
  height: auto;
}
.bootstrap-scope textarea.form-control {
  height: auto;
}
.bootstrap-scope .form-group {
  margin-bottom: 1rem;
}
.bootstrap-scope .form-text {
  display: block;
  margin-top: 0.25rem;
}
.bootstrap-scope .form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.bootstrap-scope .form-row > .col,
.bootstrap-scope .form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}
.bootstrap-scope .form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.bootstrap-scope .form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.bootstrap-scope .form-check-input[disabled] ~ .form-check-label, .bootstrap-scope .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}
.bootstrap-scope .form-check-label {
  margin-bottom: 0;
}
.bootstrap-scope .form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.bootstrap-scope .form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
.bootstrap-scope .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}
.bootstrap-scope .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.was-validated .bootstrap-scope:valid ~ .valid-feedback,
.was-validated .bootstrap-scope:valid ~ .valid-tooltip, .bootstrap-scope.is-valid ~ .valid-feedback,
.bootstrap-scope.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .bootstrap-scope .form-control:valid, .bootstrap-scope .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .bootstrap-scope .form-control:valid:focus, .bootstrap-scope .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .bootstrap-scope textarea.form-control:valid, .bootstrap-scope textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}
.was-validated .bootstrap-scope .custom-select:valid, .bootstrap-scope .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .bootstrap-scope .custom-select:valid:focus, .bootstrap-scope .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .bootstrap-scope .form-check-input:valid ~ .form-check-label, .bootstrap-scope .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .bootstrap-scope .form-check-input:valid ~ .valid-feedback,
.was-validated .bootstrap-scope .form-check-input:valid ~ .valid-tooltip, .bootstrap-scope .form-check-input.is-valid ~ .valid-feedback,
.bootstrap-scope .form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .bootstrap-scope .custom-control-input:valid ~ .custom-control-label, .bootstrap-scope .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .bootstrap-scope .custom-control-input:valid ~ .custom-control-label::before, .bootstrap-scope .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .bootstrap-scope .custom-control-input:valid:checked ~ .custom-control-label::before, .bootstrap-scope .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .bootstrap-scope .custom-control-input:valid:focus ~ .custom-control-label::before, .bootstrap-scope .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .bootstrap-scope .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .bootstrap-scope .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .bootstrap-scope .custom-file-input:valid ~ .custom-file-label, .bootstrap-scope .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .bootstrap-scope .custom-file-input:valid:focus ~ .custom-file-label, .bootstrap-scope .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.bootstrap-scope .invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
.bootstrap-scope .invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.was-validated .bootstrap-scope:invalid ~ .invalid-feedback,
.was-validated .bootstrap-scope:invalid ~ .invalid-tooltip, .bootstrap-scope.is-invalid ~ .invalid-feedback,
.bootstrap-scope.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .bootstrap-scope .form-control:invalid, .bootstrap-scope .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .bootstrap-scope .form-control:invalid:focus, .bootstrap-scope .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .bootstrap-scope textarea.form-control:invalid, .bootstrap-scope textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}
.was-validated .bootstrap-scope .custom-select:invalid, .bootstrap-scope .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .bootstrap-scope .custom-select:invalid:focus, .bootstrap-scope .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .bootstrap-scope .form-check-input:invalid ~ .form-check-label, .bootstrap-scope .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .bootstrap-scope .form-check-input:invalid ~ .invalid-feedback,
.was-validated .bootstrap-scope .form-check-input:invalid ~ .invalid-tooltip, .bootstrap-scope .form-check-input.is-invalid ~ .invalid-feedback,
.bootstrap-scope .form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .bootstrap-scope .custom-control-input:invalid ~ .custom-control-label, .bootstrap-scope .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .bootstrap-scope .custom-control-input:invalid ~ .custom-control-label::before, .bootstrap-scope .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .bootstrap-scope .custom-control-input:invalid:checked ~ .custom-control-label::before, .bootstrap-scope .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .bootstrap-scope .custom-control-input:invalid:focus ~ .custom-control-label::before, .bootstrap-scope .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .bootstrap-scope .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .bootstrap-scope .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .bootstrap-scope .custom-file-input:invalid ~ .custom-file-label, .bootstrap-scope .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .bootstrap-scope .custom-file-input:invalid:focus ~ .custom-file-label, .bootstrap-scope .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.bootstrap-scope .form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.bootstrap-scope .form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .bootstrap-scope .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .bootstrap-scope .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .bootstrap-scope .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .bootstrap-scope .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .bootstrap-scope .form-inline .input-group,
.bootstrap-scope .form-inline .custom-select {
    width: auto;
  }
  .bootstrap-scope .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .bootstrap-scope .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .bootstrap-scope .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .bootstrap-scope .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}
.bootstrap-scope .btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .bootstrap-scope .btn {
    transition: none;
  }
}
.bootstrap-scope .btn:hover {
  color: #212529;
  text-decoration: none;
}
.bootstrap-scope .btn:focus, .bootstrap-scope .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.bootstrap-scope .btn.disabled, .bootstrap-scope .btn:disabled {
  opacity: 0.65;
}
.bootstrap-scope a.btn.disabled,
.bootstrap-scope fieldset:disabled a.btn {
  pointer-events: none;
}
.bootstrap-scope .btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.bootstrap-scope .btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.bootstrap-scope .btn-primary:focus, .bootstrap-scope .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.bootstrap-scope .btn-primary.disabled, .bootstrap-scope .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.bootstrap-scope .btn-primary:not(:disabled):not(.disabled):active, .bootstrap-scope .btn-primary:not(:disabled):not(.disabled).active, .show > .bootstrap-scope .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.bootstrap-scope .btn-primary:not(:disabled):not(.disabled):active:focus, .bootstrap-scope .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .bootstrap-scope .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.bootstrap-scope .btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.bootstrap-scope .btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.bootstrap-scope .btn-secondary:focus, .bootstrap-scope .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.bootstrap-scope .btn-secondary.disabled, .bootstrap-scope .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.bootstrap-scope .btn-secondary:not(:disabled):not(.disabled):active, .bootstrap-scope .btn-secondary:not(:disabled):not(.disabled).active, .show > .bootstrap-scope .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.bootstrap-scope .btn-secondary:not(:disabled):not(.disabled):active:focus, .bootstrap-scope .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .bootstrap-scope .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.bootstrap-scope .btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.bootstrap-scope .btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.bootstrap-scope .btn-success:focus, .bootstrap-scope .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.bootstrap-scope .btn-success.disabled, .bootstrap-scope .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.bootstrap-scope .btn-success:not(:disabled):not(.disabled):active, .bootstrap-scope .btn-success:not(:disabled):not(.disabled).active, .show > .bootstrap-scope .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.bootstrap-scope .btn-success:not(:disabled):not(.disabled):active:focus, .bootstrap-scope .btn-success:not(:disabled):not(.disabled).active:focus, .show > .bootstrap-scope .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.bootstrap-scope .btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.bootstrap-scope .btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.bootstrap-scope .btn-info:focus, .bootstrap-scope .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.bootstrap-scope .btn-info.disabled, .bootstrap-scope .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.bootstrap-scope .btn-info:not(:disabled):not(.disabled):active, .bootstrap-scope .btn-info:not(:disabled):not(.disabled).active, .show > .bootstrap-scope .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.bootstrap-scope .btn-info:not(:disabled):not(.disabled):active:focus, .bootstrap-scope .btn-info:not(:disabled):not(.disabled).active:focus, .show > .bootstrap-scope .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.bootstrap-scope .btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.bootstrap-scope .btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.bootstrap-scope .btn-warning:focus, .bootstrap-scope .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.bootstrap-scope .btn-warning.disabled, .bootstrap-scope .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.bootstrap-scope .btn-warning:not(:disabled):not(.disabled):active, .bootstrap-scope .btn-warning:not(:disabled):not(.disabled).active, .show > .bootstrap-scope .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.bootstrap-scope .btn-warning:not(:disabled):not(.disabled):active:focus, .bootstrap-scope .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .bootstrap-scope .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.bootstrap-scope .btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.bootstrap-scope .btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.bootstrap-scope .btn-danger:focus, .bootstrap-scope .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.bootstrap-scope .btn-danger.disabled, .bootstrap-scope .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.bootstrap-scope .btn-danger:not(:disabled):not(.disabled):active, .bootstrap-scope .btn-danger:not(:disabled):not(.disabled).active, .show > .bootstrap-scope .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.bootstrap-scope .btn-danger:not(:disabled):not(.disabled):active:focus, .bootstrap-scope .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .bootstrap-scope .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.bootstrap-scope .btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.bootstrap-scope .btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.bootstrap-scope .btn-light:focus, .bootstrap-scope .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.bootstrap-scope .btn-light.disabled, .bootstrap-scope .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.bootstrap-scope .btn-light:not(:disabled):not(.disabled):active, .bootstrap-scope .btn-light:not(:disabled):not(.disabled).active, .show > .bootstrap-scope .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.bootstrap-scope .btn-light:not(:disabled):not(.disabled):active:focus, .bootstrap-scope .btn-light:not(:disabled):not(.disabled).active:focus, .show > .bootstrap-scope .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.bootstrap-scope .btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.bootstrap-scope .btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.bootstrap-scope .btn-dark:focus, .bootstrap-scope .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.bootstrap-scope .btn-dark.disabled, .bootstrap-scope .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.bootstrap-scope .btn-dark:not(:disabled):not(.disabled):active, .bootstrap-scope .btn-dark:not(:disabled):not(.disabled).active, .show > .bootstrap-scope .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.bootstrap-scope .btn-dark:not(:disabled):not(.disabled):active:focus, .bootstrap-scope .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .bootstrap-scope .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.bootstrap-scope .btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.bootstrap-scope .btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.bootstrap-scope .btn-outline-primary:focus, .bootstrap-scope .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.bootstrap-scope .btn-outline-primary.disabled, .bootstrap-scope .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.bootstrap-scope .btn-outline-primary:not(:disabled):not(.disabled):active, .bootstrap-scope .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .bootstrap-scope .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.bootstrap-scope .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .bootstrap-scope .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .bootstrap-scope .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.bootstrap-scope .btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.bootstrap-scope .btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.bootstrap-scope .btn-outline-secondary:focus, .bootstrap-scope .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.bootstrap-scope .btn-outline-secondary.disabled, .bootstrap-scope .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.bootstrap-scope .btn-outline-secondary:not(:disabled):not(.disabled):active, .bootstrap-scope .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .bootstrap-scope .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.bootstrap-scope .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .bootstrap-scope .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .bootstrap-scope .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.bootstrap-scope .btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.bootstrap-scope .btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.bootstrap-scope .btn-outline-success:focus, .bootstrap-scope .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.bootstrap-scope .btn-outline-success.disabled, .bootstrap-scope .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.bootstrap-scope .btn-outline-success:not(:disabled):not(.disabled):active, .bootstrap-scope .btn-outline-success:not(:disabled):not(.disabled).active, .show > .bootstrap-scope .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.bootstrap-scope .btn-outline-success:not(:disabled):not(.disabled):active:focus, .bootstrap-scope .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .bootstrap-scope .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.bootstrap-scope .btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.bootstrap-scope .btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.bootstrap-scope .btn-outline-info:focus, .bootstrap-scope .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.bootstrap-scope .btn-outline-info.disabled, .bootstrap-scope .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.bootstrap-scope .btn-outline-info:not(:disabled):not(.disabled):active, .bootstrap-scope .btn-outline-info:not(:disabled):not(.disabled).active, .show > .bootstrap-scope .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.bootstrap-scope .btn-outline-info:not(:disabled):not(.disabled):active:focus, .bootstrap-scope .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .bootstrap-scope .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.bootstrap-scope .btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.bootstrap-scope .btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.bootstrap-scope .btn-outline-warning:focus, .bootstrap-scope .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.bootstrap-scope .btn-outline-warning.disabled, .bootstrap-scope .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.bootstrap-scope .btn-outline-warning:not(:disabled):not(.disabled):active, .bootstrap-scope .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .bootstrap-scope .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.bootstrap-scope .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .bootstrap-scope .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .bootstrap-scope .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.bootstrap-scope .btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.bootstrap-scope .btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.bootstrap-scope .btn-outline-danger:focus, .bootstrap-scope .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.bootstrap-scope .btn-outline-danger.disabled, .bootstrap-scope .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.bootstrap-scope .btn-outline-danger:not(:disabled):not(.disabled):active, .bootstrap-scope .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .bootstrap-scope .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.bootstrap-scope .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .bootstrap-scope .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .bootstrap-scope .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.bootstrap-scope .btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.bootstrap-scope .btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.bootstrap-scope .btn-outline-light:focus, .bootstrap-scope .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.bootstrap-scope .btn-outline-light.disabled, .bootstrap-scope .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.bootstrap-scope .btn-outline-light:not(:disabled):not(.disabled):active, .bootstrap-scope .btn-outline-light:not(:disabled):not(.disabled).active, .show > .bootstrap-scope .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.bootstrap-scope .btn-outline-light:not(:disabled):not(.disabled):active:focus, .bootstrap-scope .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .bootstrap-scope .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.bootstrap-scope .btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.bootstrap-scope .btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.bootstrap-scope .btn-outline-dark:focus, .bootstrap-scope .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.bootstrap-scope .btn-outline-dark.disabled, .bootstrap-scope .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.bootstrap-scope .btn-outline-dark:not(:disabled):not(.disabled):active, .bootstrap-scope .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .bootstrap-scope .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.bootstrap-scope .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .bootstrap-scope .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .bootstrap-scope .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.bootstrap-scope .btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.bootstrap-scope .btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.bootstrap-scope .btn-link:focus, .bootstrap-scope .btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}
.bootstrap-scope .btn-link:disabled, .bootstrap-scope .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}
.bootstrap-scope .btn-lg, .bootstrap-scope .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.bootstrap-scope .btn-sm, .bootstrap-scope .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.bootstrap-scope .btn-block {
  display: block;
  width: 100%;
}
.bootstrap-scope .btn-block + .btn-block {
  margin-top: 0.5rem;
}
.bootstrap-scope input[type=submit].btn-block,
.bootstrap-scope input[type=reset].btn-block,
.bootstrap-scope input[type=button].btn-block {
  width: 100%;
}
.bootstrap-scope .fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .bootstrap-scope .fade {
    transition: none;
  }
}
.bootstrap-scope .fade:not(.show) {
  opacity: 0;
}
.bootstrap-scope .collapse:not(.show) {
  display: none;
}
.bootstrap-scope .collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .bootstrap-scope .collapsing {
    transition: none;
  }
}
.bootstrap-scope .dropup,
.bootstrap-scope .dropright,
.bootstrap-scope .dropdown,
.bootstrap-scope .dropleft {
  position: relative;
}
.bootstrap-scope .dropdown-toggle {
  white-space: nowrap;
}
.bootstrap-scope .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.bootstrap-scope .dropdown-toggle:empty::after {
  margin-left: 0;
}
.bootstrap-scope .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.bootstrap-scope .dropdown-menu-left {
  right: auto;
  left: 0;
}
.bootstrap-scope .dropdown-menu-right {
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
  .bootstrap-scope .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .bootstrap-scope .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .bootstrap-scope .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .bootstrap-scope .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .bootstrap-scope .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .bootstrap-scope .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .bootstrap-scope .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .bootstrap-scope .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.bootstrap-scope .dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.bootstrap-scope .dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.bootstrap-scope .dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.bootstrap-scope .dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.bootstrap-scope .dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.bootstrap-scope .dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.bootstrap-scope .dropright .dropdown-toggle::after {
  vertical-align: 0;
}
.bootstrap-scope .dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.bootstrap-scope .dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.bootstrap-scope .dropleft .dropdown-toggle::after {
  display: none;
}
.bootstrap-scope .dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.bootstrap-scope .dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.bootstrap-scope .dropleft .dropdown-toggle::before {
  vertical-align: 0;
}
.bootstrap-scope .dropdown-menu[x-placement^=top], .bootstrap-scope .dropdown-menu[x-placement^=right], .bootstrap-scope .dropdown-menu[x-placement^=bottom], .bootstrap-scope .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}
.bootstrap-scope .dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
.bootstrap-scope .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.bootstrap-scope .dropdown-item:hover, .bootstrap-scope .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.bootstrap-scope .dropdown-item.active, .bootstrap-scope .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.bootstrap-scope .dropdown-item.disabled, .bootstrap-scope .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}
.bootstrap-scope .dropdown-menu.show {
  display: block;
}
.bootstrap-scope .dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}
.bootstrap-scope .dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}
.bootstrap-scope .btn-group,
.bootstrap-scope .btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.bootstrap-scope .btn-group > .btn,
.bootstrap-scope .btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.bootstrap-scope .btn-group > .btn:hover,
.bootstrap-scope .btn-group-vertical > .btn:hover {
  z-index: 1;
}
.bootstrap-scope .btn-group > .btn:focus, .bootstrap-scope .btn-group > .btn:active, .bootstrap-scope .btn-group > .btn.active,
.bootstrap-scope .btn-group-vertical > .btn:focus,
.bootstrap-scope .btn-group-vertical > .btn:active,
.bootstrap-scope .btn-group-vertical > .btn.active {
  z-index: 1;
}
.bootstrap-scope .btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.bootstrap-scope .btn-toolbar .input-group {
  width: auto;
}
.bootstrap-scope .btn-group > .btn:not(:first-child),
.bootstrap-scope .btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.bootstrap-scope .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.bootstrap-scope .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.bootstrap-scope .btn-group > .btn:not(:first-child),
.bootstrap-scope .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.bootstrap-scope .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.bootstrap-scope .dropdown-toggle-split::after, .dropup .bootstrap-scope .dropdown-toggle-split::after, .dropright .bootstrap-scope .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .bootstrap-scope .dropdown-toggle-split::before {
  margin-right: 0;
}
.bootstrap-scope .btn-sm + .dropdown-toggle-split, .bootstrap-scope .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}
.bootstrap-scope .btn-lg + .dropdown-toggle-split, .bootstrap-scope .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.bootstrap-scope .btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.bootstrap-scope .btn-group-vertical > .btn,
.bootstrap-scope .btn-group-vertical > .btn-group {
  width: 100%;
}
.bootstrap-scope .btn-group-vertical > .btn:not(:first-child),
.bootstrap-scope .btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.bootstrap-scope .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.bootstrap-scope .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.bootstrap-scope .btn-group-vertical > .btn:not(:first-child),
.bootstrap-scope .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.bootstrap-scope .btn-group-toggle > .btn,
.bootstrap-scope .btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.bootstrap-scope .btn-group-toggle > .btn input[type=radio],
.bootstrap-scope .btn-group-toggle > .btn input[type=checkbox],
.bootstrap-scope .btn-group-toggle > .btn-group > .btn input[type=radio],
.bootstrap-scope .btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.bootstrap-scope .input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.bootstrap-scope .input-group > .form-control,
.bootstrap-scope .input-group > .form-control-plaintext,
.bootstrap-scope .input-group > .custom-select,
.bootstrap-scope .input-group > .custom-file {
  position: relative;
  flex: 1 1 0%;
  min-width: 0;
  margin-bottom: 0;
}
.bootstrap-scope .input-group > .form-control + .form-control,
.bootstrap-scope .input-group > .form-control + .custom-select,
.bootstrap-scope .input-group > .form-control + .custom-file,
.bootstrap-scope .input-group > .form-control-plaintext + .form-control,
.bootstrap-scope .input-group > .form-control-plaintext + .custom-select,
.bootstrap-scope .input-group > .form-control-plaintext + .custom-file,
.bootstrap-scope .input-group > .custom-select + .form-control,
.bootstrap-scope .input-group > .custom-select + .custom-select,
.bootstrap-scope .input-group > .custom-select + .custom-file,
.bootstrap-scope .input-group > .custom-file + .form-control,
.bootstrap-scope .input-group > .custom-file + .custom-select,
.bootstrap-scope .input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.bootstrap-scope .input-group > .form-control:focus,
.bootstrap-scope .input-group > .custom-select:focus,
.bootstrap-scope .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.bootstrap-scope .input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.bootstrap-scope .input-group > .form-control:not(:last-child),
.bootstrap-scope .input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.bootstrap-scope .input-group > .form-control:not(:first-child),
.bootstrap-scope .input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.bootstrap-scope .input-group > .custom-file {
  display: flex;
  align-items: center;
}
.bootstrap-scope .input-group > .custom-file:not(:last-child) .custom-file-label, .bootstrap-scope .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.bootstrap-scope .input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.bootstrap-scope .input-group-prepend,
.bootstrap-scope .input-group-append {
  display: flex;
}
.bootstrap-scope .input-group-prepend .btn,
.bootstrap-scope .input-group-append .btn {
  position: relative;
  z-index: 2;
}
.bootstrap-scope .input-group-prepend .btn:focus,
.bootstrap-scope .input-group-append .btn:focus {
  z-index: 3;
}
.bootstrap-scope .input-group-prepend .btn + .btn,
.bootstrap-scope .input-group-prepend .btn + .input-group-text,
.bootstrap-scope .input-group-prepend .input-group-text + .input-group-text,
.bootstrap-scope .input-group-prepend .input-group-text + .btn,
.bootstrap-scope .input-group-append .btn + .btn,
.bootstrap-scope .input-group-append .btn + .input-group-text,
.bootstrap-scope .input-group-append .input-group-text + .input-group-text,
.bootstrap-scope .input-group-append .input-group-text + .btn {
  margin-left: -1px;
}
.bootstrap-scope .input-group-prepend {
  margin-right: -1px;
}
.bootstrap-scope .input-group-append {
  margin-left: -1px;
}
.bootstrap-scope .input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.bootstrap-scope .input-group-text input[type=radio],
.bootstrap-scope .input-group-text input[type=checkbox] {
  margin-top: 0;
}
.bootstrap-scope .input-group-lg > .form-control:not(textarea),
.bootstrap-scope .input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}
.bootstrap-scope .input-group-lg > .form-control,
.bootstrap-scope .input-group-lg > .custom-select,
.bootstrap-scope .input-group-lg > .input-group-prepend > .input-group-text,
.bootstrap-scope .input-group-lg > .input-group-append > .input-group-text,
.bootstrap-scope .input-group-lg > .input-group-prepend > .btn,
.bootstrap-scope .input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.bootstrap-scope .input-group-sm > .form-control:not(textarea),
.bootstrap-scope .input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}
.bootstrap-scope .input-group-sm > .form-control,
.bootstrap-scope .input-group-sm > .custom-select,
.bootstrap-scope .input-group-sm > .input-group-prepend > .input-group-text,
.bootstrap-scope .input-group-sm > .input-group-append > .input-group-text,
.bootstrap-scope .input-group-sm > .input-group-prepend > .btn,
.bootstrap-scope .input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.bootstrap-scope .input-group-lg > .custom-select,
.bootstrap-scope .input-group-sm > .custom-select {
  padding-right: 1.75rem;
}
.bootstrap-scope .input-group > .input-group-prepend > .btn,
.bootstrap-scope .input-group > .input-group-prepend > .input-group-text,
.bootstrap-scope .input-group > .input-group-append:not(:last-child) > .btn,
.bootstrap-scope .input-group > .input-group-append:not(:last-child) > .input-group-text,
.bootstrap-scope .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.bootstrap-scope .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.bootstrap-scope .input-group > .input-group-append > .btn,
.bootstrap-scope .input-group > .input-group-append > .input-group-text,
.bootstrap-scope .input-group > .input-group-prepend:not(:first-child) > .btn,
.bootstrap-scope .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.bootstrap-scope .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.bootstrap-scope .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.bootstrap-scope .custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}
.bootstrap-scope .custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}
.bootstrap-scope .custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.bootstrap-scope .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.bootstrap-scope .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.bootstrap-scope .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.bootstrap-scope .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.bootstrap-scope .custom-control-input[disabled] ~ .custom-control-label, .bootstrap-scope .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.bootstrap-scope .custom-control-input[disabled] ~ .custom-control-label::before, .bootstrap-scope .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}
.bootstrap-scope .custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.bootstrap-scope .custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.bootstrap-scope .custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}
.bootstrap-scope .custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.bootstrap-scope .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.bootstrap-scope .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.bootstrap-scope .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.bootstrap-scope .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.bootstrap-scope .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.bootstrap-scope .custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.bootstrap-scope .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.bootstrap-scope .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.bootstrap-scope .custom-switch {
  padding-left: 2.25rem;
}
.bootstrap-scope .custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.bootstrap-scope .custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .bootstrap-scope .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.bootstrap-scope .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.bootstrap-scope .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.bootstrap-scope .custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.bootstrap-scope .custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.bootstrap-scope .custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.bootstrap-scope .custom-select[multiple], .bootstrap-scope .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.bootstrap-scope .custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.bootstrap-scope .custom-select::-ms-expand {
  display: none;
}
.bootstrap-scope .custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.bootstrap-scope .custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}
.bootstrap-scope .custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}
.bootstrap-scope .custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}
.bootstrap-scope .custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}
.bootstrap-scope .custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.bootstrap-scope .custom-file-input[disabled] ~ .custom-file-label, .bootstrap-scope .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.bootstrap-scope .custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.bootstrap-scope .custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}
.bootstrap-scope .custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.bootstrap-scope .custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}
.bootstrap-scope .custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.bootstrap-scope .custom-range:focus {
  outline: none;
}
.bootstrap-scope .custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.bootstrap-scope .custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.bootstrap-scope .custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.bootstrap-scope .custom-range::-moz-focus-outer {
  border: 0;
}
.bootstrap-scope .custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .bootstrap-scope .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.bootstrap-scope .custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.bootstrap-scope .custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.bootstrap-scope .custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .bootstrap-scope .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.bootstrap-scope .custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.bootstrap-scope .custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.bootstrap-scope .custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .bootstrap-scope .custom-range::-ms-thumb {
    transition: none;
  }
}
.bootstrap-scope .custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.bootstrap-scope .custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.bootstrap-scope .custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.bootstrap-scope .custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.bootstrap-scope .custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.bootstrap-scope .custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.bootstrap-scope .custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.bootstrap-scope .custom-range:disabled::-moz-range-track {
  cursor: default;
}
.bootstrap-scope .custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}
.bootstrap-scope .custom-control-label::before,
.bootstrap-scope .custom-file-label,
.bootstrap-scope .custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .bootstrap-scope .custom-control-label::before,
.bootstrap-scope .custom-file-label,
.bootstrap-scope .custom-select {
    transition: none;
  }
}
.bootstrap-scope .nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.bootstrap-scope .nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.bootstrap-scope .nav-link:hover, .bootstrap-scope .nav-link:focus {
  text-decoration: none;
}
.bootstrap-scope .nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}
.bootstrap-scope .nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.bootstrap-scope .nav-tabs .nav-item {
  margin-bottom: -1px;
}
.bootstrap-scope .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.bootstrap-scope .nav-tabs .nav-link:hover, .bootstrap-scope .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.bootstrap-scope .nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.bootstrap-scope .nav-tabs .nav-link.active,
.bootstrap-scope .nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.bootstrap-scope .nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.bootstrap-scope .nav-pills .nav-link {
  border-radius: 0.25rem;
}
.bootstrap-scope .nav-pills .nav-link.active,
.bootstrap-scope .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}
.bootstrap-scope .nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}
.bootstrap-scope .nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.bootstrap-scope .tab-content > .tab-pane {
  display: none;
}
.bootstrap-scope .tab-content > .active {
  display: block;
}
.bootstrap-scope .navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.bootstrap-scope .navbar .container,
.bootstrap-scope .navbar .container-fluid,
.bootstrap-scope .navbar .container-sm,
.bootstrap-scope .navbar .container-md,
.bootstrap-scope .navbar .container-lg,
.bootstrap-scope .navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.bootstrap-scope .navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.bootstrap-scope .navbar-brand:hover, .bootstrap-scope .navbar-brand:focus {
  text-decoration: none;
}
.bootstrap-scope .navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.bootstrap-scope .navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.bootstrap-scope .navbar-nav .dropdown-menu {
  position: static;
  float: none;
}
.bootstrap-scope .navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.bootstrap-scope .navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
.bootstrap-scope .navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.bootstrap-scope .navbar-toggler:hover, .bootstrap-scope .navbar-toggler:focus {
  text-decoration: none;
}
.bootstrap-scope .navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}
@media (max-width: 575.98px) {
  .bootstrap-scope .navbar-expand-sm > .container,
.bootstrap-scope .navbar-expand-sm > .container-fluid,
.bootstrap-scope .navbar-expand-sm > .container-sm,
.bootstrap-scope .navbar-expand-sm > .container-md,
.bootstrap-scope .navbar-expand-sm > .container-lg,
.bootstrap-scope .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .bootstrap-scope .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .bootstrap-scope .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .bootstrap-scope .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .bootstrap-scope .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .bootstrap-scope .navbar-expand-sm > .container,
.bootstrap-scope .navbar-expand-sm > .container-fluid,
.bootstrap-scope .navbar-expand-sm > .container-sm,
.bootstrap-scope .navbar-expand-sm > .container-md,
.bootstrap-scope .navbar-expand-sm > .container-lg,
.bootstrap-scope .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .bootstrap-scope .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .bootstrap-scope .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .bootstrap-scope .navbar-expand-md > .container,
.bootstrap-scope .navbar-expand-md > .container-fluid,
.bootstrap-scope .navbar-expand-md > .container-sm,
.bootstrap-scope .navbar-expand-md > .container-md,
.bootstrap-scope .navbar-expand-md > .container-lg,
.bootstrap-scope .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .bootstrap-scope .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .bootstrap-scope .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .bootstrap-scope .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .bootstrap-scope .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .bootstrap-scope .navbar-expand-md > .container,
.bootstrap-scope .navbar-expand-md > .container-fluid,
.bootstrap-scope .navbar-expand-md > .container-sm,
.bootstrap-scope .navbar-expand-md > .container-md,
.bootstrap-scope .navbar-expand-md > .container-lg,
.bootstrap-scope .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .bootstrap-scope .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .bootstrap-scope .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .bootstrap-scope .navbar-expand-lg > .container,
.bootstrap-scope .navbar-expand-lg > .container-fluid,
.bootstrap-scope .navbar-expand-lg > .container-sm,
.bootstrap-scope .navbar-expand-lg > .container-md,
.bootstrap-scope .navbar-expand-lg > .container-lg,
.bootstrap-scope .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .bootstrap-scope .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .bootstrap-scope .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .bootstrap-scope .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .bootstrap-scope .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .bootstrap-scope .navbar-expand-lg > .container,
.bootstrap-scope .navbar-expand-lg > .container-fluid,
.bootstrap-scope .navbar-expand-lg > .container-sm,
.bootstrap-scope .navbar-expand-lg > .container-md,
.bootstrap-scope .navbar-expand-lg > .container-lg,
.bootstrap-scope .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .bootstrap-scope .navbar-expand-lg .navbar-collapse {
    /* margin-bottom: 50px; */
    position:relative;
    display: flex !important;
    flex-basis: auto;
  }

  .bootstrap-scope .navbar-expand-lg.is-sticky  .navbar-collapse {
    /* margin-bottom: 50px; */
   top:0px;
  }

  .bootstrap-scope .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .bootstrap-scope .navbar-expand-xl > .container,
.bootstrap-scope .navbar-expand-xl > .container-fluid,
.bootstrap-scope .navbar-expand-xl > .container-sm,
.bootstrap-scope .navbar-expand-xl > .container-md,
.bootstrap-scope .navbar-expand-xl > .container-lg,
.bootstrap-scope .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .bootstrap-scope .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .bootstrap-scope .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .bootstrap-scope .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .bootstrap-scope .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .bootstrap-scope .navbar-expand-xl > .container,
.bootstrap-scope .navbar-expand-xl > .container-fluid,
.bootstrap-scope .navbar-expand-xl > .container-sm,
.bootstrap-scope .navbar-expand-xl > .container-md,
.bootstrap-scope .navbar-expand-xl > .container-lg,
.bootstrap-scope .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .bootstrap-scope .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .bootstrap-scope .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.bootstrap-scope .navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.bootstrap-scope .navbar-expand > .container,
.bootstrap-scope .navbar-expand > .container-fluid,
.bootstrap-scope .navbar-expand > .container-sm,
.bootstrap-scope .navbar-expand > .container-md,
.bootstrap-scope .navbar-expand > .container-lg,
.bootstrap-scope .navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.bootstrap-scope .navbar-expand .navbar-nav {
  flex-direction: row;
}
.bootstrap-scope .navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.bootstrap-scope .navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.bootstrap-scope .navbar-expand > .container,
.bootstrap-scope .navbar-expand > .container-fluid,
.bootstrap-scope .navbar-expand > .container-sm,
.bootstrap-scope .navbar-expand > .container-md,
.bootstrap-scope .navbar-expand > .container-lg,
.bootstrap-scope .navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.bootstrap-scope .navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.bootstrap-scope .navbar-expand .navbar-toggler {
  display: none;
}
.bootstrap-scope .navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.bootstrap-scope .navbar-light .navbar-brand:hover, .bootstrap-scope .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.bootstrap-scope .navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.bootstrap-scope .navbar-light .navbar-nav .nav-link:hover, .bootstrap-scope .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.bootstrap-scope .navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.bootstrap-scope .navbar-light .navbar-nav .show > .nav-link,
.bootstrap-scope .navbar-light .navbar-nav .active > .nav-link,
.bootstrap-scope .navbar-light .navbar-nav .nav-link.show,
.bootstrap-scope .navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.bootstrap-scope .navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.bootstrap-scope .navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.bootstrap-scope .navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.bootstrap-scope .navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.bootstrap-scope .navbar-light .navbar-text a:hover, .bootstrap-scope .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}
.bootstrap-scope .navbar-dark .navbar-brand {
  color: #fff;
}
.bootstrap-scope .navbar-dark .navbar-brand:hover, .bootstrap-scope .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.bootstrap-scope .navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.bootstrap-scope .navbar-dark .navbar-nav .nav-link:hover, .bootstrap-scope .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.bootstrap-scope .navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.bootstrap-scope .navbar-dark .navbar-nav .show > .nav-link,
.bootstrap-scope .navbar-dark .navbar-nav .active > .nav-link,
.bootstrap-scope .navbar-dark .navbar-nav .nav-link.show,
.bootstrap-scope .navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.bootstrap-scope .navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.bootstrap-scope .navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.bootstrap-scope .navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.bootstrap-scope .navbar-dark .navbar-text a {
  color: #fff;
}
.bootstrap-scope .navbar-dark .navbar-text a:hover, .bootstrap-scope .navbar-dark .navbar-text a:focus {
  color: #fff;
}
.bootstrap-scope .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.bootstrap-scope .card > hr {
  margin-right: 0;
  margin-left: 0;
}
.bootstrap-scope .card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.bootstrap-scope .card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.bootstrap-scope .card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}
.bootstrap-scope .card-title {
  margin-bottom: 0.75rem;
}
.bootstrap-scope .card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}
.bootstrap-scope .card-text:last-child {
  margin-bottom: 0;
}
.bootstrap-scope .card-link:hover {
  text-decoration: none;
}
.bootstrap-scope .card-link + .card-link {
  margin-left: 1.25rem;
}
.bootstrap-scope .card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.bootstrap-scope .card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.bootstrap-scope .card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}
.bootstrap-scope .card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.bootstrap-scope .card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}
.bootstrap-scope .card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}
.bootstrap-scope .card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
.bootstrap-scope .card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}
.bootstrap-scope .card-img,
.bootstrap-scope .card-img-top,
.bootstrap-scope .card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}
.bootstrap-scope .card-img,
.bootstrap-scope .card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.bootstrap-scope .card-img,
.bootstrap-scope .card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.bootstrap-scope .card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .bootstrap-scope .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .bootstrap-scope .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}
.bootstrap-scope .card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .bootstrap-scope .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .bootstrap-scope .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .bootstrap-scope .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .bootstrap-scope .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .bootstrap-scope .card-group > .card:not(:last-child) .card-img-top,
.bootstrap-scope .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .bootstrap-scope .card-group > .card:not(:last-child) .card-img-bottom,
.bootstrap-scope .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .bootstrap-scope .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .bootstrap-scope .card-group > .card:not(:first-child) .card-img-top,
.bootstrap-scope .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .bootstrap-scope .card-group > .card:not(:first-child) .card-img-bottom,
.bootstrap-scope .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}
.bootstrap-scope .card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .bootstrap-scope .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .bootstrap-scope .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
.bootstrap-scope .accordion > .card {
  overflow: hidden;
}
.bootstrap-scope .accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.bootstrap-scope .accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.bootstrap-scope .accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}
.bootstrap-scope .breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.bootstrap-scope .breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.bootstrap-scope .breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.bootstrap-scope .breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.bootstrap-scope .breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.bootstrap-scope .breadcrumb-item.active {
  color: #6c757d;
}
.bootstrap-scope .pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
.bootstrap-scope .page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.bootstrap-scope .page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.bootstrap-scope .page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.bootstrap-scope .page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.bootstrap-scope .page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.bootstrap-scope .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.bootstrap-scope .page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}
.bootstrap-scope .pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.bootstrap-scope .pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.bootstrap-scope .pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.bootstrap-scope .pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.bootstrap-scope .pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.bootstrap-scope .pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.bootstrap-scope .badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .bootstrap-scope .badge {
    transition: none;
  }
}
a.bootstrap-scope .badge:hover, a.bootstrap-scope .badge:focus {
  text-decoration: none;
}

.bootstrap-scope .badge:empty {
  display: none;
}
.bootstrap-scope .btn .badge {
  position: relative;
  top: -1px;
}
.bootstrap-scope .badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.bootstrap-scope .badge-primary {
  color: #fff;
  background-color: #007bff;
}
a.bootstrap-scope .badge-primary:hover, a.bootstrap-scope .badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}
a.bootstrap-scope .badge-primary:focus, a.bootstrap-scope .badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.bootstrap-scope .badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.bootstrap-scope .badge-secondary:hover, a.bootstrap-scope .badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.bootstrap-scope .badge-secondary:focus, a.bootstrap-scope .badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.bootstrap-scope .badge-success {
  color: #fff;
  background-color: #28a745;
}
a.bootstrap-scope .badge-success:hover, a.bootstrap-scope .badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.bootstrap-scope .badge-success:focus, a.bootstrap-scope .badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.bootstrap-scope .badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.bootstrap-scope .badge-info:hover, a.bootstrap-scope .badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.bootstrap-scope .badge-info:focus, a.bootstrap-scope .badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.bootstrap-scope .badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.bootstrap-scope .badge-warning:hover, a.bootstrap-scope .badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.bootstrap-scope .badge-warning:focus, a.bootstrap-scope .badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.bootstrap-scope .badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.bootstrap-scope .badge-danger:hover, a.bootstrap-scope .badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.bootstrap-scope .badge-danger:focus, a.bootstrap-scope .badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.bootstrap-scope .badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.bootstrap-scope .badge-light:hover, a.bootstrap-scope .badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.bootstrap-scope .badge-light:focus, a.bootstrap-scope .badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.bootstrap-scope .badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.bootstrap-scope .badge-dark:hover, a.bootstrap-scope .badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.bootstrap-scope .badge-dark:focus, a.bootstrap-scope .badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.bootstrap-scope .jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .bootstrap-scope .jumbotron {
    padding: 4rem 2rem;
  }
}
.bootstrap-scope .jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
.bootstrap-scope .alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.bootstrap-scope .alert-heading {
  color: inherit;
}
.bootstrap-scope .alert-link {
  font-weight: 700;
}
.bootstrap-scope .alert-dismissible {
  padding-right: 4rem;
}
.bootstrap-scope .alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}
.bootstrap-scope .alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.bootstrap-scope .alert-primary hr {
  border-top-color: #9fcdff;
}
.bootstrap-scope .alert-primary .alert-link {
  color: #002752;
}
.bootstrap-scope .alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.bootstrap-scope .alert-secondary hr {
  border-top-color: #c8cbcf;
}
.bootstrap-scope .alert-secondary .alert-link {
  color: #202326;
}
.bootstrap-scope .alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.bootstrap-scope .alert-success hr {
  border-top-color: #b1dfbb;
}
.bootstrap-scope .alert-success .alert-link {
  color: #0b2e13;
}
.bootstrap-scope .alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.bootstrap-scope .alert-info hr {
  border-top-color: #abdde5;
}
.bootstrap-scope .alert-info .alert-link {
  color: #062c33;
}
.bootstrap-scope .alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.bootstrap-scope .alert-warning hr {
  border-top-color: #ffe8a1;
}
.bootstrap-scope .alert-warning .alert-link {
  color: #533f03;
}
.bootstrap-scope .alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.bootstrap-scope .alert-danger hr {
  border-top-color: #f1b0b7;
}
.bootstrap-scope .alert-danger .alert-link {
  color: #491217;
}
.bootstrap-scope .alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.bootstrap-scope .alert-light hr {
  border-top-color: #ececf6;
}
.bootstrap-scope .alert-light .alert-link {
  color: #686868;
}
.bootstrap-scope .alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.bootstrap-scope .alert-dark hr {
  border-top-color: #b9bbbe;
}
.bootstrap-scope .alert-dark .alert-link {
  color: #040505;
}
@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.bootstrap-scope .progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.bootstrap-scope .progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .bootstrap-scope .progress-bar {
    transition: none;
  }
}
.bootstrap-scope .progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}
.bootstrap-scope .progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .bootstrap-scope .progress-bar-animated {
    animation: none;
  }
}
.bootstrap-scope .media {
  display: flex;
  align-items: flex-start;
}
.bootstrap-scope .media-body {
  flex: 1;
}
.bootstrap-scope .list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}
.bootstrap-scope .list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.bootstrap-scope .list-group-item-action:hover, .bootstrap-scope .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.bootstrap-scope .list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}
.bootstrap-scope .list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.bootstrap-scope .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.bootstrap-scope .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.bootstrap-scope .list-group-item.disabled, .bootstrap-scope .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.bootstrap-scope .list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.bootstrap-scope .list-group-item + .bootstrap-scope .list-group-item {
  border-top-width: 0;
}
.bootstrap-scope .list-group-item + .bootstrap-scope .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}
.bootstrap-scope .list-group-horizontal {
  flex-direction: row;
}
.bootstrap-scope .list-group-horizontal .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.bootstrap-scope .list-group-horizontal .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.bootstrap-scope .list-group-horizontal .list-group-item.active {
  margin-top: 0;
}
.bootstrap-scope .list-group-horizontal .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.bootstrap-scope .list-group-horizontal .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}
@media (min-width: 576px) {
  .bootstrap-scope .list-group-horizontal-sm {
    flex-direction: row;
  }
  .bootstrap-scope .list-group-horizontal-sm .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .bootstrap-scope .list-group-horizontal-sm .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .bootstrap-scope .list-group-horizontal-sm .list-group-item.active {
    margin-top: 0;
  }
  .bootstrap-scope .list-group-horizontal-sm .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .bootstrap-scope .list-group-horizontal-sm .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .bootstrap-scope .list-group-horizontal-md {
    flex-direction: row;
  }
  .bootstrap-scope .list-group-horizontal-md .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .bootstrap-scope .list-group-horizontal-md .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .bootstrap-scope .list-group-horizontal-md .list-group-item.active {
    margin-top: 0;
  }
  .bootstrap-scope .list-group-horizontal-md .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .bootstrap-scope .list-group-horizontal-md .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .bootstrap-scope .list-group-horizontal-lg {
    flex-direction: row;
  }
  .bootstrap-scope .list-group-horizontal-lg .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .bootstrap-scope .list-group-horizontal-lg .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .bootstrap-scope .list-group-horizontal-lg .list-group-item.active {
    margin-top: 0;
  }
  .bootstrap-scope .list-group-horizontal-lg .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .bootstrap-scope .list-group-horizontal-lg .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .bootstrap-scope .list-group-horizontal-xl {
    flex-direction: row;
  }
  .bootstrap-scope .list-group-horizontal-xl .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .bootstrap-scope .list-group-horizontal-xl .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .bootstrap-scope .list-group-horizontal-xl .list-group-item.active {
    margin-top: 0;
  }
  .bootstrap-scope .list-group-horizontal-xl .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .bootstrap-scope .list-group-horizontal-xl .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.bootstrap-scope .list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0;
}
.bootstrap-scope .list-group-flush .list-group-item:first-child {
  border-top-width: 0;
}
.bootstrap-scope .list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0;
}
.bootstrap-scope .list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.bootstrap-scope .list-group-item-primary.list-group-item-action:hover, .bootstrap-scope .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}
.bootstrap-scope .list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}
.bootstrap-scope .list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.bootstrap-scope .list-group-item-secondary.list-group-item-action:hover, .bootstrap-scope .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.bootstrap-scope .list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}
.bootstrap-scope .list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.bootstrap-scope .list-group-item-success.list-group-item-action:hover, .bootstrap-scope .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.bootstrap-scope .list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}
.bootstrap-scope .list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.bootstrap-scope .list-group-item-info.list-group-item-action:hover, .bootstrap-scope .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.bootstrap-scope .list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}
.bootstrap-scope .list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.bootstrap-scope .list-group-item-warning.list-group-item-action:hover, .bootstrap-scope .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.bootstrap-scope .list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}
.bootstrap-scope .list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.bootstrap-scope .list-group-item-danger.list-group-item-action:hover, .bootstrap-scope .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.bootstrap-scope .list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}
.bootstrap-scope .list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.bootstrap-scope .list-group-item-light.list-group-item-action:hover, .bootstrap-scope .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.bootstrap-scope .list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}
.bootstrap-scope .list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.bootstrap-scope .list-group-item-dark.list-group-item-action:hover, .bootstrap-scope .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.bootstrap-scope .list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}
.bootstrap-scope .close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.bootstrap-scope .close:hover {
  color: #000;
  text-decoration: none;
}
.bootstrap-scope .close:not(:disabled):not(.disabled):hover, .bootstrap-scope .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}
.bootstrap-scope button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}
.bootstrap-scope a.close.disabled {
  pointer-events: none;
}
.bootstrap-scope .toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}
.bootstrap-scope .toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.bootstrap-scope .toast.showing {
  opacity: 1;
}
.bootstrap-scope .toast.show {
  display: block;
  opacity: 1;
}
.bootstrap-scope .toast.hide {
  display: none;
}
.bootstrap-scope .toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.bootstrap-scope .toast-body {
  padding: 0.75rem;
}
.bootstrap-scope .modal-open {
  overflow: hidden;
}
.bootstrap-scope .modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.bootstrap-scope .modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.bootstrap-scope .modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .bootstrap-scope .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .bootstrap-scope .modal-dialog {
    transition: none;
  }
}
.modal.show .bootstrap-scope .modal-dialog {
  transform: none;
}
.modal.modal-static .bootstrap-scope .modal-dialog {
  transform: scale(1.02);
}
.bootstrap-scope .modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.bootstrap-scope .modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.bootstrap-scope .modal-dialog-scrollable .modal-header,
.bootstrap-scope .modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.bootstrap-scope .modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.bootstrap-scope .modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.bootstrap-scope .modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}
.bootstrap-scope .modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.bootstrap-scope .modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.bootstrap-scope .modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}
.bootstrap-scope .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.bootstrap-scope .modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.bootstrap-scope .modal-backdrop.fade {
  opacity: 0;
}
.bootstrap-scope .modal-backdrop.show {
  opacity: 0.5;
}
.bootstrap-scope .modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.bootstrap-scope .modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}
.bootstrap-scope .modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.bootstrap-scope .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}
.bootstrap-scope .modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.bootstrap-scope .modal-footer > * {
  margin: 0.25rem;
}
.bootstrap-scope .modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .bootstrap-scope .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .bootstrap-scope .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .bootstrap-scope .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .bootstrap-scope .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .bootstrap-scope .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .bootstrap-scope .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .bootstrap-scope .modal-lg,
.bootstrap-scope .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .bootstrap-scope .modal-xl {
    max-width: 1140px;
  }
}
.bootstrap-scope .tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.bootstrap-scope .tooltip.show {
  opacity: 0.9;
}
.bootstrap-scope .tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.bootstrap-scope .tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bootstrap-scope .bs-tooltip-top, .bootstrap-scope .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bootstrap-scope .bs-tooltip-top .arrow, .bootstrap-scope .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bootstrap-scope .bs-tooltip-top .arrow::before, .bootstrap-scope .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}
.bootstrap-scope .bs-tooltip-right, .bootstrap-scope .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bootstrap-scope .bs-tooltip-right .arrow, .bootstrap-scope .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bootstrap-scope .bs-tooltip-right .arrow::before, .bootstrap-scope .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}
.bootstrap-scope .bs-tooltip-bottom, .bootstrap-scope .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bootstrap-scope .bs-tooltip-bottom .arrow, .bootstrap-scope .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bootstrap-scope .bs-tooltip-bottom .arrow::before, .bootstrap-scope .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}
.bootstrap-scope .bs-tooltip-left, .bootstrap-scope .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bootstrap-scope .bs-tooltip-left .arrow, .bootstrap-scope .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bootstrap-scope .bs-tooltip-left .arrow::before, .bootstrap-scope .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}
.bootstrap-scope .tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}
.bootstrap-scope .popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.bootstrap-scope .popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.bootstrap-scope .popover .arrow::before, .bootstrap-scope .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bootstrap-scope .bs-popover-top, .bootstrap-scope .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bootstrap-scope .bs-popover-top > .arrow, .bootstrap-scope .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bootstrap-scope .bs-popover-top > .arrow::before, .bootstrap-scope .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bootstrap-scope .bs-popover-top > .arrow::after, .bootstrap-scope .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}
.bootstrap-scope .bs-popover-right, .bootstrap-scope .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bootstrap-scope .bs-popover-right > .arrow, .bootstrap-scope .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bootstrap-scope .bs-popover-right > .arrow::before, .bootstrap-scope .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bootstrap-scope .bs-popover-right > .arrow::after, .bootstrap-scope .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}
.bootstrap-scope .bs-popover-bottom, .bootstrap-scope .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bootstrap-scope .bs-popover-bottom > .arrow, .bootstrap-scope .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bootstrap-scope .bs-popover-bottom > .arrow::before, .bootstrap-scope .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bootstrap-scope .bs-popover-bottom > .arrow::after, .bootstrap-scope .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bootstrap-scope .bs-popover-bottom .popover-header::before, .bootstrap-scope .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}
.bootstrap-scope .bs-popover-left, .bootstrap-scope .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bootstrap-scope .bs-popover-left > .arrow, .bootstrap-scope .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bootstrap-scope .bs-popover-left > .arrow::before, .bootstrap-scope .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bootstrap-scope .bs-popover-left > .arrow::after, .bootstrap-scope .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}
.bootstrap-scope .popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.bootstrap-scope .popover-header:empty {
  display: none;
}
.bootstrap-scope .popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}
.bootstrap-scope .carousel {
  position: relative;
}
.bootstrap-scope .carousel.pointer-event {
  touch-action: pan-y;
}
.bootstrap-scope .carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.bootstrap-scope .carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}
.bootstrap-scope .carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .bootstrap-scope .carousel-item {
    transition: none;
  }
}
.bootstrap-scope .carousel-item.active,
.bootstrap-scope .carousel-item-next,
.bootstrap-scope .carousel-item-prev {
  display: block;
}
.bootstrap-scope .carousel-item-next:not(.carousel-item-left),
.bootstrap-scope .active.carousel-item-right {
  transform: translateX(100%);
}
.bootstrap-scope .carousel-item-prev:not(.carousel-item-right),
.bootstrap-scope .active.carousel-item-left {
  transform: translateX(-100%);
}
.bootstrap-scope .carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.bootstrap-scope .carousel-fade .carousel-item.active,
.bootstrap-scope .carousel-fade .carousel-item-next.carousel-item-left,
.bootstrap-scope .carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.bootstrap-scope .carousel-fade .active.carousel-item-left,
.bootstrap-scope .carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .bootstrap-scope .carousel-fade .active.carousel-item-left,
.bootstrap-scope .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}
.bootstrap-scope .carousel-control-prev,
.bootstrap-scope .carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .bootstrap-scope .carousel-control-prev,
.bootstrap-scope .carousel-control-next {
    transition: none;
  }
}
.bootstrap-scope .carousel-control-prev:hover, .bootstrap-scope .carousel-control-prev:focus,
.bootstrap-scope .carousel-control-next:hover,
.bootstrap-scope .carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
.bootstrap-scope .carousel-control-prev {
  left: 0;
}
.bootstrap-scope .carousel-control-next {
  right: 0;
}
.bootstrap-scope .carousel-control-prev-icon,
.bootstrap-scope .carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}
.bootstrap-scope .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}
.bootstrap-scope .carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}
.bootstrap-scope .carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.bootstrap-scope .carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .bootstrap-scope .carousel-indicators li {
    transition: none;
  }
}
.bootstrap-scope .carousel-indicators .active {
  opacity: 1;
}
.bootstrap-scope .carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.bootstrap-scope .spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}
.bootstrap-scope .spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}
.bootstrap-scope .spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}
.bootstrap-scope .spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}
.bootstrap-scope .align-baseline {
  vertical-align: baseline !important;
}
.bootstrap-scope .align-top {
  vertical-align: top !important;
}
.bootstrap-scope .align-middle {
  vertical-align: middle !important;
}
.bootstrap-scope .align-bottom {
  vertical-align: bottom !important;
}
.bootstrap-scope .align-text-bottom {
  vertical-align: text-bottom !important;
}
.bootstrap-scope .align-text-top {
  vertical-align: text-top !important;
}
.bootstrap-scope .bg-primary {
  background-color: #007bff !important;
}
.bootstrap-scope a.bg-primary:hover, .bootstrap-scope a.bg-primary:focus,
.bootstrap-scope button.bg-primary:hover,
.bootstrap-scope button.bg-primary:focus {
  background-color: #0062cc !important;
}
.bootstrap-scope .bg-secondary {
  background-color: #6c757d !important;
}
.bootstrap-scope a.bg-secondary:hover, .bootstrap-scope a.bg-secondary:focus,
.bootstrap-scope button.bg-secondary:hover,
.bootstrap-scope button.bg-secondary:focus {
  background-color: #545b62 !important;
}
.bootstrap-scope .bg-success {
  background-color: #28a745 !important;
}
.bootstrap-scope a.bg-success:hover, .bootstrap-scope a.bg-success:focus,
.bootstrap-scope button.bg-success:hover,
.bootstrap-scope button.bg-success:focus {
  background-color: #1e7e34 !important;
}
.bootstrap-scope .bg-info {
  background-color: #17a2b8 !important;
}
.bootstrap-scope a.bg-info:hover, .bootstrap-scope a.bg-info:focus,
.bootstrap-scope button.bg-info:hover,
.bootstrap-scope button.bg-info:focus {
  background-color: #117a8b !important;
}
.bootstrap-scope .bg-warning {
  background-color: #ffc107 !important;
}
.bootstrap-scope a.bg-warning:hover, .bootstrap-scope a.bg-warning:focus,
.bootstrap-scope button.bg-warning:hover,
.bootstrap-scope button.bg-warning:focus {
  background-color: #d39e00 !important;
}
.bootstrap-scope .bg-danger {
  background-color: #dc3545 !important;
}
.bootstrap-scope a.bg-danger:hover, .bootstrap-scope a.bg-danger:focus,
.bootstrap-scope button.bg-danger:hover,
.bootstrap-scope button.bg-danger:focus {
  background-color: #bd2130 !important;
}
.bootstrap-scope .bg-light {
  /* background-color: #f8f9fa !important; */
}
.bootstrap-scope a.bg-light:hover, .bootstrap-scope a.bg-light:focus,
.bootstrap-scope button.bg-light:hover,
.bootstrap-scope button.bg-light:focus {
  background-color: #dae0e5 !important;
}
.bootstrap-scope .bg-dark {
  background-color: #343a40 !important;
}
.bootstrap-scope a.bg-dark:hover, .bootstrap-scope a.bg-dark:focus,
.bootstrap-scope button.bg-dark:hover,
.bootstrap-scope button.bg-dark:focus {
  background-color: #1d2124 !important;
}
.bootstrap-scope .bg-white {
  background-color: #fff !important;
}
.bootstrap-scope .bg-transparent {
  background-color: transparent !important;
}
.bootstrap-scope .border {
  border: 1px solid #dee2e6 !important;
}
.bootstrap-scope .border-top {
  border-top: 1px solid #dee2e6 !important;
}
.bootstrap-scope .border-right {
  border-right: 1px solid #dee2e6 !important;
}
.bootstrap-scope .border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.bootstrap-scope .border-left {
  border-left: 1px solid #dee2e6 !important;
}
.bootstrap-scope .border-0 {
  border: 0 !important;
}
.bootstrap-scope .border-top-0 {
  border-top: 0 !important;
}
.bootstrap-scope .border-right-0 {
  border-right: 0 !important;
}
.bootstrap-scope .border-bottom-0 {
  border-bottom: 0 !important;
}
.bootstrap-scope .border-left-0 {
  border-left: 0 !important;
}
.bootstrap-scope .border-primary {
  border-color: #007bff !important;
}
.bootstrap-scope .border-secondary {
  border-color: #6c757d !important;
}
.bootstrap-scope .border-success {
  border-color: #28a745 !important;
}
.bootstrap-scope .border-info {
  border-color: #17a2b8 !important;
}
.bootstrap-scope .border-warning {
  border-color: #ffc107 !important;
}
.bootstrap-scope .border-danger {
  border-color: #dc3545 !important;
}
.bootstrap-scope .border-light {
  border-color: #f8f9fa !important;
}
.bootstrap-scope .border-dark {
  border-color: #343a40 !important;
}
.bootstrap-scope .border-white {
  border-color: #fff !important;
}
.bootstrap-scope .rounded-sm {
  border-radius: 0.2rem !important;
}
.bootstrap-scope .rounded {
  border-radius: 0.25rem !important;
}
.bootstrap-scope .rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.bootstrap-scope .rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.bootstrap-scope .rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.bootstrap-scope .rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.bootstrap-scope .rounded-lg {
  border-radius: 0.3rem !important;
}
.bootstrap-scope .rounded-circle {
  border-radius: 50% !important;
}
.bootstrap-scope .rounded-pill {
  border-radius: 50rem !important;
}
.bootstrap-scope .rounded-0 {
  border-radius: 0 !important;
}
.bootstrap-scope .clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.bootstrap-scope .d-none {
  display: none !important;
}
.bootstrap-scope .d-inline {
  display: inline !important;
}
.bootstrap-scope .d-inline-block {
  display: inline-block !important;
}
.bootstrap-scope .d-block {
  display: block !important;
}
.bootstrap-scope .d-table {
  display: table !important;
}
.bootstrap-scope .d-table-row {
  display: table-row !important;
}
.bootstrap-scope .d-table-cell {
  display: table-cell !important;
}
.bootstrap-scope .d-flex {
  display: flex !important;
}
.bootstrap-scope .d-inline-flex {
  display: inline-flex !important;
}
@media (min-width: 576px) {
  .bootstrap-scope .d-sm-none {
    display: none !important;
  }
  .bootstrap-scope .d-sm-inline {
    display: inline !important;
  }
  .bootstrap-scope .d-sm-inline-block {
    display: inline-block !important;
  }
  .bootstrap-scope .d-sm-block {
    display: block !important;
  }
  .bootstrap-scope .d-sm-table {
    display: table !important;
  }
  .bootstrap-scope .d-sm-table-row {
    display: table-row !important;
  }
  .bootstrap-scope .d-sm-table-cell {
    display: table-cell !important;
  }
  .bootstrap-scope .d-sm-flex {
    display: flex !important;
  }
  .bootstrap-scope .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .bootstrap-scope .d-md-none {
    display: none !important;
  }
  .bootstrap-scope .d-md-inline {
    display: inline !important;
  }
  .bootstrap-scope .d-md-inline-block {
    display: inline-block !important;
  }
  .bootstrap-scope .d-md-block {
    display: block !important;
  }
  .bootstrap-scope .d-md-table {
    display: table !important;
  }
  .bootstrap-scope .d-md-table-row {
    display: table-row !important;
  }
  .bootstrap-scope .d-md-table-cell {
    display: table-cell !important;
  }
  .bootstrap-scope .d-md-flex {
    display: flex !important;
  }
  .bootstrap-scope .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .bootstrap-scope .d-lg-none {
    display: none !important;
  }
  .bootstrap-scope .d-lg-inline {
    display: inline !important;
  }
  .bootstrap-scope .d-lg-inline-block {
    display: inline-block !important;
  }
  .bootstrap-scope .d-lg-block {
    display: block !important;
  }
  .bootstrap-scope .d-lg-table {
    display: table !important;
  }
  .bootstrap-scope .d-lg-table-row {
    display: table-row !important;
  }
  .bootstrap-scope .d-lg-table-cell {
    display: table-cell !important;
  }
  .bootstrap-scope .d-lg-flex {
    display: flex !important;
  }
  .bootstrap-scope .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .bootstrap-scope .d-xl-none {
    display: none !important;
  }
  .bootstrap-scope .d-xl-inline {
    display: inline !important;
  }
  .bootstrap-scope .d-xl-inline-block {
    display: inline-block !important;
  }
  .bootstrap-scope .d-xl-block {
    display: block !important;
  }
  .bootstrap-scope .d-xl-table {
    display: table !important;
  }
  .bootstrap-scope .d-xl-table-row {
    display: table-row !important;
  }
  .bootstrap-scope .d-xl-table-cell {
    display: table-cell !important;
  }
  .bootstrap-scope .d-xl-flex {
    display: flex !important;
  }
  .bootstrap-scope .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .bootstrap-scope .d-print-none {
    display: none !important;
  }
  .bootstrap-scope .d-print-inline {
    display: inline !important;
  }
  .bootstrap-scope .d-print-inline-block {
    display: inline-block !important;
  }
  .bootstrap-scope .d-print-block {
    display: block !important;
  }
  .bootstrap-scope .d-print-table {
    display: table !important;
  }
  .bootstrap-scope .d-print-table-row {
    display: table-row !important;
  }
  .bootstrap-scope .d-print-table-cell {
    display: table-cell !important;
  }
  .bootstrap-scope .d-print-flex {
    display: flex !important;
  }
  .bootstrap-scope .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.bootstrap-scope .embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.bootstrap-scope .embed-responsive::before {
  display: block;
  content: "";
}
.bootstrap-scope .embed-responsive .embed-responsive-item,
.bootstrap-scope .embed-responsive iframe,
.bootstrap-scope .embed-responsive embed,
.bootstrap-scope .embed-responsive object,
.bootstrap-scope .embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.bootstrap-scope .embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}
.bootstrap-scope .embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.bootstrap-scope .embed-responsive-4by3::before {
  padding-top: 75%;
}
.bootstrap-scope .embed-responsive-1by1::before {
  padding-top: 100%;
}
.bootstrap-scope .flex-row {
  flex-direction: row !important;
}
.bootstrap-scope .flex-column {
  flex-direction: column !important;
}
.bootstrap-scope .flex-row-reverse {
  flex-direction: row-reverse !important;
}
.bootstrap-scope .flex-column-reverse {
  flex-direction: column-reverse !important;
}
.bootstrap-scope .flex-wrap {
  flex-wrap: wrap !important;
}
.bootstrap-scope .flex-nowrap {
  flex-wrap: nowrap !important;
}
.bootstrap-scope .flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.bootstrap-scope .flex-fill {
  flex: 1 1 auto !important;
}
.bootstrap-scope .flex-grow-0 {
  flex-grow: 0 !important;
}
.bootstrap-scope .flex-grow-1 {
  flex-grow: 1 !important;
}
.bootstrap-scope .flex-shrink-0 {
  flex-shrink: 0 !important;
}
.bootstrap-scope .flex-shrink-1 {
  flex-shrink: 1 !important;
}
.bootstrap-scope .justify-content-start {
  justify-content: flex-start !important;
}
.bootstrap-scope .justify-content-end {
  justify-content: flex-end !important;
}
.bootstrap-scope .justify-content-center {
  justify-content: center !important;
}
.bootstrap-scope .justify-content-between {
  justify-content: space-between !important;
}
.bootstrap-scope .justify-content-around {
  justify-content: space-around !important;
}
.bootstrap-scope .align-items-start {
  align-items: flex-start !important;
}
.bootstrap-scope .align-items-end {
  align-items: flex-end !important;
}
.bootstrap-scope .align-items-center {
  align-items: center !important;
}
.bootstrap-scope .align-items-baseline {
  align-items: baseline !important;
}
.bootstrap-scope .align-items-stretch {
  align-items: stretch !important;
}
.bootstrap-scope .align-content-start {
  align-content: flex-start !important;
}
.bootstrap-scope .align-content-end {
  align-content: flex-end !important;
}
.bootstrap-scope .align-content-center {
  align-content: center !important;
}
.bootstrap-scope .align-content-between {
  align-content: space-between !important;
}
.bootstrap-scope .align-content-around {
  align-content: space-around !important;
}
.bootstrap-scope .align-content-stretch {
  align-content: stretch !important;
}
.bootstrap-scope .align-self-auto {
  align-self: auto !important;
}
.bootstrap-scope .align-self-start {
  align-self: flex-start !important;
}
.bootstrap-scope .align-self-end {
  align-self: flex-end !important;
}
.bootstrap-scope .align-self-center {
  align-self: center !important;
}
.bootstrap-scope .align-self-baseline {
  align-self: baseline !important;
}
.bootstrap-scope .align-self-stretch {
  align-self: stretch !important;
}
@media (min-width: 576px) {
  .bootstrap-scope .flex-sm-row {
    flex-direction: row !important;
  }
  .bootstrap-scope .flex-sm-column {
    flex-direction: column !important;
  }
  .bootstrap-scope .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .bootstrap-scope .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .bootstrap-scope .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .bootstrap-scope .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .bootstrap-scope .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .bootstrap-scope .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .bootstrap-scope .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .bootstrap-scope .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .bootstrap-scope .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .bootstrap-scope .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .bootstrap-scope .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .bootstrap-scope .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .bootstrap-scope .justify-content-sm-center {
    justify-content: center !important;
  }
  .bootstrap-scope .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .bootstrap-scope .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .bootstrap-scope .align-items-sm-start {
    align-items: flex-start !important;
  }
  .bootstrap-scope .align-items-sm-end {
    align-items: flex-end !important;
  }
  .bootstrap-scope .align-items-sm-center {
    align-items: center !important;
  }
  .bootstrap-scope .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .bootstrap-scope .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .bootstrap-scope .align-content-sm-start {
    align-content: flex-start !important;
  }
  .bootstrap-scope .align-content-sm-end {
    align-content: flex-end !important;
  }
  .bootstrap-scope .align-content-sm-center {
    align-content: center !important;
  }
  .bootstrap-scope .align-content-sm-between {
    align-content: space-between !important;
  }
  .bootstrap-scope .align-content-sm-around {
    align-content: space-around !important;
  }
  .bootstrap-scope .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .bootstrap-scope .align-self-sm-auto {
    align-self: auto !important;
  }
  .bootstrap-scope .align-self-sm-start {
    align-self: flex-start !important;
  }
  .bootstrap-scope .align-self-sm-end {
    align-self: flex-end !important;
  }
  .bootstrap-scope .align-self-sm-center {
    align-self: center !important;
  }
  .bootstrap-scope .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .bootstrap-scope .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .bootstrap-scope .flex-md-row {
    flex-direction: row !important;
  }
  .bootstrap-scope .flex-md-column {
    flex-direction: column !important;
  }
  .bootstrap-scope .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .bootstrap-scope .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .bootstrap-scope .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .bootstrap-scope .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .bootstrap-scope .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .bootstrap-scope .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .bootstrap-scope .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .bootstrap-scope .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .bootstrap-scope .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .bootstrap-scope .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .bootstrap-scope .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .bootstrap-scope .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .bootstrap-scope .justify-content-md-center {
    justify-content: center !important;
  }
  .bootstrap-scope .justify-content-md-between {
    justify-content: space-between !important;
  }
  .bootstrap-scope .justify-content-md-around {
    justify-content: space-around !important;
  }
  .bootstrap-scope .align-items-md-start {
    align-items: flex-start !important;
  }
  .bootstrap-scope .align-items-md-end {
    align-items: flex-end !important;
  }
  .bootstrap-scope .align-items-md-center {
    align-items: center !important;
  }
  .bootstrap-scope .align-items-md-baseline {
    align-items: baseline !important;
  }
  .bootstrap-scope .align-items-md-stretch {
    align-items: stretch !important;
  }
  .bootstrap-scope .align-content-md-start {
    align-content: flex-start !important;
  }
  .bootstrap-scope .align-content-md-end {
    align-content: flex-end !important;
  }
  .bootstrap-scope .align-content-md-center {
    align-content: center !important;
  }
  .bootstrap-scope .align-content-md-between {
    align-content: space-between !important;
  }
  .bootstrap-scope .align-content-md-around {
    align-content: space-around !important;
  }
  .bootstrap-scope .align-content-md-stretch {
    align-content: stretch !important;
  }
  .bootstrap-scope .align-self-md-auto {
    align-self: auto !important;
  }
  .bootstrap-scope .align-self-md-start {
    align-self: flex-start !important;
  }
  .bootstrap-scope .align-self-md-end {
    align-self: flex-end !important;
  }
  .bootstrap-scope .align-self-md-center {
    align-self: center !important;
  }
  .bootstrap-scope .align-self-md-baseline {
    align-self: baseline !important;
  }
  .bootstrap-scope .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .bootstrap-scope .flex-lg-row {
    flex-direction: row !important;
  }
  .bootstrap-scope .flex-lg-column {
    flex-direction: column !important;
  }
  .bootstrap-scope .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .bootstrap-scope .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .bootstrap-scope .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .bootstrap-scope .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .bootstrap-scope .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .bootstrap-scope .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .bootstrap-scope .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .bootstrap-scope .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .bootstrap-scope .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .bootstrap-scope .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .bootstrap-scope .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .bootstrap-scope .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .bootstrap-scope .justify-content-lg-center {
    justify-content: center !important;
  }
  .bootstrap-scope .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .bootstrap-scope .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .bootstrap-scope .align-items-lg-start {
    align-items: flex-start !important;
  }
  .bootstrap-scope .align-items-lg-end {
    align-items: flex-end !important;
  }
  .bootstrap-scope .align-items-lg-center {
    align-items: center !important;
  }
  .bootstrap-scope .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .bootstrap-scope .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .bootstrap-scope .align-content-lg-start {
    align-content: flex-start !important;
  }
  .bootstrap-scope .align-content-lg-end {
    align-content: flex-end !important;
  }
  .bootstrap-scope .align-content-lg-center {
    align-content: center !important;
  }
  .bootstrap-scope .align-content-lg-between {
    align-content: space-between !important;
  }
  .bootstrap-scope .align-content-lg-around {
    align-content: space-around !important;
  }
  .bootstrap-scope .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .bootstrap-scope .align-self-lg-auto {
    align-self: auto !important;
  }
  .bootstrap-scope .align-self-lg-start {
    align-self: flex-start !important;
  }
  .bootstrap-scope .align-self-lg-end {
    align-self: flex-end !important;
  }
  .bootstrap-scope .align-self-lg-center {
    align-self: center !important;
  }
  .bootstrap-scope .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .bootstrap-scope .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .bootstrap-scope .flex-xl-row {
    flex-direction: row !important;
  }
  .bootstrap-scope .flex-xl-column {
    flex-direction: column !important;
  }
  .bootstrap-scope .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .bootstrap-scope .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .bootstrap-scope .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .bootstrap-scope .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .bootstrap-scope .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .bootstrap-scope .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .bootstrap-scope .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .bootstrap-scope .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .bootstrap-scope .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .bootstrap-scope .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .bootstrap-scope .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .bootstrap-scope .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .bootstrap-scope .justify-content-xl-center {
    justify-content: center !important;
  }
  .bootstrap-scope .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .bootstrap-scope .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .bootstrap-scope .align-items-xl-start {
    align-items: flex-start !important;
  }
  .bootstrap-scope .align-items-xl-end {
    align-items: flex-end !important;
  }
  .bootstrap-scope .align-items-xl-center {
    align-items: center !important;
  }
  .bootstrap-scope .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .bootstrap-scope .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .bootstrap-scope .align-content-xl-start {
    align-content: flex-start !important;
  }
  .bootstrap-scope .align-content-xl-end {
    align-content: flex-end !important;
  }
  .bootstrap-scope .align-content-xl-center {
    align-content: center !important;
  }
  .bootstrap-scope .align-content-xl-between {
    align-content: space-between !important;
  }
  .bootstrap-scope .align-content-xl-around {
    align-content: space-around !important;
  }
  .bootstrap-scope .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .bootstrap-scope .align-self-xl-auto {
    align-self: auto !important;
  }
  .bootstrap-scope .align-self-xl-start {
    align-self: flex-start !important;
  }
  .bootstrap-scope .align-self-xl-end {
    align-self: flex-end !important;
  }
  .bootstrap-scope .align-self-xl-center {
    align-self: center !important;
  }
  .bootstrap-scope .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .bootstrap-scope .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.bootstrap-scope .float-left {
  float: left !important;
}
.bootstrap-scope .float-right {
  float: right !important;
}
.bootstrap-scope .float-none {
  float: none !important;
}
@media (min-width: 576px) {
  .bootstrap-scope .float-sm-left {
    float: left !important;
  }
  .bootstrap-scope .float-sm-right {
    float: right !important;
  }
  .bootstrap-scope .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .bootstrap-scope .float-md-left {
    float: left !important;
  }
  .bootstrap-scope .float-md-right {
    float: right !important;
  }
  .bootstrap-scope .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .bootstrap-scope .float-lg-left {
    float: left !important;
  }
  .bootstrap-scope .float-lg-right {
    float: right !important;
  }
  .bootstrap-scope .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .bootstrap-scope .float-xl-left {
    float: left !important;
  }
  .bootstrap-scope .float-xl-right {
    float: right !important;
  }
  .bootstrap-scope .float-xl-none {
    float: none !important;
  }
}
.bootstrap-scope .overflow-auto {
  overflow: auto !important;
}
.bootstrap-scope .overflow-hidden {
  overflow: hidden !important;
}
.bootstrap-scope .position-static {
  position: static !important;
}
.bootstrap-scope .position-relative {
  position: relative !important;
}
.bootstrap-scope .position-absolute {
  position: absolute !important;
}
.bootstrap-scope .position-fixed {
  position: fixed !important;
}
.bootstrap-scope .position-sticky {
  position: sticky !important;
}
.bootstrap-scope .fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.bootstrap-scope .fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
@supports (position: sticky) {
  .bootstrap-scope .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.bootstrap-scope .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.bootstrap-scope .sr-only-focusable:active, .bootstrap-scope .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.bootstrap-scope .shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.bootstrap-scope .shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.bootstrap-scope .shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.bootstrap-scope .shadow-none {
  box-shadow: none !important;
}
.bootstrap-scope .w-25 {
  width: 25% !important;
}
.bootstrap-scope .w-50 {
  width: 50% !important;
}
.bootstrap-scope .w-75 {
  width: 75% !important;
}
.bootstrap-scope .w-100 {
  width: 100% !important;
}
.bootstrap-scope .w-auto {
  width: auto !important;
}
.bootstrap-scope .h-25 {
  height: 25% !important;
}
.bootstrap-scope .h-50 {
  height: 50% !important;
}
.bootstrap-scope .h-75 {
  height: 75% !important;
}
.bootstrap-scope .h-100 {
  height: 100% !important;
}
.bootstrap-scope .h-auto {
  height: auto !important;
}
.bootstrap-scope .mw-100 {
  max-width: 100% !important;
}
.bootstrap-scope .mh-100 {
  max-height: 100% !important;
}
.bootstrap-scope .min-vw-100 {
  min-width: 100vw !important;
}
.bootstrap-scope .min-vh-100 {
  min-height: 100vh !important;
}
.bootstrap-scope .vw-100 {
  width: 100vw !important;
}
.bootstrap-scope .vh-100 {
  height: 100vh !important;
}
.bootstrap-scope .stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}
.bootstrap-scope .m-0 {
  margin: 0 !important;
}
.bootstrap-scope .mt-0,
.bootstrap-scope .my-0 {
  margin-top: 0 !important;
}
.bootstrap-scope .mr-0,
.bootstrap-scope .mx-0 {
  margin-right: 0 !important;
}
.bootstrap-scope .mb-0,
.bootstrap-scope .my-0 {
  margin-bottom: 0 !important;
}
.bootstrap-scope .ml-0,
.bootstrap-scope .mx-0 {
  margin-left: 0 !important;
}
.bootstrap-scope .m-1 {
  margin: 0.25rem !important;
}
.bootstrap-scope .mt-1,
.bootstrap-scope .my-1 {
  margin-top: 0.25rem !important;
}
.bootstrap-scope .mr-1,
.bootstrap-scope .mx-1 {
  margin-right: 0.25rem !important;
}
.bootstrap-scope .mb-1,
.bootstrap-scope .my-1 {
  margin-bottom: 0.25rem !important;
}
.bootstrap-scope .ml-1,
.bootstrap-scope .mx-1 {
  margin-left: 0.25rem !important;
}
.bootstrap-scope .m-2 {
  margin: 0.5rem !important;
}
.bootstrap-scope .mt-2,
.bootstrap-scope .my-2 {
  margin-top: 0.5rem !important;
}
.bootstrap-scope .mr-2,
.bootstrap-scope .mx-2 {
  margin-right: 0.5rem !important;
}
.bootstrap-scope .mb-2,
.bootstrap-scope .my-2 {
  margin-bottom: 0.5rem !important;
}
.bootstrap-scope .ml-2,
.bootstrap-scope .mx-2 {
  margin-left: 0.5rem !important;
}
.bootstrap-scope .m-3 {
  margin: 1rem !important;
}
.bootstrap-scope .mt-3,
.bootstrap-scope .my-3 {
  margin-top: 1rem !important;
}
.bootstrap-scope .mr-3,
.bootstrap-scope .mx-3 {
  margin-right: 1rem !important;
}
.bootstrap-scope .mb-3,
.bootstrap-scope .my-3 {
  margin-bottom: 1rem !important;
}
.bootstrap-scope .ml-3,
.bootstrap-scope .mx-3 {
  margin-left: 1rem !important;
}
.bootstrap-scope .m-4 {
  margin: 1.5rem !important;
}
.bootstrap-scope .mt-4,
.bootstrap-scope .my-4 {
  margin-top: 1.5rem !important;
}
.bootstrap-scope .mr-4,
.bootstrap-scope .mx-4 {
  margin-right: 1.5rem !important;
}
.bootstrap-scope .mb-4,
.bootstrap-scope .my-4 {
  margin-bottom: 1.5rem !important;
}
.bootstrap-scope .ml-4,
.bootstrap-scope .mx-4 {
  margin-left: 1.5rem !important;
}
.bootstrap-scope .m-5 {
  margin: 3rem !important;
}
.bootstrap-scope .mt-5,
.bootstrap-scope .my-5 {
  margin-top: 3rem !important;
}
.bootstrap-scope .mr-5,
.bootstrap-scope .mx-5 {
  margin-right: 3rem !important;
}
.bootstrap-scope .mb-5,
.bootstrap-scope .my-5 {
  margin-bottom: 3rem !important;
}
.bootstrap-scope .ml-5,
.bootstrap-scope .mx-5 {
  margin-left: 3rem !important;
}
.bootstrap-scope .p-0 {
  padding: 0 !important;
}
.bootstrap-scope .pt-0,
.bootstrap-scope .py-0 {
  padding-top: 0 !important;
}
.bootstrap-scope .pr-0,
.bootstrap-scope .px-0 {
  padding-right: 0 !important;
}
.bootstrap-scope .pb-0,
.bootstrap-scope .py-0 {
  padding-bottom: 0 !important;
}
.bootstrap-scope .pl-0,
.bootstrap-scope .px-0 {
  padding-left: 0 !important;
}
.bootstrap-scope .p-1 {
  padding: 0.25rem !important;
}
.bootstrap-scope .pt-1,
.bootstrap-scope .py-1 {
  padding-top: 0.25rem !important;
}
.bootstrap-scope .pr-1,
.bootstrap-scope .px-1 {
  padding-right: 0.25rem !important;
}
.bootstrap-scope .pb-1,
.bootstrap-scope .py-1 {
  padding-bottom: 0.25rem !important;
}
.bootstrap-scope .pl-1,
.bootstrap-scope .px-1 {
  padding-left: 0.25rem !important;
}
.bootstrap-scope .p-2 {
  padding: 0.5rem !important;
}
.bootstrap-scope .pt-2,
.bootstrap-scope .py-2 {
  padding-top: 0.5rem !important;
}
.bootstrap-scope .pr-2,
.bootstrap-scope .px-2 {
  padding-right: 0.5rem !important;
}
.bootstrap-scope .pb-2,
.bootstrap-scope .py-2 {
  padding-bottom: 0.5rem !important;
}
.bootstrap-scope .pl-2,
.bootstrap-scope .px-2 {
  padding-left: 0.5rem !important;
}
.bootstrap-scope .p-3 {
  padding: 1rem !important;
}
.bootstrap-scope .pt-3,
.bootstrap-scope .py-3 {
  padding-top: 1rem !important;
}
.bootstrap-scope .pr-3,
.bootstrap-scope .px-3 {
  padding-right: 1rem !important;
}
.bootstrap-scope .pb-3,
.bootstrap-scope .py-3 {
  padding-bottom: 1rem !important;
}
.bootstrap-scope .pl-3,
.bootstrap-scope .px-3 {
  padding-left: 1rem !important;
}
.bootstrap-scope .p-4 {
  padding: 1.5rem !important;
}
.bootstrap-scope .pt-4,
.bootstrap-scope .py-4 {
  padding-top: 1.5rem !important;
}
.bootstrap-scope .pr-4,
.bootstrap-scope .px-4 {
  padding-right: 1.5rem !important;
}
.bootstrap-scope .pb-4,
.bootstrap-scope .py-4 {
  padding-bottom: 1.5rem !important;
}
.bootstrap-scope .pl-4,
.bootstrap-scope .px-4 {
  padding-left: 1.5rem !important;
}
.bootstrap-scope .p-5 {
  padding: 3rem !important;
}
.bootstrap-scope .pt-5,
.bootstrap-scope .py-5 {
  padding-top: 3rem !important;
}
.bootstrap-scope .pr-5,
.bootstrap-scope .px-5 {
  padding-right: 3rem !important;
}
.bootstrap-scope .pb-5,
.bootstrap-scope .py-5 {
  padding-bottom: 3rem !important;
}
.bootstrap-scope .pl-5,
.bootstrap-scope .px-5 {
  padding-left: 3rem !important;
}
.bootstrap-scope .m-n1 {
  margin: -0.25rem !important;
}
.bootstrap-scope .mt-n1,
.bootstrap-scope .my-n1 {
  margin-top: -0.25rem !important;
}
.bootstrap-scope .mr-n1,
.bootstrap-scope .mx-n1 {
  margin-right: -0.25rem !important;
}
.bootstrap-scope .mb-n1,
.bootstrap-scope .my-n1 {
  margin-bottom: -0.25rem !important;
}
.bootstrap-scope .ml-n1,
.bootstrap-scope .mx-n1 {
  margin-left: -0.25rem !important;
}
.bootstrap-scope .m-n2 {
  margin: -0.5rem !important;
}
.bootstrap-scope .mt-n2,
.bootstrap-scope .my-n2 {
  margin-top: -0.5rem !important;
}
.bootstrap-scope .mr-n2,
.bootstrap-scope .mx-n2 {
  margin-right: -0.5rem !important;
}
.bootstrap-scope .mb-n2,
.bootstrap-scope .my-n2 {
  margin-bottom: -0.5rem !important;
}
.bootstrap-scope .ml-n2,
.bootstrap-scope .mx-n2 {
  margin-left: -0.5rem !important;
}
.bootstrap-scope .m-n3 {
  margin: -1rem !important;
}
.bootstrap-scope .mt-n3,
.bootstrap-scope .my-n3 {
  margin-top: -1rem !important;
}
.bootstrap-scope .mr-n3,
.bootstrap-scope .mx-n3 {
  margin-right: -1rem !important;
}
.bootstrap-scope .mb-n3,
.bootstrap-scope .my-n3 {
  margin-bottom: -1rem !important;
}
.bootstrap-scope .ml-n3,
.bootstrap-scope .mx-n3 {
  margin-left: -1rem !important;
}
.bootstrap-scope .m-n4 {
  margin: -1.5rem !important;
}
.bootstrap-scope .mt-n4,
.bootstrap-scope .my-n4 {
  margin-top: -1.5rem !important;
}
.bootstrap-scope .mr-n4,
.bootstrap-scope .mx-n4 {
  margin-right: -1.5rem !important;
}
.bootstrap-scope .mb-n4,
.bootstrap-scope .my-n4 {
  margin-bottom: -1.5rem !important;
}
.bootstrap-scope .ml-n4,
.bootstrap-scope .mx-n4 {
  margin-left: -1.5rem !important;
}
.bootstrap-scope .m-n5 {
  margin: -3rem !important;
}
.bootstrap-scope .mt-n5,
.bootstrap-scope .my-n5 {
  margin-top: -3rem !important;
}
.bootstrap-scope .mr-n5,
.bootstrap-scope .mx-n5 {
  margin-right: -3rem !important;
}
.bootstrap-scope .mb-n5,
.bootstrap-scope .my-n5 {
  margin-bottom: -3rem !important;
}
.bootstrap-scope .ml-n5,
.bootstrap-scope .mx-n5 {
  margin-left: -3rem !important;
}
.bootstrap-scope .m-auto {
  margin: auto !important;
}
.bootstrap-scope .mt-auto,
.bootstrap-scope .my-auto {
  margin-top: auto !important;
}
.bootstrap-scope .mr-auto,
.bootstrap-scope .mx-auto {
  margin-right: auto !important;
}
.bootstrap-scope .mb-auto,
.bootstrap-scope .my-auto {
  margin-bottom: auto !important;
}
.bootstrap-scope .ml-auto,
.bootstrap-scope .mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .bootstrap-scope .m-sm-0 {
    margin: 0 !important;
  }
  .bootstrap-scope .mt-sm-0,
.bootstrap-scope .my-sm-0 {
    margin-top: 0 !important;
  }
  .bootstrap-scope .mr-sm-0,
.bootstrap-scope .mx-sm-0 {
    margin-right: 0 !important;
  }
  .bootstrap-scope .mb-sm-0,
.bootstrap-scope .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .bootstrap-scope .ml-sm-0,
.bootstrap-scope .mx-sm-0 {
    margin-left: 0 !important;
  }
  .bootstrap-scope .m-sm-1 {
    margin: 0.25rem !important;
  }
  .bootstrap-scope .mt-sm-1,
.bootstrap-scope .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .bootstrap-scope .mr-sm-1,
.bootstrap-scope .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .bootstrap-scope .mb-sm-1,
.bootstrap-scope .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .bootstrap-scope .ml-sm-1,
.bootstrap-scope .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .bootstrap-scope .m-sm-2 {
    margin: 0.5rem !important;
  }
  .bootstrap-scope .mt-sm-2,
.bootstrap-scope .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .bootstrap-scope .mr-sm-2,
.bootstrap-scope .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .bootstrap-scope .mb-sm-2,
.bootstrap-scope .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .bootstrap-scope .ml-sm-2,
.bootstrap-scope .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .bootstrap-scope .m-sm-3 {
    margin: 1rem !important;
  }
  .bootstrap-scope .mt-sm-3,
.bootstrap-scope .my-sm-3 {
    margin-top: 1rem !important;
  }
  .bootstrap-scope .mr-sm-3,
.bootstrap-scope .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .bootstrap-scope .mb-sm-3,
.bootstrap-scope .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .bootstrap-scope .ml-sm-3,
.bootstrap-scope .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .bootstrap-scope .m-sm-4 {
    margin: 1.5rem !important;
  }
  .bootstrap-scope .mt-sm-4,
.bootstrap-scope .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .bootstrap-scope .mr-sm-4,
.bootstrap-scope .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .bootstrap-scope .mb-sm-4,
.bootstrap-scope .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .bootstrap-scope .ml-sm-4,
.bootstrap-scope .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .bootstrap-scope .m-sm-5 {
    margin: 3rem !important;
  }
  .bootstrap-scope .mt-sm-5,
.bootstrap-scope .my-sm-5 {
    margin-top: 3rem !important;
  }
  .bootstrap-scope .mr-sm-5,
.bootstrap-scope .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .bootstrap-scope .mb-sm-5,
.bootstrap-scope .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .bootstrap-scope .ml-sm-5,
.bootstrap-scope .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .bootstrap-scope .p-sm-0 {
    padding: 0 !important;
  }
  .bootstrap-scope .pt-sm-0,
.bootstrap-scope .py-sm-0 {
    padding-top: 0 !important;
  }
  .bootstrap-scope .pr-sm-0,
.bootstrap-scope .px-sm-0 {
    padding-right: 0 !important;
  }
  .bootstrap-scope .pb-sm-0,
.bootstrap-scope .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .bootstrap-scope .pl-sm-0,
.bootstrap-scope .px-sm-0 {
    padding-left: 0 !important;
  }
  .bootstrap-scope .p-sm-1 {
    padding: 0.25rem !important;
  }
  .bootstrap-scope .pt-sm-1,
.bootstrap-scope .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .bootstrap-scope .pr-sm-1,
.bootstrap-scope .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .bootstrap-scope .pb-sm-1,
.bootstrap-scope .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .bootstrap-scope .pl-sm-1,
.bootstrap-scope .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .bootstrap-scope .p-sm-2 {
    padding: 0.5rem !important;
  }
  .bootstrap-scope .pt-sm-2,
.bootstrap-scope .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .bootstrap-scope .pr-sm-2,
.bootstrap-scope .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .bootstrap-scope .pb-sm-2,
.bootstrap-scope .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .bootstrap-scope .pl-sm-2,
.bootstrap-scope .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .bootstrap-scope .p-sm-3 {
    padding: 1rem !important;
  }
  .bootstrap-scope .pt-sm-3,
.bootstrap-scope .py-sm-3 {
    padding-top: 1rem !important;
  }
  .bootstrap-scope .pr-sm-3,
.bootstrap-scope .px-sm-3 {
    padding-right: 1rem !important;
  }
  .bootstrap-scope .pb-sm-3,
.bootstrap-scope .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .bootstrap-scope .pl-sm-3,
.bootstrap-scope .px-sm-3 {
    padding-left: 1rem !important;
  }
  .bootstrap-scope .p-sm-4 {
    padding: 1.5rem !important;
  }
  .bootstrap-scope .pt-sm-4,
.bootstrap-scope .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .bootstrap-scope .pr-sm-4,
.bootstrap-scope .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .bootstrap-scope .pb-sm-4,
.bootstrap-scope .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .bootstrap-scope .pl-sm-4,
.bootstrap-scope .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .bootstrap-scope .p-sm-5 {
    padding: 3rem !important;
  }
  .bootstrap-scope .pt-sm-5,
.bootstrap-scope .py-sm-5 {
    padding-top: 3rem !important;
  }
  .bootstrap-scope .pr-sm-5,
.bootstrap-scope .px-sm-5 {
    padding-right: 3rem !important;
  }
  .bootstrap-scope .pb-sm-5,
.bootstrap-scope .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .bootstrap-scope .pl-sm-5,
.bootstrap-scope .px-sm-5 {
    padding-left: 3rem !important;
  }
  .bootstrap-scope .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .bootstrap-scope .mt-sm-n1,
.bootstrap-scope .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .bootstrap-scope .mr-sm-n1,
.bootstrap-scope .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .bootstrap-scope .mb-sm-n1,
.bootstrap-scope .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .bootstrap-scope .ml-sm-n1,
.bootstrap-scope .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .bootstrap-scope .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .bootstrap-scope .mt-sm-n2,
.bootstrap-scope .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .bootstrap-scope .mr-sm-n2,
.bootstrap-scope .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .bootstrap-scope .mb-sm-n2,
.bootstrap-scope .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .bootstrap-scope .ml-sm-n2,
.bootstrap-scope .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .bootstrap-scope .m-sm-n3 {
    margin: -1rem !important;
  }
  .bootstrap-scope .mt-sm-n3,
.bootstrap-scope .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .bootstrap-scope .mr-sm-n3,
.bootstrap-scope .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .bootstrap-scope .mb-sm-n3,
.bootstrap-scope .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .bootstrap-scope .ml-sm-n3,
.bootstrap-scope .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .bootstrap-scope .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .bootstrap-scope .mt-sm-n4,
.bootstrap-scope .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .bootstrap-scope .mr-sm-n4,
.bootstrap-scope .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .bootstrap-scope .mb-sm-n4,
.bootstrap-scope .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .bootstrap-scope .ml-sm-n4,
.bootstrap-scope .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .bootstrap-scope .m-sm-n5 {
    margin: -3rem !important;
  }
  .bootstrap-scope .mt-sm-n5,
.bootstrap-scope .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .bootstrap-scope .mr-sm-n5,
.bootstrap-scope .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .bootstrap-scope .mb-sm-n5,
.bootstrap-scope .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .bootstrap-scope .ml-sm-n5,
.bootstrap-scope .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .bootstrap-scope .m-sm-auto {
    margin: auto !important;
  }
  .bootstrap-scope .mt-sm-auto,
.bootstrap-scope .my-sm-auto {
    margin-top: auto !important;
  }
  .bootstrap-scope .mr-sm-auto,
.bootstrap-scope .mx-sm-auto {
    margin-right: auto !important;
  }
  .bootstrap-scope .mb-sm-auto,
.bootstrap-scope .my-sm-auto {
    margin-bottom: auto !important;
  }
  .bootstrap-scope .ml-sm-auto,
.bootstrap-scope .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .bootstrap-scope .m-md-0 {
    margin: 0 !important;
  }
  .bootstrap-scope .mt-md-0,
.bootstrap-scope .my-md-0 {
    margin-top: 0 !important;
  }
  .bootstrap-scope .mr-md-0,
.bootstrap-scope .mx-md-0 {
    margin-right: 0 !important;
  }
  .bootstrap-scope .mb-md-0,
.bootstrap-scope .my-md-0 {
    margin-bottom: 0 !important;
  }
  .bootstrap-scope .ml-md-0,
.bootstrap-scope .mx-md-0 {
    margin-left: 0 !important;
  }
  .bootstrap-scope .m-md-1 {
    margin: 0.25rem !important;
  }
  .bootstrap-scope .mt-md-1,
.bootstrap-scope .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .bootstrap-scope .mr-md-1,
.bootstrap-scope .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .bootstrap-scope .mb-md-1,
.bootstrap-scope .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .bootstrap-scope .ml-md-1,
.bootstrap-scope .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .bootstrap-scope .m-md-2 {
    margin: 0.5rem !important;
  }
  .bootstrap-scope .mt-md-2,
.bootstrap-scope .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .bootstrap-scope .mr-md-2,
.bootstrap-scope .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .bootstrap-scope .mb-md-2,
.bootstrap-scope .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .bootstrap-scope .ml-md-2,
.bootstrap-scope .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .bootstrap-scope .m-md-3 {
    margin: 1rem !important;
  }
  .bootstrap-scope .mt-md-3,
.bootstrap-scope .my-md-3 {
    margin-top: 1rem !important;
  }
  .bootstrap-scope .mr-md-3,
.bootstrap-scope .mx-md-3 {
    margin-right: 1rem !important;
  }
  .bootstrap-scope .mb-md-3,
.bootstrap-scope .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .bootstrap-scope .ml-md-3,
.bootstrap-scope .mx-md-3 {
    margin-left: 1rem !important;
  }
  .bootstrap-scope .m-md-4 {
    margin: 1.5rem !important;
  }
  .bootstrap-scope .mt-md-4,
.bootstrap-scope .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .bootstrap-scope .mr-md-4,
.bootstrap-scope .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .bootstrap-scope .mb-md-4,
.bootstrap-scope .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .bootstrap-scope .ml-md-4,
.bootstrap-scope .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .bootstrap-scope .m-md-5 {
    margin: 3rem !important;
  }
  .bootstrap-scope .mt-md-5,
.bootstrap-scope .my-md-5 {
    margin-top: 3rem !important;
  }
  .bootstrap-scope .mr-md-5,
.bootstrap-scope .mx-md-5 {
    margin-right: 3rem !important;
  }
  .bootstrap-scope .mb-md-5,
.bootstrap-scope .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .bootstrap-scope .ml-md-5,
.bootstrap-scope .mx-md-5 {
    margin-left: 3rem !important;
  }
  .bootstrap-scope .p-md-0 {
    padding: 0 !important;
  }
  .bootstrap-scope .pt-md-0,
.bootstrap-scope .py-md-0 {
    padding-top: 0 !important;
  }
  .bootstrap-scope .pr-md-0,
.bootstrap-scope .px-md-0 {
    padding-right: 0 !important;
  }
  .bootstrap-scope .pb-md-0,
.bootstrap-scope .py-md-0 {
    padding-bottom: 0 !important;
  }
  .bootstrap-scope .pl-md-0,
.bootstrap-scope .px-md-0 {
    padding-left: 0 !important;
  }
  .bootstrap-scope .p-md-1 {
    padding: 0.25rem !important;
  }
  .bootstrap-scope .pt-md-1,
.bootstrap-scope .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .bootstrap-scope .pr-md-1,
.bootstrap-scope .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .bootstrap-scope .pb-md-1,
.bootstrap-scope .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .bootstrap-scope .pl-md-1,
.bootstrap-scope .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .bootstrap-scope .p-md-2 {
    padding: 0.5rem !important;
  }
  .bootstrap-scope .pt-md-2,
.bootstrap-scope .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .bootstrap-scope .pr-md-2,
.bootstrap-scope .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .bootstrap-scope .pb-md-2,
.bootstrap-scope .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .bootstrap-scope .pl-md-2,
.bootstrap-scope .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .bootstrap-scope .p-md-3 {
    padding: 1rem !important;
  }
  .bootstrap-scope .pt-md-3,
.bootstrap-scope .py-md-3 {
    padding-top: 1rem !important;
  }
  .bootstrap-scope .pr-md-3,
.bootstrap-scope .px-md-3 {
    padding-right: 1rem !important;
  }
  .bootstrap-scope .pb-md-3,
.bootstrap-scope .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .bootstrap-scope .pl-md-3,
.bootstrap-scope .px-md-3 {
    padding-left: 1rem !important;
  }
  .bootstrap-scope .p-md-4 {
    padding: 1.5rem !important;
  }
  .bootstrap-scope .pt-md-4,
.bootstrap-scope .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .bootstrap-scope .pr-md-4,
.bootstrap-scope .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .bootstrap-scope .pb-md-4,
.bootstrap-scope .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .bootstrap-scope .pl-md-4,
.bootstrap-scope .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .bootstrap-scope .p-md-5 {
    padding: 3rem !important;
  }
  .bootstrap-scope .pt-md-5,
.bootstrap-scope .py-md-5 {
    padding-top: 3rem !important;
  }
  .bootstrap-scope .pr-md-5,
.bootstrap-scope .px-md-5 {
    padding-right: 3rem !important;
  }
  .bootstrap-scope .pb-md-5,
.bootstrap-scope .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .bootstrap-scope .pl-md-5,
.bootstrap-scope .px-md-5 {
    padding-left: 3rem !important;
  }
  .bootstrap-scope .m-md-n1 {
    margin: -0.25rem !important;
  }
  .bootstrap-scope .mt-md-n1,
.bootstrap-scope .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .bootstrap-scope .mr-md-n1,
.bootstrap-scope .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .bootstrap-scope .mb-md-n1,
.bootstrap-scope .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .bootstrap-scope .ml-md-n1,
.bootstrap-scope .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .bootstrap-scope .m-md-n2 {
    margin: -0.5rem !important;
  }
  .bootstrap-scope .mt-md-n2,
.bootstrap-scope .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .bootstrap-scope .mr-md-n2,
.bootstrap-scope .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .bootstrap-scope .mb-md-n2,
.bootstrap-scope .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .bootstrap-scope .ml-md-n2,
.bootstrap-scope .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .bootstrap-scope .m-md-n3 {
    margin: -1rem !important;
  }
  .bootstrap-scope .mt-md-n3,
.bootstrap-scope .my-md-n3 {
    margin-top: -1rem !important;
  }
  .bootstrap-scope .mr-md-n3,
.bootstrap-scope .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .bootstrap-scope .mb-md-n3,
.bootstrap-scope .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .bootstrap-scope .ml-md-n3,
.bootstrap-scope .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .bootstrap-scope .m-md-n4 {
    margin: -1.5rem !important;
  }
  .bootstrap-scope .mt-md-n4,
.bootstrap-scope .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .bootstrap-scope .mr-md-n4,
.bootstrap-scope .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .bootstrap-scope .mb-md-n4,
.bootstrap-scope .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .bootstrap-scope .ml-md-n4,
.bootstrap-scope .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .bootstrap-scope .m-md-n5 {
    margin: -3rem !important;
  }
  .bootstrap-scope .mt-md-n5,
.bootstrap-scope .my-md-n5 {
    margin-top: -3rem !important;
  }
  .bootstrap-scope .mr-md-n5,
.bootstrap-scope .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .bootstrap-scope .mb-md-n5,
.bootstrap-scope .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .bootstrap-scope .ml-md-n5,
.bootstrap-scope .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .bootstrap-scope .m-md-auto {
    margin: auto !important;
  }
  .bootstrap-scope .mt-md-auto,
.bootstrap-scope .my-md-auto {
    margin-top: auto !important;
  }
  .bootstrap-scope .mr-md-auto,
.bootstrap-scope .mx-md-auto {
    margin-right: auto !important;
  }
  .bootstrap-scope .mb-md-auto,
.bootstrap-scope .my-md-auto {
    margin-bottom: auto !important;
  }
  .bootstrap-scope .ml-md-auto,
.bootstrap-scope .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .bootstrap-scope .m-lg-0 {
    margin: 0 !important;
  }
  .bootstrap-scope .mt-lg-0,
.bootstrap-scope .my-lg-0 {
    margin-top: 0 !important;
  }
  .bootstrap-scope .mr-lg-0,
.bootstrap-scope .mx-lg-0 {
    margin-right: 0 !important;
  }
  .bootstrap-scope .mb-lg-0,
.bootstrap-scope .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .bootstrap-scope .ml-lg-0,
.bootstrap-scope .mx-lg-0 {
    margin-left: 0 !important;
  }
  .bootstrap-scope .m-lg-1 {
    margin: 0.25rem !important;
  }
  .bootstrap-scope .mt-lg-1,
.bootstrap-scope .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .bootstrap-scope .mr-lg-1,
.bootstrap-scope .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .bootstrap-scope .mb-lg-1,
.bootstrap-scope .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .bootstrap-scope .ml-lg-1,
.bootstrap-scope .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .bootstrap-scope .m-lg-2 {
    margin: 0.5rem !important;
  }
  .bootstrap-scope .mt-lg-2,
.bootstrap-scope .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .bootstrap-scope .mr-lg-2,
.bootstrap-scope .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .bootstrap-scope .mb-lg-2,
.bootstrap-scope .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .bootstrap-scope .ml-lg-2,
.bootstrap-scope .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .bootstrap-scope .m-lg-3 {
    margin: 1rem !important;
  }
  .bootstrap-scope .mt-lg-3,
.bootstrap-scope .my-lg-3 {
    margin-top: 1rem !important;
  }
  .bootstrap-scope .mr-lg-3,
.bootstrap-scope .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .bootstrap-scope .mb-lg-3,
.bootstrap-scope .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .bootstrap-scope .ml-lg-3,
.bootstrap-scope .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .bootstrap-scope .m-lg-4 {
    margin: 1.5rem !important;
  }
  .bootstrap-scope .mt-lg-4,
.bootstrap-scope .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .bootstrap-scope .mr-lg-4,
.bootstrap-scope .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .bootstrap-scope .mb-lg-4,
.bootstrap-scope .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .bootstrap-scope .ml-lg-4,
.bootstrap-scope .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .bootstrap-scope .m-lg-5 {
    margin: 3rem !important;
  }
  .bootstrap-scope .mt-lg-5,
.bootstrap-scope .my-lg-5 {
    margin-top: 3rem !important;
  }
  .bootstrap-scope .mr-lg-5,
.bootstrap-scope .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .bootstrap-scope .mb-lg-5,
.bootstrap-scope .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .bootstrap-scope .ml-lg-5,
.bootstrap-scope .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .bootstrap-scope .p-lg-0 {
    padding: 0 !important;
  }
  .bootstrap-scope .pt-lg-0,
.bootstrap-scope .py-lg-0 {
    padding-top: 0 !important;
  }
  .bootstrap-scope .pr-lg-0,
.bootstrap-scope .px-lg-0 {
    padding-right: 0 !important;
  }
  .bootstrap-scope .pb-lg-0,
.bootstrap-scope .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .bootstrap-scope .pl-lg-0,
.bootstrap-scope .px-lg-0 {
    padding-left: 0 !important;
  }
  .bootstrap-scope .p-lg-1 {
    padding: 0.25rem !important;
  }
  .bootstrap-scope .pt-lg-1,
.bootstrap-scope .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .bootstrap-scope .pr-lg-1,
.bootstrap-scope .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .bootstrap-scope .pb-lg-1,
.bootstrap-scope .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .bootstrap-scope .pl-lg-1,
.bootstrap-scope .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .bootstrap-scope .p-lg-2 {
    padding: 0.5rem !important;
  }
  .bootstrap-scope .pt-lg-2,
.bootstrap-scope .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .bootstrap-scope .pr-lg-2,
.bootstrap-scope .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .bootstrap-scope .pb-lg-2,
.bootstrap-scope .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .bootstrap-scope .pl-lg-2,
.bootstrap-scope .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .bootstrap-scope .p-lg-3 {
    padding: 1rem !important;
  }
  .bootstrap-scope .pt-lg-3,
.bootstrap-scope .py-lg-3 {
    padding-top: 1rem !important;
  }
  .bootstrap-scope .pr-lg-3,
.bootstrap-scope .px-lg-3 {
    padding-right: 1rem !important;
  }
  .bootstrap-scope .pb-lg-3,
.bootstrap-scope .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .bootstrap-scope .pl-lg-3,
.bootstrap-scope .px-lg-3 {
    padding-left: 1rem !important;
  }
  .bootstrap-scope .p-lg-4 {
    padding: 1.5rem !important;
  }
  .bootstrap-scope .pt-lg-4,
.bootstrap-scope .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .bootstrap-scope .pr-lg-4,
.bootstrap-scope .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .bootstrap-scope .pb-lg-4,
.bootstrap-scope .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .bootstrap-scope .pl-lg-4,
.bootstrap-scope .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .bootstrap-scope .p-lg-5 {
    padding: 3rem !important;
  }
  .bootstrap-scope .pt-lg-5,
.bootstrap-scope .py-lg-5 {
    padding-top: 3rem !important;
  }
  .bootstrap-scope .pr-lg-5,
.bootstrap-scope .px-lg-5 {
    padding-right: 3rem !important;
  }
  .bootstrap-scope .pb-lg-5,
.bootstrap-scope .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .bootstrap-scope .pl-lg-5,
.bootstrap-scope .px-lg-5 {
    padding-left: 3rem !important;
  }
  .bootstrap-scope .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .bootstrap-scope .mt-lg-n1,
.bootstrap-scope .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .bootstrap-scope .mr-lg-n1,
.bootstrap-scope .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .bootstrap-scope .mb-lg-n1,
.bootstrap-scope .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .bootstrap-scope .ml-lg-n1,
.bootstrap-scope .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .bootstrap-scope .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .bootstrap-scope .mt-lg-n2,
.bootstrap-scope .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .bootstrap-scope .mr-lg-n2,
.bootstrap-scope .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .bootstrap-scope .mb-lg-n2,
.bootstrap-scope .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .bootstrap-scope .ml-lg-n2,
.bootstrap-scope .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .bootstrap-scope .m-lg-n3 {
    margin: -1rem !important;
  }
  .bootstrap-scope .mt-lg-n3,
.bootstrap-scope .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .bootstrap-scope .mr-lg-n3,
.bootstrap-scope .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .bootstrap-scope .mb-lg-n3,
.bootstrap-scope .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .bootstrap-scope .ml-lg-n3,
.bootstrap-scope .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .bootstrap-scope .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .bootstrap-scope .mt-lg-n4,
.bootstrap-scope .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .bootstrap-scope .mr-lg-n4,
.bootstrap-scope .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .bootstrap-scope .mb-lg-n4,
.bootstrap-scope .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .bootstrap-scope .ml-lg-n4,
.bootstrap-scope .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .bootstrap-scope .m-lg-n5 {
    margin: -3rem !important;
  }
  .bootstrap-scope .mt-lg-n5,
.bootstrap-scope .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .bootstrap-scope .mr-lg-n5,
.bootstrap-scope .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .bootstrap-scope .mb-lg-n5,
.bootstrap-scope .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .bootstrap-scope .ml-lg-n5,
.bootstrap-scope .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .bootstrap-scope .m-lg-auto {
    margin: auto !important;
  }
  .bootstrap-scope .mt-lg-auto,
.bootstrap-scope .my-lg-auto {
    margin-top: auto !important;
  }
  .bootstrap-scope .mr-lg-auto,
.bootstrap-scope .mx-lg-auto {
    margin-right: auto !important;
  }
  .bootstrap-scope .mb-lg-auto,
.bootstrap-scope .my-lg-auto {
    margin-bottom: auto !important;
  }
  .bootstrap-scope .ml-lg-auto,
.bootstrap-scope .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .bootstrap-scope .m-xl-0 {
    margin: 0 !important;
  }
  .bootstrap-scope .mt-xl-0,
.bootstrap-scope .my-xl-0 {
    margin-top: 0 !important;
  }
  .bootstrap-scope .mr-xl-0,
.bootstrap-scope .mx-xl-0 {
    margin-right: 0 !important;
  }
  .bootstrap-scope .mb-xl-0,
.bootstrap-scope .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .bootstrap-scope .ml-xl-0,
.bootstrap-scope .mx-xl-0 {
    margin-left: 0 !important;
  }
  .bootstrap-scope .m-xl-1 {
    margin: 0.25rem !important;
  }
  .bootstrap-scope .mt-xl-1,
.bootstrap-scope .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .bootstrap-scope .mr-xl-1,
.bootstrap-scope .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .bootstrap-scope .mb-xl-1,
.bootstrap-scope .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .bootstrap-scope .ml-xl-1,
.bootstrap-scope .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .bootstrap-scope .m-xl-2 {
    margin: 0.5rem !important;
  }
  .bootstrap-scope .mt-xl-2,
.bootstrap-scope .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .bootstrap-scope .mr-xl-2,
.bootstrap-scope .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .bootstrap-scope .mb-xl-2,
.bootstrap-scope .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .bootstrap-scope .ml-xl-2,
.bootstrap-scope .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .bootstrap-scope .m-xl-3 {
    margin: 1rem !important;
  }
  .bootstrap-scope .mt-xl-3,
.bootstrap-scope .my-xl-3 {
    margin-top: 1rem !important;
  }
  .bootstrap-scope .mr-xl-3,
.bootstrap-scope .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .bootstrap-scope .mb-xl-3,
.bootstrap-scope .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .bootstrap-scope .ml-xl-3,
.bootstrap-scope .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .bootstrap-scope .m-xl-4 {
    margin: 1.5rem !important;
  }
  .bootstrap-scope .mt-xl-4,
.bootstrap-scope .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .bootstrap-scope .mr-xl-4,
.bootstrap-scope .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .bootstrap-scope .mb-xl-4,
.bootstrap-scope .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .bootstrap-scope .ml-xl-4,
.bootstrap-scope .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .bootstrap-scope .m-xl-5 {
    margin: 3rem !important;
  }
  .bootstrap-scope .mt-xl-5,
.bootstrap-scope .my-xl-5 {
    margin-top: 3rem !important;
  }
  .bootstrap-scope .mr-xl-5,
.bootstrap-scope .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .bootstrap-scope .mb-xl-5,
.bootstrap-scope .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .bootstrap-scope .ml-xl-5,
.bootstrap-scope .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .bootstrap-scope .p-xl-0 {
    padding: 0 !important;
  }
  .bootstrap-scope .pt-xl-0,
.bootstrap-scope .py-xl-0 {
    padding-top: 0 !important;
  }
  .bootstrap-scope .pr-xl-0,
.bootstrap-scope .px-xl-0 {
    padding-right: 0 !important;
  }
  .bootstrap-scope .pb-xl-0,
.bootstrap-scope .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .bootstrap-scope .pl-xl-0,
.bootstrap-scope .px-xl-0 {
    padding-left: 0 !important;
  }
  .bootstrap-scope .p-xl-1 {
    padding: 0.25rem !important;
  }
  .bootstrap-scope .pt-xl-1,
.bootstrap-scope .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .bootstrap-scope .pr-xl-1,
.bootstrap-scope .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .bootstrap-scope .pb-xl-1,
.bootstrap-scope .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .bootstrap-scope .pl-xl-1,
.bootstrap-scope .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .bootstrap-scope .p-xl-2 {
    padding: 0.5rem !important;
  }
  .bootstrap-scope .pt-xl-2,
.bootstrap-scope .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .bootstrap-scope .pr-xl-2,
.bootstrap-scope .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .bootstrap-scope .pb-xl-2,
.bootstrap-scope .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .bootstrap-scope .pl-xl-2,
.bootstrap-scope .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .bootstrap-scope .p-xl-3 {
    padding: 1rem !important;
  }
  .bootstrap-scope .pt-xl-3,
.bootstrap-scope .py-xl-3 {
    padding-top: 1rem !important;
  }
  .bootstrap-scope .pr-xl-3,
.bootstrap-scope .px-xl-3 {
    padding-right: 1rem !important;
  }
  .bootstrap-scope .pb-xl-3,
.bootstrap-scope .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .bootstrap-scope .pl-xl-3,
.bootstrap-scope .px-xl-3 {
    padding-left: 1rem !important;
  }
  .bootstrap-scope .p-xl-4 {
    padding: 1.5rem !important;
  }
  .bootstrap-scope .pt-xl-4,
.bootstrap-scope .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .bootstrap-scope .pr-xl-4,
.bootstrap-scope .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .bootstrap-scope .pb-xl-4,
.bootstrap-scope .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .bootstrap-scope .pl-xl-4,
.bootstrap-scope .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .bootstrap-scope .p-xl-5 {
    padding: 3rem !important;
  }
  .bootstrap-scope .pt-xl-5,
.bootstrap-scope .py-xl-5 {
    padding-top: 3rem !important;
  }
  .bootstrap-scope .pr-xl-5,
.bootstrap-scope .px-xl-5 {
    padding-right: 3rem !important;
  }
  .bootstrap-scope .pb-xl-5,
.bootstrap-scope .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .bootstrap-scope .pl-xl-5,
.bootstrap-scope .px-xl-5 {
    padding-left: 3rem !important;
  }
  .bootstrap-scope .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .bootstrap-scope .mt-xl-n1,
.bootstrap-scope .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .bootstrap-scope .mr-xl-n1,
.bootstrap-scope .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .bootstrap-scope .mb-xl-n1,
.bootstrap-scope .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .bootstrap-scope .ml-xl-n1,
.bootstrap-scope .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .bootstrap-scope .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .bootstrap-scope .mt-xl-n2,
.bootstrap-scope .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .bootstrap-scope .mr-xl-n2,
.bootstrap-scope .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .bootstrap-scope .mb-xl-n2,
.bootstrap-scope .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .bootstrap-scope .ml-xl-n2,
.bootstrap-scope .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .bootstrap-scope .m-xl-n3 {
    margin: -1rem !important;
  }
  .bootstrap-scope .mt-xl-n3,
.bootstrap-scope .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .bootstrap-scope .mr-xl-n3,
.bootstrap-scope .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .bootstrap-scope .mb-xl-n3,
.bootstrap-scope .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .bootstrap-scope .ml-xl-n3,
.bootstrap-scope .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .bootstrap-scope .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .bootstrap-scope .mt-xl-n4,
.bootstrap-scope .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .bootstrap-scope .mr-xl-n4,
.bootstrap-scope .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .bootstrap-scope .mb-xl-n4,
.bootstrap-scope .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .bootstrap-scope .ml-xl-n4,
.bootstrap-scope .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .bootstrap-scope .m-xl-n5 {
    margin: -3rem !important;
  }
  .bootstrap-scope .mt-xl-n5,
.bootstrap-scope .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .bootstrap-scope .mr-xl-n5,
.bootstrap-scope .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .bootstrap-scope .mb-xl-n5,
.bootstrap-scope .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .bootstrap-scope .ml-xl-n5,
.bootstrap-scope .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .bootstrap-scope .m-xl-auto {
    margin: auto !important;
  }
  .bootstrap-scope .mt-xl-auto,
.bootstrap-scope .my-xl-auto {
    margin-top: auto !important;
  }
  .bootstrap-scope .mr-xl-auto,
.bootstrap-scope .mx-xl-auto {
    margin-right: auto !important;
  }
  .bootstrap-scope .mb-xl-auto,
.bootstrap-scope .my-xl-auto {
    margin-bottom: auto !important;
  }
  .bootstrap-scope .ml-xl-auto,
.bootstrap-scope .mx-xl-auto {
    margin-left: auto !important;
  }
}
.bootstrap-scope .text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}
.bootstrap-scope .text-justify {
  text-align: justify !important;
}
.bootstrap-scope .text-wrap {
  white-space: normal !important;
}
.bootstrap-scope .text-nowrap {
  white-space: nowrap !important;
}
.bootstrap-scope .text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.bootstrap-scope .text-left {
  text-align: left !important;
}
.bootstrap-scope .text-right {
  text-align: right !important;
}
.bootstrap-scope .text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .bootstrap-scope .text-sm-left {
    text-align: left !important;
  }
  .bootstrap-scope .text-sm-right {
    text-align: right !important;
  }
  .bootstrap-scope .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .bootstrap-scope .text-md-left {
    text-align: left !important;
  }
  .bootstrap-scope .text-md-right {
    text-align: right !important;
  }
  .bootstrap-scope .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .bootstrap-scope .text-lg-left {
    text-align: left !important;
  }
  .bootstrap-scope .text-lg-right {
    text-align: right !important;
  }
  .bootstrap-scope .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .bootstrap-scope .text-xl-left {
    text-align: left !important;
  }
  .bootstrap-scope .text-xl-right {
    text-align: right !important;
  }
  .bootstrap-scope .text-xl-center {
    text-align: center !important;
  }
}
.bootstrap-scope .text-lowercase {
  text-transform: lowercase !important;
}
.bootstrap-scope .text-uppercase {
  text-transform: uppercase !important;
}
.bootstrap-scope .text-capitalize {
  text-transform: capitalize !important;
}
.bootstrap-scope .font-weight-light {
  font-weight: 300 !important;
}
.bootstrap-scope .font-weight-lighter {
  font-weight: lighter !important;
}
.bootstrap-scope .font-weight-normal {
  font-weight: 400 !important;
}
.bootstrap-scope .font-weight-bold {
  font-weight: 700 !important;
}
.bootstrap-scope .font-weight-bolder {
  font-weight: bolder !important;
}
.bootstrap-scope .font-italic {
  font-style: italic !important;
}
.bootstrap-scope .text-white {
  color: #fff !important;
}
.bootstrap-scope .text-primary {
  color: #007bff !important;
}
.bootstrap-scope a.text-primary:hover, .bootstrap-scope a.text-primary:focus {
  color: #0056b3 !important;
}
.bootstrap-scope .text-secondary {
  color: #6c757d !important;
}
.bootstrap-scope a.text-secondary:hover, .bootstrap-scope a.text-secondary:focus {
  color: #494f54 !important;
}
.bootstrap-scope .text-success {
  color: #28a745 !important;
}
.bootstrap-scope a.text-success:hover, .bootstrap-scope a.text-success:focus {
  color: #19692c !important;
}
.bootstrap-scope .text-info {
  color: #17a2b8 !important;
}
.bootstrap-scope a.text-info:hover, .bootstrap-scope a.text-info:focus {
  color: #0f6674 !important;
}
.bootstrap-scope .text-warning {
  color: #ffc107 !important;
}
.bootstrap-scope a.text-warning:hover, .bootstrap-scope a.text-warning:focus {
  color: #ba8b00 !important;
}
.bootstrap-scope .text-danger {
  color: #dc3545 !important;
}
.bootstrap-scope a.text-danger:hover, .bootstrap-scope a.text-danger:focus {
  color: #a71d2a !important;
}
.bootstrap-scope .text-light {
  color: #f8f9fa !important;
}
.bootstrap-scope a.text-light:hover, .bootstrap-scope a.text-light:focus {
  color: #cbd3da !important;
}
.bootstrap-scope .text-dark {
  color: #343a40 !important;
}
.bootstrap-scope a.text-dark:hover, .bootstrap-scope a.text-dark:focus {
  color: #121416 !important;
}
.bootstrap-scope .text-body {
  color: #212529 !important;
}
.bootstrap-scope .text-muted {
  color: #6c757d !important;
}
.bootstrap-scope .text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
.bootstrap-scope .text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.bootstrap-scope .text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.bootstrap-scope .text-decoration-none {
  text-decoration: none !important;
}
.bootstrap-scope .text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}
.bootstrap-scope .text-reset {
  color: inherit !important;
}
.bootstrap-scope .visible {
  visibility: visible !important;
}
.bootstrap-scope .invisible {
  visibility: hidden !important;
}
@media print {
  .bootstrap-scope *,
.bootstrap-scope *::before,
.bootstrap-scope *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  .bootstrap-scope a:not(.btn) {
    text-decoration: underline;
  }
  .bootstrap-scope abbr[title]::after {
    content: " (" attr(title) ")";
  }
  .bootstrap-scope pre {
    white-space: pre-wrap !important;
  }
  .bootstrap-scope pre,
.bootstrap-scope blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  .bootstrap-scope thead {
    display: table-header-group;
  }
  .bootstrap-scope tr,
.bootstrap-scope img {
    page-break-inside: avoid;
  }
  .bootstrap-scope p,
.bootstrap-scope h2,
.bootstrap-scope h3 {
    orphans: 3;
    widows: 3;
  }
  .bootstrap-scope h2,
.bootstrap-scope h3 {
    page-break-after: avoid;
  }
  @page {
    .bootstrap-scope {
      size: a3;
    }
  }
  .bootstrap-scope body {
    min-width: 992px !important;
  }
  .bootstrap-scope .container {
    min-width: 992px !important;
  }
  .bootstrap-scope .navbar {
    display: none;
  }
  .bootstrap-scope .badge {
    border: 1px solid #000;
  }
  .bootstrap-scope .table {
    border-collapse: collapse !important;
  }
  .bootstrap-scope .table td,
.bootstrap-scope .table th {
    background-color: #fff !important;
  }
  .bootstrap-scope .table-bordered th,
.bootstrap-scope .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .bootstrap-scope .table-dark {
    color: inherit;
  }
  .bootstrap-scope .table-dark th,
.bootstrap-scope .table-dark td,
.bootstrap-scope .table-dark thead th,
.bootstrap-scope .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .bootstrap-scope .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

/*# sourceMappingURL=beeze.css.map */
