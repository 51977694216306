@font-face {
    font-family: 'FontNumber';
    src: url('../assets/font/RSU_Regular.ttf')
}

@font-face {
    font-family: 'FontText';
    src: url('../assets/font/RSU_Regular.ttf')
}

:root {
    --rotate: 0deg;
    --logoFontSize: 10px;
    --logoWebFontSize: 10px;
}

#sticker {
    position: relative;
}

.Div-Sticker {
    display: flex;
    justify-content: center;
    font-family: 'FontText';
    color: black;
}

.header_wrapper {
    text-align: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.header_company {
    text-align: center;
    top: 5.5%;
    position: absolute;
    width: 100%;
    font-size: 2.9rem;
    color: red;
}

.header_brand {
    text-align: center;
    top: 10%;
    position: absolute;
    width: 100%;
    font-size: 2.5rem;
    color: gray;
}

.header_gen {
    text-align: center;
    top: 14.5%;
    position: absolute;
    width: 100%;
    font-size: 1.8rem;
}

.header_size {
    text-align: center;
    top: 17.2%;
    position: absolute;
    width: 100%;
    font-size: 1.8rem;
}

.vector_1_wrapper_A {
    text-align: center;
    position: absolute;
    top: 35.5%;
    left: 18.4%;
    width: 29%;
    height: 8%;
}

.vector_1_wrapper_B {
    text-align: center;
    position: absolute;
    top: 38.2%;
    left: 18.4%;
    width: 29%;
    height: 8%;
}

.vector_1_wrapper_C {
    text-align: center;
    position: absolute;
    top: 40.9%;
    left: 18.4%;
    width: 29%;
    height: 8%;
}

.vector_1_wrapper_D {
    text-align: center;
    position: absolute;
    top: 43.6%;
    left: 18.4%;
    width: 29%;
    height: 8%;
}

.vector_1_wrapper_E {
    text-align: center;
    position: absolute;
    top: 46.3%;
    left: 18.4%;
    width: 29%;
    height: 8%;
}

.vector_1_wrapper_F {
    text-align: center;
    position: absolute;
    top: 49%;
    left: 18.4%;
    width: 29%;
    height: 8%;
}

.vector_1_wrapper_G {
    text-align: center;
    position: absolute;
    top: 51.8%;
    left: 18.4%;
    width: 29%;
    height: 8%;
}

.vector_1 {
    position: absolute;
    width: 95%;
}

.vector_1_txt {
    left: 100%;
    top: 17%;
    position: absolute;
    color: white;
    font-size: 1.5em
}

.vector_2_wrapper_A {
    text-align: center;
    position: absolute;
    top: 36.2%;
    right: 13.8%;
    width: 22%;
    height: 8%;
}

.vector_2_wrapper_B {
    text-align: center;
    position: absolute;
    top: 39%;
    right: 13.8%;
    width: 22%;
    height: 8%;
}

.vector_2_wrapper_C {
    text-align: center;
    position: absolute;
    top: 41.7%;
    right: 13.8%;
    width: 22%;
    height: 8%;
}

.vector_2_wrapper_D {
    text-align: center;
    position: absolute;
    top: 44.4%;
    right: 13.8%;
    width: 22%;
    height: 8%;
}

.vector_2_wrapper_E {
    text-align: center;
    position: absolute;
    top: 47.2%;
    right: 13.8%;
    width: 22%;
    height: 8%;
}

.vector_2_wrapper_F {
    text-align: center;
    position: absolute;
    top: 49.9%;
    right: 13.8%;
    width: 22%;
    height: 8%;
}

.vector_2_wrapper_G {
    text-align: center;
    position: absolute;
    top: 52.4%;
    right: 13.8%;
    width: 22%;
    height: 8%;
}

.vector_2 {
    position: absolute;
    width: 95%;
}

.vector_2_txt {
    left: 95%;
    top: 10%;
    position: absolute;
    /* width: 28.5%; */
    color: white;
    font-size: 1.3em
}

.volume {
    width: 6%;
    left: 38%;
    top: 61.2%;
    position: absolute;
}

.volume_txt {
    text-align: center;
    right: 12%;
    top: 61.6%;
    position: absolute;
    font-size: 1.5em;
    color: white;
}

.tyre_class_wrapper {
    width: 100%;
    top: 69%;
    position: absolute;
    font-size: 1em;
}

.tyre_class_left {
    left: 3%;
    position: absolute;
}

.tyre_class_right {
    right: 3%;
    position: absolute;
}

.standard_top {
    top: 72.8%;
    left: 38%;
    position: absolute;
    font-size: 1.2em;
}

.standard_bottom {
    top: 76%;
    left: 38%;
    position: absolute;
    font-size: 1.2em;
}

.produce_by_wrapper {
    text-align: left;
    position: absolute;
    top: 80%;
    font-size: 1em;
    width: 98%;
}

.produce_by_right {
    position: absolute;
    left: 55%;
}

.produce_by_left {
    position: absolute;
    left: 38.8%;
}

.import_by_wrapper {
    text-align: left;
    position: absolute;
    top: 85%;
    font-size: 1em;
    width: 98%;
}

.import_by_right {
    position: absolute;
    left: 55%;
}

.import_by_left {
    position: absolute;
    left: 38.8%;
}

.country_wrapper {
    text-align: left;
    position: absolute;
    top: 90%;
    font-size: 1em;
    width: 98%;
}

.country_right {
    position: absolute;
    left: 55%;
}

.country_left {
    position: absolute;
    left: 38.8%;
}

.ShowSticker {
    z-index: 0;
    width: 100%;
}

.QR_wrapper {
    /* text-align: center; */
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.QR_txt{
    text-align: left;
    top: 87.5%;
    left:10.5%;
    position: absolute;
    width: 100%;
    font-size: 0.7rem;
    
}
.QR {
    position: absolute;
    left:8%;
    top:87.8%;
    width: 15.5%;

}

/* Mobile Portrait with Large width  e.g. Pixel2 ,iPhone 678+ */

@media only screen and (min-width: 411px) and (max-width: 767px) and (orientation: portrait) {
    /* .QR_wrapper {
        left: 7.8%;
        top: 85%
    } */
    /* .QR_txt{
        top:16%
    }
    .QR {
        width: 50%;
    } */
}

/* Mobile Landscape */

@media only screen and (min-width: 440px) and (max-width: 766px) {
    .header_company {
        text-align: center;
        top: 5.5%;
        position: absolute;
        width: 100%;
        font-size: 3.9rem;
        color: red;
    }
    .header_brand {
        text-align: center;
        top: 10%;
        position: absolute;
        width: 100%;
        font-size: 3.5rem;
        color: gray;
    }
    .header_gen {
        text-align: center;
        top: 14.5%;
        position: absolute;
        width: 100%;
        font-size: 2.8rem;
    }
    .header_size {
        text-align: center;
        top: 17.2%;
        position: absolute;
        width: 100%;
        font-size: 2.8rem;
    }
    .vector_1_txt {
        left: 100%;
        top: 17%;
        position: absolute;
        color: white;
        font-size: 2.5em
    }
    .vector_2_txt {
        left: 95%;
        top: 10%;
        position: absolute;
        color: white;
        font-size: 2.3em
    }
    .volume_txt {
        text-align: center;
        right: 12%;
        top: 61.9%;
        position: absolute;
        font-size: 2.4em;
        color: white;
    }
    .tyre_class_wrapper {
        width: 100%;
        top: 69%;
        position: absolute;
        font-size: 1.8em;
    }
    .standard_top {
        top: 72.8%;
        left: 38%;
        position: absolute;
        font-size: 2em;
    }
    .standard_bottom {
        top: 76%;
        left: 38%;
        position: absolute;
        font-size: 2em;
    }
    .produce_by_wrapper {
        text-align: left;
        position: absolute;
        top: 80%;
        font-size: 1.8em;
        width: 98%;
    }

    .import_by_wrapper {
        text-align: left;
        position: absolute;
        top: 85%;
        font-size: 1.8em;
        width: 98%;
    }

    .country_wrapper {
        text-align: left;
        position: absolute;
        top: 90%;
        font-size: 1.8em;
        width: 98%;
    }
    /* .QR_wrapper {
        left: 7.6%;
        position: absolute;
        top: 88%
    } */
    .QR {
        width: 80%;
    }
}



/* Iphone 5/SE Portrait */

@media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    /* .QR_wrapper {
        left: 7.6%;
        top: 82.6%
    } */
    /* .QR_txt{
        left:5%;
        top:22%
    }

    .QR {
        width: 40%;
    } */
    .QR_txt{
       left:10%
    }
    .QR{
        top:86.8%
    }
}

/* Iphone 5/SE Landscape */

@media (device-height: 320px) and (device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    /* .QR_wrapper {
        left: 8.5%;
        top: 87.7%
    } */
    
    .QR {
        width: 65%;
    }
}

/* IPAD Pro protrait */

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    .import_by_wrapper {
        top: 87%;
  
    }

    .country_wrapper {

        top: 94%;
        
    }

    .QR {
        /* width: 64%; */
        left: 8.2%;
        top: 86.3%
    }
    .QR_txt {
        /* width: 64%; */
        left: 9.4%;
       
    }
}

/* IPAD Pro landscape */

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
    /* .QR_wrapper {
        left: 7.6%;
        top: 85%
    } */
    .QR {
        width: 15%;
    }
}

/* IPAD Landscape */

@media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
    .import_by_wrapper {
        top: 87%;
  
    }

    .country_wrapper {

        top: 94%;
        
    }
    .QR {
        top:86.5%;
        width: 15%;
    }
    .QR_txt {
        left:9%
    }
}

@media only screen and (min-width: 1400px) {
    .header_gen {
        text-align: center;
        top: 14.5%;
        position: absolute;
        width: 100%;
        font-size: 1.8rem;
    }
    .header_size {
        text-align: center;
        top: 17.2%;
        position: absolute;
        width: 100%;
        font-size: 1.8rem;
    }
    .vector_1_wrapper_A {
        text-align: center;
        position: absolute;
        top: 35.5%;
        left: 18.4%;
        width: 29%;
        height: 8%;
    }
    .vector_1_wrapper_B {
        text-align: center;
        position: absolute;
        top: 38.2%;
        left: 18.4%;
        width: 29%;
        height: 8%;
    }
    .vector_1_wrapper_C {
        text-align: center;
        position: absolute;
        top: 40.9%;
        left: 18.4%;
        width: 29%;
        height: 8%;
    }
    .vector_1_wrapper_D {
        text-align: center;
        position: absolute;
        top: 43.6%;
        left: 18.4%;
        width: 29%;
        height: 8%;
    }
    .vector_1_wrapper_E {
        text-align: center;
        position: absolute;
        top: 46.3%;
        left: 18.4%;
        width: 29%;
        height: 8%;
    }
    .vector_1_wrapper_F {
        text-align: center;
        position: absolute;
        top: 49%;
        left: 18.4%;
        width: 29%;
        height: 8%;
    }
    .vector_1_wrapper_G {
        text-align: center;
        position: absolute;
        top: 51.8%;
        left: 18.4%;
        width: 29%;
        height: 8%;
    }
    .vector_1 {
        position: absolute;
        width: 95%;
    }
    .vector_1_txt {
        left: 100%;
        top: 20%;
        position: absolute;
        color: white;
        font-size: 1.5em
    }
    .vector_2 {
        position: absolute;
        width: 95%;
    }
    .vector_2_txt {
        left: 95%;
        top: 14%;
        position: absolute;
        color: white;
        font-size: 1.3em
    }
    .volume {
        width: 6%;
        left: 38%;
        top: 61.2%;
        position: absolute;
    }
    .volume_txt {
        text-align: center;
        right: 12%;
        top: 62%;
        position: absolute;
        font-size: 1.5em;
        color: white;
    }
    .tyre_class_wrapper {
        width: 100%;
        top: 69.4%;
        position: absolute;
        font-size: 1em;
    }
    .tyre_class_left {
        left: 3%;
        position: absolute;
    }
    .tyre_class_right {
        right: 3%;
        position: absolute;
    }
    /* .QR_wrapper {
        left: 8.6%;
        top: 85%
    } */
}

@media only screen and (min-width: 1600px) {
    .header_gen {
        text-align: center;
        top: 14.5%;
        position: absolute;
        width: 100%;
        font-size: 1.8rem;
    }
    .header_size {
        text-align: center;
        top: 17.2%;
        position: absolute;
        width: 100%;
        font-size: 1.8rem;
    }
    .vector_1_wrapper_A {
        text-align: center;
        position: absolute;
        top: 35.5%;
        left: 18.4%;
        width: 29%;
        height: 8%;
    }
    .vector_1_wrapper_B {
        text-align: center;
        position: absolute;
        top: 38.2%;
        left: 18.4%;
        width: 29%;
        height: 8%;
    }
    .vector_1_wrapper_C {
        text-align: center;
        position: absolute;
        top: 40.9%;
        left: 18.4%;
        width: 29%;
        height: 8%;
    }
    .vector_1_wrapper_D {
        text-align: center;
        position: absolute;
        top: 43.6%;
        left: 18.4%;
        width: 29%;
        height: 8%;
    }
    .vector_1_wrapper_E {
        text-align: center;
        position: absolute;
        top: 46.3%;
        left: 18.4%;
        width: 29%;
        height: 8%;
    }
    .vector_1_wrapper_F {
        text-align: center;
        position: absolute;
        top: 49%;
        left: 18.4%;
        width: 29%;
        height: 8%;
    }
    .vector_1_wrapper_G {
        text-align: center;
        position: absolute;
        top: 51.8%;
        left: 18.4%;
        width: 29%;
        height: 8%;
    }
    .vector_1 {
        position: absolute;
        width: 95%;
    }
    .vector_1_txt {
        left: 100%;
        top: 20%;
        position: absolute;
        color: white;
        font-size: 1.8em
    }
    .vector_2 {
        position: absolute;
        width: 95%;
    }
    .vector_2_txt {
        left: 95%;
        top: 14%;
        position: absolute;
        color: white;
        font-size: 1.6em
    }
    .volume {
        width: 6%;
        left: 38%;
        top: 61.2%;
        position: absolute;
    }
    .volume_txt {
        text-align: center;
        right: 12%;
        top: 62%;
        position: absolute;
        font-size: 1.8em;
        color: white;
    }
    .tyre_class_wrapper {
        width: 100%;
        top: 69.4%;
        position: absolute;
        font-size: 1.4em;
    }
    .tyre_class_left {
        left: 3%;
        position: absolute;
    }
    .tyre_class_right {
        right: 3%;
        position: absolute;
    }
    .standard_top {
        top: 72.8%;
        left: 38%;
        position: absolute;
        font-size: 1.8em;
    }
    
    .standard_bottom {
        top: 76%;
        left: 38%;
        position: absolute;
        font-size: 1.8em;
    }
    
    .produce_by_wrapper {
        text-align: left;
        position: absolute;
        top: 80%;
        font-size: 1.5em;
        width: 98%;
    }

    .import_by_wrapper {
        text-align: left;
        position: absolute;
        top: 85%;
        font-size: 1.5em;
        width: 98%;
    }

    .country_wrapper {
        text-align: left;
        position: absolute;
        top: 90%;
        font-size: 1.5em;
        width: 98%;
    }
    
    .produce_by_right {
        position: absolute;
        left: 55%;
    }
    
    .produce_by_left {
        position: absolute;
        left: 38.8%;
    }

    /* .QR_wrapper {
        left: 7%;
        top: 86.2%
    } */
    .QR{
        top:89%;
        width:16%
    }
    .QR_txt{
        left:10.6%;
        font-size:1em
    }
}
@media only screen and (min-width: 1800px) {
    .vector_1_txt {
        left: 100%;
        top: 24%;
        font-size: 1.8em
    }
    /* .QR_wrapper {
        left: 8.2%;
        top: 87.2%
    } */
    .QR {
        left:7.5%
    }
}