.btn_size {
    width: 100px;
}
/* 
.App {
	text-align: center;
} */

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.paddingTop10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
input,
a,
link,
textArea,
button,
header {
    font-family: "Sarabun", sans-serif;
}

@font-face {
    font-family: "Sarabun";
    src: url("./fonts/Sarabun-Regular.ttf");
}

.ui.header {
    font-family: "Sarabun", sans-serif;
}

.ui.button {
    font-family: "Sarabun", sans-serif;
}

.itemHeaderDropdown {
    margin: 0;
    text-align: left;
    font-size: 1em !important;
    padding: 0 !important;
    background: 0 0 !important;
    color: rgba(0, 0, 0, 0.87) !important;
    text-transform: none !important;
    font-weight: 400 !important;
    box-shadow: none !important;
    transition: none !important;
}

.itemDropdown {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0.78571429em 1.14285714em;
    color: black;
}
.itemDropdown:hover {
    background: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.95);
    z-index: 13;
}

.ui.modals.dimmer.visible.active {
    z-index: 2000;
}
