.container {
    margin-top: 5%;
    width: 100%;
    overflow-y: hidden;
}
.headerListNews {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.containerBoxNews {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.boxImageBackgroud {
    width: 475px;
    height: 475px;
    position: absolute;
    top: -13%;
    right: -6%;
}

.containerSlideNews {
    display: flex;
    flex-direction: column;
    padding: 5% 10%;
    position: relative;
    overflow: hidden;
    width: 100%;
    background: rgb(197, 238, 174);
    background: linear-gradient(45deg, rgba(197, 238, 174, 0.6) 0%, rgba(225, 215, 80, 0.6) 100%);
}
.boxSlideNews {
    position: relative;
    z-index: 999;
    background-color: #fff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
    // justify-content: center;
    .imageSlideNews {
        width: 470px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
        overflow: hidden;
        .imageNews {
            display: block;
            width: 100%;
            object-fit: cover;
        }
    }
    .contentSlideNews {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 55%;
        padding: 20px;
        h5 {
            color: #0096ff;
        }
    }
}

.boxIndicators {
    margin: 15px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .indicators {
        padding: 0 15px;
        cursor: pointer;
        width: 45px;
        height: 10px;
        background: #333333;
        border-radius: 50px;
        transition: width 0.5s;
    }
    .indicatorsActive {
        padding: 0 15px;
        cursor: pointer;
        width: 57px;
        height: 10px;
        background: #71c21b;
        border-radius: 50px;
        transition: width 0.5s;
    }
    .indicators:hover {
        background: #71c21b;
        width: 57px;
    }
}
.headerListCards {
    color: #333333;
    padding: 50px 20px;
    h5 {
        font-size: 38px;
    }
}
.boxSectionHeader {
    h3 {
        font-family: "Raleway", sans-serif;
    }
}
.boxGirdImage {
    width: 53%;
    // height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .boxImageShowContentNews {
        height: 100%;
        width: 100%;
        border: none;
        outline: none;
        background-color: #fff;
        border-radius: 15;
        overflow-x: hidden;
    }
}
.boxPreviewPdfCard {
    font-family: "Prompt", sans-serif;
    width: 100%;
    height: 55vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;

    .boxImagePreviewPdfCard {
        width: 92%;
        height: 50%;
        border-radius: 16px;
        overflow: hidden;
        margin-bottom: 10px;

        // border: 1px solid linear-gradient(357.13deg, #ffd53f 2.15%, #a4dd00 97.46%);
        .imagePreviewPdf {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.6s all ease-in-out;
            border-radius: 10px;
        }
    }
    .boxContentPreviewPdfCard {
        display: flex;
        flex-direction: column;
        padding: 20px 25px;
        a {
            font-family: "Prompt", sans-serif;
            color: #71c21b;
        }
    }
}
.boxPreviewPdfCard:hover {
    width: 100%;
    height: 55vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 10px;
    padding: 10px;
    background: linear-gradient(357.13deg, #ffd53f 2.15%, #a4dd00 97.46%) padding-box,
        linear-gradient(357.13deg, #ffd53f 2.15%, #a4dd00 97.46%) border-box;
    cursor: pointer;
    .boxImagePreviewPdfCard {
        width: 92%;
        height: 50%;
        border-radius: 16px;
        overflow: hidden;
        margin-bottom: 10px;
        // border: 1px solid linear-gradient(357.13deg, #ffd53f 2.15%, #a4dd00 97.46%);
        .imagePreviewPdf {
            transform: scale(1.3);
        }
    }
    .boxContentPreviewPdfCard {
        display: flex;
        flex-direction: column;
        padding: 20px 25px;
        a {
            color: #333333;
        }
    }
}

.contentNews {
    width: 100%;
    // align-items: center;
    justify-content: space-around;
    display: flex;
    flex-direction: row;
}

.card {
    width: 45%;
    border-radius: 10px;
    background: #fff;
    // box-shadow: rgb(203, 170 ,35 / 40%) 1px 1px 15px 1px;;
    // box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    .cardActionArea {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        .cardMedia {
            height: 180;
            overflow: hidden;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            img {
                width: 100%;
                object-fit: cover;
                transition: 0.6s all ease-in-out;
            }
            img:hover {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                transform: scale(1.5);
            }
        }
        .cardContent {
            display: flex;
            flex-direction: column;
            padding: 20px 25px;
            span {
                font-size: 18px;
                color: #0096ff;
            }
            p {
                font-size: 14px;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                line-clamp: 3;
                text-overflow: ellipsis;
            }
        }
    }
}
.card:hover {
    opacity: 0.9;
}
.cardPreviewPdf {
    width: 400px;
    border-radius: 10px;
    background: #fff;
    // box-shadow: rgb(203, 170 ,35 / 40%) 1px 1px 15px 1px;;
    // box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    .cardActionArea {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        .cardMedia {
            height: 180;
            overflow: hidden;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            img {
                width: 100%;
                object-fit: cover;
                transition: 0.6s all ease-in-out;
            }
            img:hover {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                transform: scale(1.5);
            }
        }
        .cardContent {
            display: flex;
            flex-direction: column;
            padding: 20px 25px;
            h4 {
                font-size: 18px;
                color: #0096ff;
            }
            p {
                font-size: 14px;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                line-clamp: 3;
                text-overflow: ellipsis;
            }
        }
    }
}
.card:hover {
    opacity: 0.9;
}
.containerPreviewPdf {
    width: 100%;
    padding: 10px;
    .boxContentShowPdf {
        display: flex;
        flex-direction: row;
        border-radius: 10px;
        background: #fff;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        cursor: pointer;
        .boximageContentPdf {
            width: 50%;
            height: 235px;
            overflow: hidden;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            .imagePreviewPdf {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: 0.6s all ease-in-out;
            }
            .imagePreviewPdf:hover {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                transform: scale(1.5);
            }
        }
        .boxContentPdf {
            width: 50%;
            padding: 16px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            span {
                font-size: 18px;
                // display: -webkit-box;
                // -webkit-line-clamp: 3;
                // -webkit-box-orient: vertical;
                // overflow: hidden;
                line-clamp: 3;
                line-height: 25px;
                margin-bottom: 15px;
                // text-overflow: ellipsis;
            }
            p {
                font-size: 14px;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                line-clamp: 3;
                text-overflow: ellipsis;
            }
        }
    }
}

.boxPagination {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 10px 15px;
}

.boxSlideContent {
    padding: 15px;
    background-color: #ffff;
    border-radius: 5px;
    h3 {
        color: #333333;
    }
    small {
        font-size: 18px;
        color: #0096ff;
    }
}

.containerCarouselItem {
    height: 396px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.myCarousel {
    div {
        ol {
            li .active {
                padding: 0 15px !important;
                cursor: pointer !important;
                width: 57px !important;
                height: 10px !important;
                border-radius: 50px !important;
                transition: width 0.5s !important;
            }
            li {
                padding: 0 15px !important;
                cursor: pointer !important;
                width: 40px !important;
                height: 10px !important;
                background: #ffff !important;
                border-radius: 50px !important;
                transition: width 0.5s !important;
                border: none !important;
            }

            // li:hover {
            //     background: #71c21b !important;
            //     width: 57px !important;
            // }
        }
    }
}

// .bootstrap-scope .carousel-indicators .active {
//     padding: 0 15px !important;
//     cursor: pointer !important;
//     width: 57px !important;
//     height: 10px !important;
//     background: #71c21b !important;
//     border-radius: 50px !important;
//     transition: width 0.5s !important;
// }
