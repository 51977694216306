$media: screen;
$feature_min: min-width;
$feature_max: max-width;
$value_one: 767px;
$value_two: 768px;
$value_three: 991px;
$value_four: 992px;
$value_five: 1199px;
$value_six: 1400px;
$template_color: #71c21b;

.bootstrap-scope {
    @media only #{$media} and ($feature_max : $value_one) {
        .reports {
            background-attachment: scroll;
            .map-img {
                width: 100%;

                h1 {
                    font-size: 18px;
                    bottom: 180px;
                    left: 40%;
                }
            }
            .reveal-card:hover {
                .content {
                    width: 100%;
                    margin-top: 0px;
                    visibility: visible;
                    max-height: 100px;
                    max-width: 50vw;
                    transition: ease-in 0.25s;
                    // div{
                    //   width:25%;
                    //   transition: linear 0.5s;
                    // }
                }
            }
        }
        .news {
            .map-img {
                // width: 80%;

                h1 {
                    font-size: 18px;
                    bottom: 180px;
                    left: 40%;
                }
            }
        }
        .d-table-cell {
            vertical-align: top;
        }
        .main-banner .hero-content {
            font-size: 0.5em;
        }
        .ptb-100 {
            padding-top: 50px;
            padding-bottom: 50px;
        }

        .btn {
            font-size: 14px;
            padding: 9px 30px;
        }

        p {
            font-size: 14px;
            line-height: 1.6;
        }

        .form-control {
            height: 40px;
            padding: 10px;
            font-size: 14px;
        }

        .owl-theme {
            .owl-nav {
                [class*="owl-"] {
                    left: 15px;
                    top: auto;
                    font-size: 15px !important;
                    width: 50px;
                    bottom: 10px;
                    height: 50px;
                    line-height: 40px !important;
                }

                .owl-next {
                    left: auto;
                    right: 15px;
                }
            }

            .owl-dots {
                margin-top: 30px;

                .owl-dot {
                    span {
                        width: 13px;
                        height: 13px;
                        margin: 0 3px;
                    }
                }
            }
        }

        .section-title {
            margin-bottom: 40px;
            margin-top: -4px;

            span {
                margin-bottom: 8px;
                font-size: 13px;
            }

            h2 {
                font-size: 25px;
                margin-bottom: 13px;
            }

            p {
                font-size: 14px;
                max-width: 100%;
            }
        }

        .navbar {
            .container {
                padding-right: 15px;
                padding-left: 15px;
            }

            &.navbar-light {
                .navbar-toggler {
                    color: $template_color;
                    outline: 0 !important;
                    border-radius: 0;
                    padding: 0.25rem 0.55rem;
                    border-color: $template_color;

                    .navbar-toggler-icon {
                        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(113, 194, 27, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
                    }
                }

                .navbar-nav {
                    background: #ffffff;
                    padding: 20px 0;
                    text-align: left;
                    // max-width: 125px;

                    .nav-item {
                        padding: 7px 15px;

                        .nav-link {
                            color: #000000;

                            &.active {
                                color: $template_color;
                            }
                        }

                        .nav_icon {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }

                &.is-sticky {
                    padding: 8px 0;

                    .container {
                        position: relative;
                    }

                    .nav-item {
                        padding: 7px 0;
                    }

                    // #basic-navbar-nav {
                    //     position: absolute;
                    //     top: 60px;
                    //     right: 15px;
                    //     width: 250px;
                    // }
                    &.navbar.navbar-light {
                        .navbar-nav {
                            background: #ffffff;
                            padding: 20px 15px;
                            text-align: left;
                            // max-width: 125px;
                        }
                    }
                }
            }
        }

        .main-banner {
            height: 100%;
            // padding-top: 10px;
            padding-bottom: 30px;

            .hero-content {
                max-width: 100%;

                h5 {
                    font-size: 16px;
                }

                h1 {
                    font-size: 35px;
                }

                p {
                    font-size: 14px;
                }

                .btn {
                    margin-right: 5px;
                }

                .video-btn {
                    font-size: 14px;

                    i {
                        font-size: 34px;
                        top: 10px;
                        margin-right: 4px;
                    }
                }
            }

            .animation-box6 {
                display: none;
            }

            .scroll_down {
                height: 40px;
                width: 25px;
                bottom: 25px;
            }
        }

        .animation-box1 {
            left: 10px;
            bottom: 15px;
        }

        .animation-box3 {
            left: 115px;
            top: 25px;
        }

        .animation-box2 {
            right: 5px;
            top: 100px;
        }

        .animation-box4 {
            right: -350px;
            bottom: -350px;
        }

        .animation-box7 {
            bottom: 0;
        }

        .modal {
            &.right {
                .modal-dialog {
                    width: 100%;
                }
            }
        }

        .subscribe-area {
            text-align: center;
            padding-top: 40px;
            padding-bottom: 40px;

            &::before {
                height: 6px;
            }

            p {
                margin-bottom: 22px;
                font-size: 19px;
                margin-top: -8px;
            }

            button {
                width: 70px;
                font-size: 20px;
                height: 40px;
                line-height: 44px;
            }
        }

        .welcome-area {
            padding-bottom: 20px;
        }

        .single-box {
            margin-bottom: 30px;
            padding: 25px 15px;

            i {
                font-size: 30px;
            }

            h3 {
                font-size: 18px;
                margin-bottom: 10px;
                margin-top: 15px;
            }
        }

        .about-image {
            position: relative;
            left: 0;
            top: 0;
            text-align: center;
            margin-bottom: 30px;
            padding: 0 15px;
        }

        .about-title {
            h3 {
                font-size: 25px;
                margin: 0 0 8px;
            }

            h5 {
                font-size: 16px;
                margin: 0 0 10px;
            }
        }

        .our-skill {
            .single-progress {
                .progress-title {
                    font-size: 15px;
                    margin: 0 0 7px;
                }

                .progress {
                    height: 8px;
                    margin-bottom: 15px;

                    .progress-value {
                        font-size: 14px;
                        top: -23px;
                    }
                }
            }
        }

        .why-choose-us {
            .col-lg-4 {
                -ms-flex: 0 0 33.333333%;
                flex: 0 0 33.333333%;
                max-width: 33.333333%;

                .single-choose {
                    padding: 15px 10px;

                    i {
                        font-size: 25px;
                    }
                }
            }
        }

        .services-area {
            padding-bottom: 20px;
        }

        .single-services {
            padding: 25px 15px;

            i {
                font-size: 30px;
            }

            h3 {
                font-size: 18px;
                margin-bottom: 10px;
                margin-top: 15px;
            }

            .bg-number {
                right: 15px;
                top: 5px;
                font-size: 45px;
            }
        }

        .team-area {
            .animation-box3 {
                left: -15px;
                top: 0;
            }

            .animation-box4 {
                right: -350px;
                bottom: -350px;
            }
        }

        .single-team {
            h3 {
                font-size: 18px;
                margin-top: 0;
                margin-bottom: 5px;
            }

            span {
                font-size: 14px;
            }
        }

        .portfolio-area {
            padding-bottom: 20px;
        }

        .single-work {
            margin-bottom: 30px;

            img {
                width: 100%;
            }

            h3 {
                font-size: 14px;
                margin: 20px 0 0;
            }
        }

        .features-area {
            .animation-box3 {
                left: 55px;
                top: 0;
            }
        }

        .features-img {
            margin-bottom: 35px;
        }

        .single-features {
            padding-left: 75px;

            .icon {
                width: 55px;
                height: 55px;
                line-height: 55px;
                font-size: 25px;
            }

            h3 {
                font-size: 18px;
                margin-bottom: 13px;
            }
        }

        .tab-slider-container {
            .row {
                width: auto;
            }
        }

        .pricing-table {
            padding-bottom: 25px;
            margin-top: 30px;

            .pricing-header {
                padding: 20px 15px;
                font-size: 16px;
            }

            .price {
                font-size: 30px;
                margin-top: 20px;
                margin-bottom: 20px;
            }

            .pricing-features {
                margin: 0 0 25px;

                li {
                    font-size: 14px;
                    margin-bottom: 10px;
                }
            }
        }

        .single-feedback {
            .client-info {
                h4 {
                    font-size: 18px;
                    margin-bottom: 5px;
                }

                span {
                    margin-top: 0;
                }
            }
        }

        .blog-area {
            padding-bottom: 20px;
        }

        .single-blog-post {
            .post-content {
                margin-top: 20px;

                h3 {
                    font-size: 18px;
                    line-height: 27px;
                    margin-bottom: 10px;
                }

                .read-more {
                    font-size: 14px;
                }
            }

            img {
                width: 100%;
            }
        }

        .work-with-us {
            .animation-box3 {
                display: none;
            }

            h3 {
                font-size: 25px;
                margin-bottom: 10px;
                margin-top: -6px;
            }

            span {
                font-size: 15px;
                margin-bottom: 20px;
            }
        }

        .contact-box {
            padding: {
                left: 92px;
                right: 15px;
                top: 20px;
                bottom: 20px;
            }

            margin-bottom: 30px;

            .icon {
                left: 20px;
                top: 25px;
                width: 55px;
                height: 55px;
                line-height: 50px;
                font-size: 30px;
            }
        }

        .get-in-touch {
            margin-top: 10px;
        }

        #contactForm {
            margin-top: 30px;

            .btn-primary {
                display: block;
                margin: 0 auto;
            }
        }

        .footer-area {
            padding: 25px 0;
            text-align: center;

            ul {
                text-align: center;
                margin: 8px 0 0;

                li:first-child {
                    padding-left: 0;
                }
            }
        }
    }

    @media only #{$media} and ($feature_min : $value_two) and ($feature_max : $value_three) {
        .reports {
            .map-img {
                h1 {
                    font-size: 22px;
                    bottom: 200px;
                    left: 60%;
                }
            }
        }
        .news {
            .map-img {
                h1 {
                    font-size: 22px;
                    bottom: 200px;
                    left: 60%;
                }
            }
        }
        .ptb-100 {
            padding-top: 70px;
            padding-bottom: 70px;

            .navbar-logo {
                height: 50px;
                width: 50px;
                //         -webkit-animation: flickerAnimation 3s infinite;
                //    -moz-animation: flickerAnimation 3s infinite;
                //    -o-animation: flickerAnimation 3s infinite;
                // animation: flickerAnimation 3s infinite;
                // animation-delay: 2s;
                transition: width 0.3s, height 0.3s;
            }
        }

        .navbar {
            .container {
                padding-right: 15px;
                padding-left: 15px;
            }

            &.navbar-light {
                .navbar-toggler {
                    color: $template_color;
                    outline: 0 !important;
                    border-radius: 0;
                    padding: 0.25rem 0.55rem;
                    border-color: $template_color;

                    .navbar-toggler-icon {
                        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(113, 194, 27, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
                    }
                }

                .navbar-nav {
                    background: #ffffff;
                    padding: 20px 0;

                    .nav-item {
                        padding: 7px 15px;

                        .nav-link {
                            color: #000000;

                            &.active {
                                color: $template_color;
                            }

                            .nav_icon {
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }
                }

                &.is-sticky {
                    padding: 8px 0;

                    .navbar-nav {
                        padding: 0;
                    }

                    .nav-item {
                        padding: 7px 0;
                    }
                }
            }
        }

        .main-banner {
            height: 50em;
            // padding-bottom: 75px;
            padding-top: 90px;
        }

        .hero-content {
            max-width: 100%;

            h5 {
                font-size: 18px;
            }

            h1 {
                font-size: 55px;
            }
        }

        .animation-box3 {
            left: 60px;
            top: 70px;
        }

        .animation-box2 {
            right: 50px;
            top: 105px;
        }

        .animation-box1 {
            left: 50px;
            bottom: 20px;
        }

        .animation-box7 {
            bottom: 0;
        }

        .animation-box4 {
            right: -320px;
            bottom: -320px;
        }

        .subscribe-area {
            text-align: center;

            &::before {
                height: 15px;
            }

            p {
                margin-bottom: 20px;
                font-size: 22px;
                margin-top: -12px;
            }
        }

        .welcome-area {
            padding-bottom: 40px;
        }

        .about-image {
            position: relative;
            left: 0;
            top: 0;
            margin-bottom: 40px;
            padding: 0 15px;
        }

        .single-box {
            margin-bottom: 30px;
        }

        .services-area {
            padding-bottom: 40px;
        }

        .portfolio-area {
            padding-bottom: 30px;
        }

        .features-img {
            margin-bottom: 40px;

            img {
                width: 100%;
            }
        }

        .tab-slider-nav {
            margin-bottom: 10px;
        }

        .tab-slider-container .row {
            width: auto;
        }

        .pricing-table {
            margin-top: 30px;
        }

        .blog-area {
            padding-bottom: 40px;
        }

        .contact-box {
            margin-bottom: 30px;
        }

        .get-in-touch {
            margin-top: 10px;
        }

        #contactForm {
            margin-top: 25px;
        }
    }

    @media only #{$media} and ($feature_min : $value_four) {
        .nav_icon {
            position: absolute;
        }
    }
    @media only #{$media} and ($feature_min : $value_four) and ($feature_max : $value_five) {
        .navbar-light {
            .navbar-logo {
                height: 100px;
                width: 100px;
            }
        }

        .snap-container {
            scroll-snap-type: y mandatory;
            // scroll-padding: 120px;
            // height: 100vh;
            // overflow-y: scroll;
            // overflow-x: hidden;

            // section {
            //     scroll-snap-align: start;
            //     scroll-snap-stop: normal;
            // }
        }

        .subscribe-area {
            &::before {
                height: 15px;
            }

            p {
                font-size: 20px;
            }
        }

        .features-img {
            margin-top: 40px;
        }

        .about-image {
            // position: absolute;
            // left: -45px;
            // top: 0;
            // width: 610px;
            position: relative;
            left: 0;
            top: 0;
            margin-bottom: 40px;
            padding: 0 15px;
        }
    }

    @media only #{$media} and ($feature_min : $value_six) {
        .navbar-light {
            .navbar-logo {
                height: 100px;
                width: 100px;
            }
        }

        .snap-container {
            scroll-snap-type: y mandatory;
        }

        .main-banner {
            .animation-box4 {
                right: -200px;
                bottom: -200px;
            }
        }

        .hero-content {
            margin-top: 0;
        }

        .subscribe-area {
            &::before {
                height: 30px;
            }
        }

        .about-area {
            .animation-box2 {
                right: 150px;
                top: 150px;
            }
        }

        .about-image {
            // width:65%;
            position: absolute;
            left: -115px;
            top: 50px;
        }

        .team-area {
            .animation-box4 {
                right: -200px;
                bottom: -200px;
            }
        }

        .creative-box1 {
            &::before {
                content: "";
                border-top: 150px solid transparent;
            }

            &::after {
                border-top: 150px solid transparent;
            }
        }

        .wave_box {
            .wave {
                height: 140px;

                &:nth-of-type(2) {
                    bottom: -30px;
                }
            }
        }

        .vertical-timeline-element--work {
            margin: 0.1em -4.5em;
        }

        .vertical-timeline--two-columns .vertical-timeline-element-icon {
            width: 38px;
            height: 38px;
            left: 50%;
            margin-left: -19px;
        }
    }
}

.bootstrap-scope .snap-container.hidden-y {
    overflow-y: hidden;
}
