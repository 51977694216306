.mainContainer {
    position: relative;
    font-family: Prompt, sans-serif;
    font-weight: 400;

    .searchSection {
        position: absolute;
        left: 0;
        top: 120px;
        height: calc(100vh - 140px);
        display: flex;

        @media screen and (max-width: 991px) {
            top: 0;
            height: calc(100% - 20px);
        }

        @media screen and (max-width: 767px) {
            display: none;
        }

        .activeButton {
            display: flex;
            align-items: center;
            padding: 20px;
            background-color: #a7f029;
            height: fit-content;
            border-radius: 0 10px 10px 0;
            cursor: pointer;
            transition-duration: 0.5s;

            &.open {
                display: none;
            }

            span {
                padding-left: 10px;
                font-size: 16px;
            }

            .closeButton {
                display: flex;
                align-items: center;
                padding-left: 10px;
            }
        }

        .iframeContainer {
            display: flex;
            flex-direction: column;
            background-color: #fff;
            width: 0px;
            transition-duration: 0.5s;
            overflow-x: hidden;
            border-radius: 10px;

            &.open {
                width: 100%;
                max-width: 450px;
                margin-left: 20px;

                &.expand {
                    width: 70vw;
                    max-width: calc(100% - 40px);
                }
            }

            .headIframe {
                display: flex;
                align-items: center;
                background-color: #000;
                padding: 5px;
                min-width: 430px;

                span {
                    color: #fff;
                    font-size: 18px;
                    padding: 0 5px;
                }

                .actionButton {
                    margin-left: auto;
                    display: flex;
                }
            }

            .iframeBox {
                width: 100%;
                height: 100%;
                border: none;
                outline: none;
                min-width: 430px;
            }
        }

        .expandButton {
            @media screen and (max-width: 960px) {
                display: none;
            }
        }
    }

    .content {
        transition-duration: 0.5s;

        &.open {
            padding-left: 450px;

            @media screen and (max-width: 767px) {
                padding-left: 0;
            }

            &.expand {
                padding-left: 70vw;

                @media screen and (max-width: 767px) {
                    padding-left: 0;
                }
            }
        }

        .contentTextBox {
            @media screen and (max-width: 991px) {
                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: #ffffff99;
                margin: 0;
                padding: 15px;
                margin-left: 20px;
                border-radius: 10px;
            }

            @media screen and (max-width: 767px) {
                background-color: transparent;
                padding: 0;
                margin-left: 0;
                max-width: 940px;
                margin: 50px auto 0;
            }

            @media screen and (max-width: 480px) {
                margin: 70px auto 0;
            }

            &.expand {
                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: #ffffff99;
                margin: 0;
                padding: 15px;
                margin-left: 20px;
                border-radius: 10px;

                @media screen and (max-width: 767px) {
                    background-color: transparent;
                    padding: 0;
                    margin-left: 0;
                    max-width: 940px;
                    margin: 50px auto 0;
                }

                @media screen and (max-width: 480px) {
                    margin: 70px auto 0;
                }
            }
        }

        .contentText {
            font-size: 3.5em;
            font-weight: 600;
            margin-bottom: 15px;
            min-height: fit-content;
            line-height: 1;

            @media screen and (max-width: 767px) {
                font-size: 3.5em;
                font-weight: 600;
                margin-bottom: 15px;
                min-height: fit-content;
                line-height: 1;
            }

            &.open {
                font-size: 3em;

                @media screen and (max-width: 991px) {
                    font-size: 2em;
                }

                @media screen and (max-width: 767px) {
                    font-size: 3.5em;
                    font-weight: 600;
                    margin-bottom: 15px;
                    min-height: fit-content;
                    line-height: 1;
                }
                &.expand {
                    font-size: 2.5em;

                    @media screen and (max-width: 767px) {
                        font-size: 3.5em;
                        font-weight: 600;
                        margin-bottom: 15px;
                        min-height: fit-content;
                        line-height: 1;
                    }
                }
            }
        }
    }
}

.searchSectionMobileButton {
    position: fixed;
    left: 0;
    top: 100px;
    display: none;

    @media screen and (max-width: 767px) {
        display: flex;
    }

    @media screen and (max-width: 600px) {
        top: 170px;
    }

    &.hide {
        display: none;
    }

    .activeButton {
        display: flex;
        align-items: center;
        padding: 20px;
        background-color: #a7f029;
        height: fit-content;
        border-radius: 0 10px 10px 0;
        cursor: pointer;
        transition-duration: 0.5s;

        span {
            padding-left: 10px;
            font-size: 16px;
        }
    }
}

.searchSectionMobile {
    position: fixed;
    left: 0;
    top: 0;
    display: none;
    width: 0;
    height: 0;
    overflow-x: hidden;

    @media screen and (max-width: 991px) {
        height: calc(100vh - 120px);
    }

    @media screen and (min-width: 767px) {
        display: none;
    }

    &.open {
        display: flex;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.4);
        padding: 100px 20px 20px;

        @media screen and (min-width: 767px) {
            display: none;
        }
        .iframeContainer {
            width: 100%;
        }
    }

    .iframeContainer {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        width: 100%;
        transition-duration: 0.5s;
        border-radius: 10px;
        overflow: hidden;

        .headIframe {
            display: flex;
            align-items: center;
            background-color: #000;
            padding: 5px;

            span {
                color: #fff;
                font-size: 18px;
                padding: 0 5px;
            }

            .actionButton {
                margin-left: auto;
                display: flex;
            }
        }

        .iframeBox {
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
        }
    }
}
