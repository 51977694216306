.overlay {
  // position: fixed;
  // top: 0;
  // left: 0;
  width: 100%;
  height: 100%;
  // background: rgba(255,255,255,.6);

  z-index: 1001;
  flex-direction: column;
  position: fixed;
  opacity: 0.7;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  transition: opacity 400ms;
}
