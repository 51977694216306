.container {
    &.override[data-type="override"] {
        max-width: 1127px !important;
    }

    @media only screen and (min-width: 1200px) {
        &.override[data-type="override"] {
            max-width: 1127px !important;
        }
    }
}